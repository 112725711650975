import { Suspense, useEffect, useState } from 'react';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { forwardAiAlert } from 'api/alerts.js';
import { getCareEvents } from 'api/teamConfigurationProfiles.js';
import { getUserPreferences } from 'api/users.js';
import { Alert, Grid, Loader } from 'components/index.js';
import InterventionsMonitoring from 'components/InterventionsMonitoring.jsx';
import {
	AiSetting,
	CallWorkflowType,
	configurableAISettings,
	configurableMonitoringMenu,
	MonitoringSettings,
	SettingsCategory,
	UserSettingTypes,
} from 'constants/configurationEnums.js';
import { CareEventTypes, PatientAiSetting } from 'constants/enums.js';
import { getUserRole } from 'infrastructure/auth.js';
import { isSettingEnabled } from 'infrastructure/helpers/aiHelper.jsx';
import {
	getAiSettingsConfigurations,
	getConfigurationValue,
	updateConfigsList,
	lazyWithRetry,
} from 'infrastructure/helpers/commonHelpers.js';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { AiPatientPosition, AiSleepQuality } from 'components/AI/Common/index.js';
import { isAnyAiFeatureFlagEnabled } from 'infrastructure/helpers/aiRoomSettingsHelper.js';
const AiSettings = lazyWithRetry(() => import('containers/AI/Monitoring/AiSettings.jsx'));

const excludedCareEvents = [
	'Patient Privacy'.toLowerCase(),
	'Set stat alarm'.toLowerCase(),
	'Patient Out of Room'.toLowerCase(),
	'Patient Returned to Room'.toLowerCase(),
];

const editablePanels = [
	{ description: 'nightVision', key: MonitoringSettings.NightVision },
	{ description: 'rails', key: AiSetting.RAILS },
	{ description: 'getOutOfBed', key: AiSetting.GET_OUT_OF_BED },
	{ description: 'fallDetection', key: AiSetting.FALL_DETECTION },
	{ description: 'patientMobility', key: AiSetting.PATIENT_MOBILITY },
	{ description: 'pressureInjury', key: AiSetting.PRESSURE_INJURY },
	{ description: 'aiPrivacyMode', key: AiSetting.AI_PRIVACY_MODE },
	{ description: 'handWashing', key: AiSetting.HAND_WASHING },
	{ description: 'ewsAiVitalSigns', key: AiSetting.EWS_AI_VITAL_SIGNS },
	{ description: 'ivBagFluidLevel', key: AiSetting.IV_BAG_FLUID_LEVEL },
	{ description: 'patientInfoBox', key: MonitoringSettings.PatientInfo },
	{ description: 'whiteboard', key: MonitoringSettings.Whiteboard },
	{ description: 'whiteboardControls', key: MonitoringSettings.DisplayControl },
	{ description: 'careEventsForSafetyCompanions', key: MonitoringSettings.CareEventsForSafetyCompanions },
	{ description: 'precautions', key: MonitoringSettings.Precautions },
	{ description: 'enablePrivacyMode', key: MonitoringSettings.Privacy },
];

const RoundingTimeline = ({
	isDarkMode,
	conferenceId,
	feed,
	hierarchyNaming,
	onAiSettingClick,
	isNoteShowing,
	toggleFallPrevention,
	numberOfFeeds,
	setSnoozeType,
	stopVoiceOver,
	onAlertInfoClose,
	isDefaultOwner,
	patientId,
	conversationId,
	setIvBagPercentage,
	aiSettings,
}) => {
	const dispatch = useDispatch();
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [careEvents, setCareEvents] = useState([]);
	const [careEventsTotalCount, setCareEventsTotalCount] = useState(0);
	const [careEventsPageIndex, setCareEventsPageIndex] = useState(0);
	const userSession = useSelector(state => state.user.userSession);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const intl = useIntl();

	const getCareEventsFiltered = arr => {
		if (!arr) {
			return [];
		}
		return arr.filter(item => !excludedCareEvents.includes(item.name.toLowerCase())) || [];
	};

	useEffect(() => {
		const getEvents = async () => {
			const allCareEventsResponse = await getCareEvents({
				pageSize: 10,
				pageIndex: careEventsPageIndex,
				healthSystemId: userSession.healthSystem.id,
				teamCareEventType: CareEventTypes.SafetyCompanions,
			});

			if (allCareEventsResponse.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			} else {
				const teamCareEvents = allCareEventsResponse?.teamCareEvents;
				setCareEvents(prevState => [...prevState, ...teamCareEvents]);
				setCareEventsTotalCount(allCareEventsResponse.total);
			}
			setIsLoading(false);
		};
		getEvents();
	}, [intl, userSession.healthSystem.id, careEventsPageIndex]);

	useEffect(() => {
		setCareEvents([]);
		setCareEventsPageIndex(0);
	}, [userSession.healthSystem.id]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const [adminRoomSettings, myRoomSettings, adminAiSettings, aiRoomSettings] = await Promise.all([
				getRoomSettings(feed.roomId, SettingsCategory.MONITORING),
				getUserPreferences(UserSettingTypes.Monitoring, feed.roomId),
				getRoomSettings(feed.roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, feed.roomId),
			]);
			const responseError = adminRoomSettings.error || myRoomSettings.error || adminAiSettings.error || aiRoomSettings.error;
			if (responseError) {
				setError(responseError.message);
				return;
			}
			const response = updateConfigsList(adminRoomSettings, myRoomSettings);
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings, CallWorkflowType.ROUNDING);
			const resError = response.error || aiResponse.error;
			if (resError) {
				setError(resError);
			} else {
				setAdminConfigurations(response.configs);
				setAdminAiConfigurations(aiResponse.configs);
				const result = patientNotes[feed.deviceId] ?? {};
				editablePanels.forEach(item => {
					result[item.description] = response.configs[item.key] ? response.configs[item.key]?.value : false;
				});

				dispatch(patientNotesActionCreators.setPatientDataSaved(result, feed.deviceId));
			}
			setIsLoading(false);
		};
		fetchRoomSettings();
	}, [feed]);

	const showForwardToNurses = getConfigurationValue(adminConfigurations[MonitoringSettings.ForwardToNurses]);

	const sendAutomaticAlert = async (alertId, isAiAlert) => {
		const { hospital, department, floor, room } = hierarchyNaming;
		const dataToSend = {
			conferenceId,
			conversationId,
			alertId,
			hospital,
			department,
			floor,
			room,
		};
		if (isAiAlert) {
			const response = await forwardAiAlert(dataToSend);
			if (response.error) {
				setError(response.error.message);
			}
		}
	};

	const isAiFeatureEnabled = (patientSetting, configSetting) => {
		const deviceSettings = aiSettingList.find(item => item.deviceId === feed.deviceId)?.settings;
		return (
			isSettingEnabled(deviceSettings, patientSetting) &&
			getConfigurationValue(adminAiSettingsConfigurations[configSetting]) &&
			!getConfigurationValue(adminAiSettingsConfigurations[AiSetting.TRAINING_MODE])
		);
	};

	const loadMoreCareEventOptions = () => {
		const hasReachedEnd = careEventsTotalCount - careEvents.length <= 0;
		if (!hasReachedEnd) {
			setCareEventsPageIndex(prevState => prevState + 1);
		}
	};

	return (
		<div className='monitoring-timeline'>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<>
					{patientNotes[feed.deviceId]?.careEventsForSafetyCompanions && !isDefaultOwner && (
						<InterventionsMonitoring
							patientId={patientId}
							deviceId={feed.deviceId}
							careEvents={getCareEventsFiltered(careEvents)}
							feed={feed}
							numberOfFeeds={numberOfFeeds}
							setSnoozeType={setSnoozeType}
							stopVoiceOver={stopVoiceOver}
							showForwardToNurses={showForwardToNurses}
							onForwardToNurses={sendAutomaticAlert}
							conferenceId={conferenceId}
							onAlertInfoClose={onAlertInfoClose}
							loadMoreCareEventOptions={loadMoreCareEventOptions}
						/>
					)}
					{isAnyAiFeatureFlagEnabled(adminAiSettingsConfigurations) && !isDefaultOwner && (
						<Suspense
							fallback={
								<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
									<Loader />
								</Grid>
							}>
							<AiSettings
								feed={feed}
								patientId={patientId}
								isDarkMode={isDarkMode}
								onAiSettingClick={onAiSettingClick}
								isNoteShowing={isNoteShowing}
								toggleFallPrevention={toggleFallPrevention}
								stopVoiceOver={stopVoiceOver}
								onAlertInfoClose={onAlertInfoClose}
								adminAiSettingsConfigurations={adminAiSettingsConfigurations}
								setIvBagPercentage={setIvBagPercentage}
								isRounding={true}
								aiSettings={aiSettings}
								conferenceId={conferenceId}
							/>
						</Suspense>
					)}
					{isAiFeatureEnabled(PatientAiSetting.PRESSURE_INJURY, AiSetting.PRESSURE_INJURY) && (
						<AiPatientPosition deviceId={feed.deviceId} isDarkMode={isDarkMode} patientId={patientId} />
					)}
					{isAiFeatureEnabled(PatientAiSetting.SLEEP_QUALITY, AiSetting.SLEEP_QUALITY_TRACKING) && (
						<AiSleepQuality deviceId={feed.deviceId} patientId={patientId} />
					)}
				</>
			)}

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default RoundingTimeline;
