import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import ThreeDots from 'components/ThreeDots.jsx';
import { IotDeviceState } from 'constants/enums.js';
import { MeasureStates } from 'constants/examinations.jsx';

const liveExaminationLink = `${healthCareCdnUrl}live-examination/`;

const ToastMessages = props => {
	const intl = useIntl();
	const deviceName = props.selectedExamination?.device || 'pulseOximeter';

	const getToastMessageDetails = (state, text) => ({
		connectionState: state
			? translate(state, {
					value: intl.formatMessage({
						id: deviceName,
					}),
			  })
			: null,

		text: translate(
			text,
			text === 'doToReconnect'
				? {
						value: <p>{intl.formatMessage({ id: 'clickHere' })}</p>,
				  }
				: ''
		),
	});

	const getToastMessage = () => {
		switch (props.selectedExamination?.deviceState) {
			case IotDeviceState.CONNECTING:
				return getToastMessageDetails('valueIsConnecting', 'pleaseWaitConnection');
			case IotDeviceState.DISCONNECTED:
				return getToastMessageDetails('valueIsDisconnected', 'doToReconnect');
			case IotDeviceState.CONNECTION_FAIL:
				return getToastMessageDetails('deviceWiFiConnectionFailed', 'doToReconnect');
			case IotDeviceState.STAND_ON_SCALE:
				return getToastMessageDetails('valueIsConnected', 'pleaseStandOnScale');
			case IotDeviceState.PUT_NEW_STRIP:
				return getToastMessageDetails('', 'newTestNewStrip');
			case IotDeviceState.MEASURING:
				return getToastMessageDetails(
					'deviceIsMeasuring',
					props.selectedExamination?.device === 'pulseOximeter' ? 'removeDeviceToCompleteMeasure' : 'waitForCompletionToEnsure'
				);
			case IotDeviceState.COMPLETED:
			case IotDeviceState.COULD_NOT_GET_PATIENT_DATA:
			case IotDeviceState.MISSING_PATIENT_INFO:
			case IotDeviceState.SHOES_ON:
			case IotDeviceState.UNREALISTIC_WEIGHT:
			case IotDeviceState.MISSING_HEIGHT:
			case IotDeviceState.MISSING_WEIGHT:
			case IotDeviceState.MISSING_GENDER:
				return getToastMessageDetails('measureHasFinished', 'doToReconnect');
			default:
				return null;
		}
	};

	return (
		<div className='toast-message-iot cursor-pointer' onClick={props.onClick ? props.onClick : () => {}}>
			<div
				className={classNames(
					![
						MeasureStates.MEASURE_COMPLETED,
						MeasureStates.MEASURING,
						MeasureStates.CONNECTING,
						MeasureStates.STAND_ON_SCALE,
						MeasureStates.COULD_NOT_GET_PATIENT_DATA,
					].includes(props.selectedExamination?.buttonText)
						? 'iot-disconnected'
						: ''
				)}>
				<img
					src={`${liveExaminationLink}devices/${
						[
							MeasureStates.MEASURE_COMPLETED,
							MeasureStates.MEASURING,
							MeasureStates.CONNECTING,
							MeasureStates.STAND_ON_SCALE,
							MeasureStates.COULD_NOT_GET_PATIENT_DATA,
						].includes(props.selectedExamination?.buttonText)
							? props.selectedExamination?.deviceImg
							: props.selectedExamination?.deviceImgGrey
					}`}
					alt='device icon'
				/>
			</div>
			<div>
				{getToastMessage()?.connectionState && (
					<span>
						{getToastMessage()?.connectionState}
						{[MeasureStates.CONNECTING, MeasureStates.MEASURING].includes(props.selectedExamination?.buttonText) && <ThreeDots />}
					</span>
				)}
				{getToastMessage()?.text && <span>{getToastMessage()?.text}</span>}
			</div>
		</div>
	);
};

export default ToastMessages;
