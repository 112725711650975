import { useState } from 'react';
import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';
import PatientPositionMonitoringIcon from 'icons/Monitoring/PatientPositionMonitoring.jsx';
import { PatientPositionTab } from 'constants/enums.js';
import { useSelector } from 'react-redux';
import SetupIcon from 'icons/Monitoring/SetupIcon.jsx';
import { getMonitoringActiveIconColor, getMonitoringIconColor } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import { AiCurrentPosition, AiPatientPositionStats } from 'components/AI/Monitoring/Stats/index.js';
import { AiPatientPositionHistory } from 'components/AI/Monitoring/History/index.js';
import Button from 'components/Button.jsx';
import PressureInjuryHistoryIcon from 'icons/Monitoring/PressureInjuryHistory.jsx';
import PressureInjuryStatsIcon from 'icons/Monitoring/PressureInjuryStats.jsx';

const AiPatientPosition = ({ deviceId, isDarkMode, patientId }) => {
	const user = useSelector(state => state.user);
	const [expandedBox, setExpandedBox] = useState(true);
	const [currentTab, setCurrentTab] = useState(PatientPositionTab.CURRENT_POSITION);

	return (
		<>
			<div className='monitoring-timeline-box patient-position'>
				<div className='timeline-box-header'>
					<p className='timeline-box-title flex-1'>
						<PatientPositionMonitoringIcon />
						{translate('patientPositionMonitoring')}
					</p>
					<div className='timeline-box-actions'>
						<Button
							type='button'
							onClick={() => setExpandedBox(prevState => !prevState)}
							icon={expandedBox ? 'expand_less' : 'expand_more'}
						/>
					</div>
				</div>
				{expandedBox && (
					<div className='timeline-box-content'>
						<div className='flex flex-align-center bottom-20'>
							<div className='flex alert-subtabs'>
								<Button
									type='button'
									className={classNames('setting-alert-tab margin-b-0', {
										active: currentTab === PatientPositionTab.CURRENT_POSITION,
									})}
									onClick={() => setCurrentTab(PatientPositionTab.CURRENT_POSITION)}
									text={translate('currentPosition')}
									svgIcon={
										<SetupIcon
											color={
												isDarkMode && currentTab === PatientPositionTab.CURRENT_POSITION
													? getMonitoringActiveIconColor(user.darkMode)
													: getMonitoringIconColor(user.darkMode)
											}
										/>
									}
								/>
								<Button
									type='button'
									className={classNames('setting-alert-tab margin-b-0', { active: currentTab === PatientPositionTab.HISTORY })}
									onClick={() => setCurrentTab(PatientPositionTab.HISTORY)}
									text={translate('history')}
									svgIcon={
										<PressureInjuryHistoryIcon
											color={
												isDarkMode && currentTab === PatientPositionTab.HISTORY
													? getMonitoringActiveIconColor(user.darkMode)
													: getMonitoringIconColor(user.darkMode)
											}
										/>
									}
								/>
								<Button
									type='button'
									className={classNames('setting-alert-tab margin-b-0', { active: currentTab === PatientPositionTab.STATS })}
									onClick={() => setCurrentTab(PatientPositionTab.STATS)}
									text={translate('stats')}
									svgIcon={
										<PressureInjuryStatsIcon
											color={
												isDarkMode && currentTab === PatientPositionTab.STATS
													? getMonitoringActiveIconColor(user.darkMode)
													: getMonitoringIconColor(user.darkMode)
											}
										/>
									}
								/>
							</div>
						</div>
						{currentTab === PatientPositionTab.CURRENT_POSITION && (
							<AiCurrentPosition deviceId={deviceId} isDarkMode={isDarkMode} />
						)}
						{currentTab === PatientPositionTab.HISTORY && (
							<AiPatientPositionHistory patientId={patientId} isDarkMode={isDarkMode} />
						)}
						{currentTab === PatientPositionTab.STATS && <AiPatientPositionStats patientId={patientId} isDarkMode={isDarkMode} />}
					</div>
				)}
			</div>
		</>
	);
};

export default AiPatientPosition;
