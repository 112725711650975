import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { ParticipantVideo } from 'calls/components/index.js';
import { captureFrame } from 'calls/helpers/index.js';
import { MeasurementDevices, MeasurementTypes, OtoscopeExaminationPoints } from 'constants/enums.js';
import { ZoomLevel } from 'constants/examinations.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Loader from 'components/Loader.jsx';
import translate from 'i18n-translations/translate.jsx';
import { TakePictureButtonState } from 'constants/enums.js';

const OtoDermatoscope = ({
	videoTrack,
	toggleCameraFeed,
	startMeasuring,
	isHelloClient,
	selectExaminationPoint,
	measurementType,
	isCompanionParticipant,
	takePictureButtonState,
	setTakePictureButtonState,
	isTakePictureButtonClicked,
	setIsTakePictureButtonClicked,
	isVitalKitAuthorized,
}) => {
	const videoRef = useRef(null);
	const [brightness, setBrightness] = useState(false);
	const [zoomedValue, setZoomedValue] = useState(ZoomLevel.ZOOM_0);
	const liveExaminationLink = `${healthCareCdnUrl}live-examination/`;

	const handleZoom = isIncrease => {
		if ((isIncrease && zoomedValue >= ZoomLevel.ZOOM_5) || (!isIncrease && zoomedValue <= ZoomLevel.ZOOM_0)) {
			return;
		}
		setZoomedValue(prevState => {
			const zoomkey = 'ZOOM_';
			const [, level] = Object.keys(ZoomLevel)
				.find(key => ZoomLevel[key] === prevState)
				.split(zoomkey);
			return isIncrease ? ZoomLevel[`${zoomkey}${+level + 1}`] : ZoomLevel[`${zoomkey}${+level - 1}`];
		});
	};

	const getExaminationPoint = measurementType => {
		switch (measurementType) {
			case MeasurementTypes.EAR:
				return OtoscopeExaminationPoints.EAR;
			case MeasurementTypes.THROAT:
				return OtoscopeExaminationPoints.THROAT;
			default:
				return null;
		}
	};

	const takePicture = () => {
		if ((isHelloClient || isCompanionParticipant) && takePictureButtonState === TakePictureButtonState.TAKE_PICTURE) {
			setIsTakePictureButtonClicked(true);
			setTakePictureButtonState(TakePictureButtonState.SAVING);
			selectExaminationPoint(
				getExaminationPoint(measurementType),
				[MeasurementTypes.EAR, MeasurementTypes.THROAT].includes(measurementType)
					? MeasurementDevices.OTOSCOPE
					: MeasurementDevices.DERMATOSCOPE
			);
		} else {
			captureFrame(videoRef?.current);
		}
	};

	const closeOtoDermatoscopeCamera = () => {
		if (videoTrack && isHelloClient && !isVitalKitAuthorized) {
			toggleCameraFeed();
			return;
		}
		startMeasuring(true);
	};

	useEffect(() => {
		if ([TakePictureButtonState.SAVED, TakePictureButtonState.FAILED].includes(takePictureButtonState)) {
			setTimeout(() => {
				setIsTakePictureButtonClicked(false);
			}, 600);
			setTimeout(() => {
				setTakePictureButtonState(TakePictureButtonState.TAKE_PICTURE);
			}, 1200);
		}
	}, [setIsTakePictureButtonClicked, setTakePictureButtonState, takePictureButtonState]);

	return (
		<div
			className={classNames(
				'non-measurable-wrapper position-relative',
				videoTrack ? 'full-width full-height' : '',
				`zoom-${zoomedValue}`
			)}>
			{videoTrack && (
				<>
					<div className='otoscope-examination'>
						<ParticipantVideo ref={videoRef} track={videoTrack} />
						<div className='position-absolute take-screenshot-wrapper'>
							<div className='flex flex-justify-center'>
								<i className='material-icons' onClick={closeOtoDermatoscopeCamera}>
									close
								</i>
							</div>
							<div
								className={classNames(
									'flex flex-align-center take-screenshot-button ',
									isTakePictureButtonClicked ? 'take-screenshot-button-expanded' : '',
									takePictureButtonState === TakePictureButtonState.TAKE_PICTURE ? 'cursor-pointer' : ''
								)}
								onClick={takePicture}>
								{takePictureButtonState === TakePictureButtonState.SAVING && (
									<>
										<span>{translate('savingImage')}</span>
										<span className='flex flex-justify-center saving-screenshot-loader'>
											<Loader />
										</span>
									</>
								)}
								{takePictureButtonState === TakePictureButtonState.FAILED && (
									<>
										<span>{translate('savingImageFailed')}</span>
										<i className='material-icons-outlined saving-img-failed-icon'>error_outline</i>
									</>
								)}
								{takePictureButtonState === TakePictureButtonState.SAVED && (
									<>
										<span>{translate('imageSaved')}</span>
										<i className='material-icons-outlined'>check_circle</i>
									</>
								)}
								{takePictureButtonState === TakePictureButtonState.TAKE_PICTURE && (
									<img src={`${liveExaminationLink}screenshot.svg`} alt='ico' />
								)}
							</div>
							<div
								className='cursor-pointer flex flex-justify-center flex-align-center'
								onClick={() => setBrightness(prevState => !prevState)}>
								<img src={`${liveExaminationLink}brightness.svg`} alt='ico' />
								{brightness && (
									<input type='range' className='brightness' min={0} step={1} max={100} value={null} onChange={() => null} />
								)}
							</div>
							<div className='cursor-pointer flex flex-justify-center' onClick={() => handleZoom(false)}>
								<img src={`${liveExaminationLink}zoom-out.svg`} alt='ico' />
							</div>
							<div className='cursor-pointer flex flex-justify-center' onClick={() => handleZoom(true)}>
								<img src={`${liveExaminationLink}zoom-in.svg`} alt='ico' />
							</div>
							<div className='cursor-pointer flex flex-justify-center'>{zoomedValue}%</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default OtoDermatoscope;
