import styled, { css } from 'styled-components';
import { WindowSize } from 'calls/enums/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean, $isOutsideOfCall: boolean, $isFromMonitoring: boolean }, never>}
 */
const StyledStreamSettings = styled.section`
	button {
		text-transform: none !important;
	}
	section {
		background: ${LightTheme.colors.grayZero};
		header {
			border-bottom: 1px solid ${LightTheme.colors.grayOne};
			h1 {
				color: ${LightTheme.colors.grayFive};
			}
			button {
				span {
					color: ${LightTheme.colors.grayFive};
				}
			}
		}
	}

	select {
		padding-right: ${LightTheme.spacing[6]}px !important;
	}

	> div {
		video {
			height: auto;
			max-height: 150px;
			width: auto;
			object-fit: contain;
			transform: scaleX(-1);
		}

		main {
			height: 100%;
			display: flex;
			flex-direction: column;
			margin: 0 !important;
			> header {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: ${LightTheme.spacing[3]}px;
				background: ${LightTheme.colors.graySix};
				border-bottom: none;
			}

			> div {
				&:last-of-type label button {
					padding: 0;
					box-shadow: none;
					span {
						font-size: 18px;
					}
				}
			}

			label {
				color: ${LightTheme.colors.grayFive};
				button {
					padding: 0;
					span {
						color: ${LightTheme.colors.grayFive};
					}
				}
			}

			select {
				appearance: none;
				background-color: ${LightTheme.colors.grayZero};
				color: ${LightTheme.colors.grayFive};
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23545454'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='.87'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
			}
		}
	}

	> section {
		width: 960px;
		display: flex;
		align-items: center;
		margin: auto;

		h1,
		h2,
		p {
			margin: 0;
			padding: 0;
			color: ${LightTheme.colors.grayZero};
		}

		h1 {
			font-size: 18px;
			margin-bottom: ${LightTheme.spacing[2]}px;
		}

		h2 {
			font-size: 16px;
		}

		> main {
			position: relative;
			flex: 2;
			aspect-ratio: 16/9;
			background: ${LightTheme.colors.graySix};
			border-radius: ${LightTheme.borderRadius.base}px;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
			overflow: hidden;

			> video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			> header,
			> div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				padding: ${LightTheme.spacing[5]}px;

				> button {
					margin-right: ${LightTheme.spacing[2]}px;
				}
			}

			> header {
				align-items: center;
				flex-direction: column;

				> div {
					margin-bottom: ${LightTheme.spacing[2]}px;
				}
			}
		}
	}

	@media (max-width: ${WindowSize.TABLET}px) {
		min-height: 100vh;
		> section {
			flex-direction: column;
			padding: ${LightTheme.spacing[5]}px;

			> main {
				width: 100%;
			}
		}
	}

	${props =>
		props.$isDarkMode &&
		css`
			section {
				background: ${DarkTheme.colors.grayThree};

				header {
					border-bottom: 1px solid ${DarkTheme.colors.grayFour};

					h1 {
						color: ${DarkTheme.colors.grayFive};
					}

					button span {
						color: ${DarkTheme.colors.grayFive};
					}
				}
			}

			div {
				main {
					> header {
						background: ${DarkTheme.colors.grayFour};
					}

					label {
						color: ${DarkTheme.colors.grayFive};

						button span {
							color: ${DarkTheme.colors.grayFive};
						}
					}

					select {
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23cfd3da' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
						background-color: ${DarkTheme.colors.grayFour};
						color: ${DarkTheme.colors.grayFive};
					}
				}
			}
		`}

	${props =>
		props.$isOutsideOfCall &&
		css`
			width: 100%;
			height: calc(100vh - 125px);
			border-left: 1px solid ${props.$isDarkMode ? DarkTheme.colors.grayFour : 'var(--gray-2)'};
			text-align: start;
			overflow: auto;
			> div {
				position: unset;
				width: 100%;
				max-width: 100%;
				height: 100%;
			}
		`}

	${props =>
		props.$isFromMonitoring &&
		css`
			> div:first-of-type {
				position: unset;
				background: none;
				height: 100%;
				> section {
					box-shadow: unset;
					width: 100%;
					max-width: 100%;
					> header {
						display: none;
					}
					> div {
						padding: 0;
						> main {
							> header {
								padding: 0;
							}
							> div {
								> label {
									button {
										padding: 0 !important;
										background: unset !important;
									}
								}
								> select {
									box-shadow: unset;
									border: 1px solid var(--gray-8-o05);
								}
							}
							.stream-settings-wrapper {
								> button {
									padding: 0 !important;
									background: unset !important;
									min-width: 50px;
									border: 1px solid ${props.$isDarkMode ? 'var(--gray-0-o10)' : 'var(--gray-8-o05)'} !important;
									> img {
										border-radius: var(--spacing-s);
									}
								}
							}
							.virtual-background-buttons-container {
								> button {
									background-color: var(--blue-2) !important;
									border-color: var(--blue-2) !important;
								}
							}
						}
					}
				}
			}
		`}
`;

export default StyledStreamSettings;
