const VitalSignDetail = ({ imgSrc, name, value, unit = null }) => (
	<div className='flex vital-signs-details rpm-measurements-ttp-details gap-s'>
		<img src={imgSrc} alt='icon' />
		<div className='flex column-direction'>
			<span>{name}</span>
			<div className='flex'>
				<span className='right-s'>{value}</span>
				{unit && <span>{unit}</span>}
			</div>
		</div>
	</div>
);

export default VitalSignDetail;
