import { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import LightTheme from 'calls/styles/LightTheme.js';
import { changeLocalParticipantBackground, imageUrlToBase64 } from 'calls/helpers/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useConferenceConfigurations, useLocalParticipant } from 'calls/hooks/index.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { CompanySettings, getConfig, UserSettings } from 'constants/configurationEnums.js';
import { getBackgroundImages } from 'api/configurations.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { getBlobSasPicture } from 'api/doctors.js';
import { setUserHealthSystemPreferences } from 'api/users.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import Alert from 'components/Alert.jsx';
import SpinLoader from 'icons/Monitoring/SpinLoader.jsx';
import CustomButton from 'components/Button.jsx';
import { Cam } from '@solaborate/calls/webrtc';
import { VirtualBackgroundTypes } from 'calls/enums/index.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean }, never>}
 */
const StyledStreamSettings = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	p {
		display: flex;
		padding: 0;
		margin: 0;
		font-size: 14px;
		color: ${LightTheme.colors.grayFive};
		font-weight: 500;
		i {
			cursor: pointer;
		}
	}

	small {
		color: ${LightTheme.colors.grayThree};
		font-size: 12px;
	}

	.stream-settings-wrapper {
		margin-top: ${LightTheme.spacing[2]}px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		z-index: 1;

		button {
			padding: 0;
			margin: ${LightTheme.spacing[1]}px;
			aspect-ratio: 4 / 3;
			min-width: 50px;
			background: transparent;
			border: 1px solid grey;
			flex: 0 0 calc(33.333% - 10px);

			span {
				text-align: center;
				color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
				i {
					margin: 0;
					font-size: 24px !important;
				}
			}
			img {
				object-fit: contain;
				margin: auto !important;
				height: -webkit-fill-available;
			}
			&:hover {
				box-shadow: 0 0 0 2px var(--blue-light-4);
			}
			&.selected-background {
				box-shadow: 0 0 0 2px var(--blue-light-4);
			}
		}
	}

	> div:nth-of-type(2) {
		display: flex;
		flex: 1;
		align-items: end;
		justify-content: flex-end;
		padding-top: ${LightTheme.spacing[3]}px;
		button {
			background: ${LightTheme.colors.blueOne};
			&:hover {
				background: ${LightTheme.colors.blueFour08};
				transition: all 0.2s linear;
			}
		}
	}
`;

/**
 * @typedef {import('@solaborate/calls/webrtc').LocalTrack} LocalTrack
 * @param {object} props
 * @param {() => void} props.onDismiss
 * @param {object} [props.videoRef]
 * @param {object} [props.streamEffect]
 * @param {object} [props.tracks]
 */

const BackgroundImagesSettings = ({ onDismiss, videoRef = null, streamEffect }) => {
	const localParticipant = useLocalParticipant();
	const [selectedBackground, setSelectedBackground] = useState({ url: null, pictureUrl: null });
	const [isLoading, setIsLoading] = useState(false);
	const [isBackgroundImgsLoading, setIsBackgroundImgsLoading] = useState(false);
	const [error, setError] = useState(null);

	const [backgrounds, setBackgrounds] = useState([
		{
			id: 6,
			description: translate('filterNone'),
			onClick: () => setSelectedBackground({ url: VirtualBackgroundTypes.NONE, pictureUrl: null }),
			url: VirtualBackgroundTypes.NONE,
		},
		{
			id: 5,
			description: translate('filterBlur'),
			onClick: () => setSelectedBackground({ url: VirtualBackgroundTypes.BLUR, pictureUrl: null }),
			url: VirtualBackgroundTypes.BLUR,
		},
	]);

	const conferenceConfigs = useConferenceConfigurations();
	const visualSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.visualsSettings);
	const userSettings = useSelector(state => state.configurations.userSettings);
	const userSession = useSelector(state => state.user.userSession);
	const [loadingBgIds, setLoadingBgIds] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		const setInitialImage = async () => {
			const callBackground = userSettings[UserSettings.CALL_BACKGROUND];
			const backgroundConfig = getConfig(visualSettings[CompanySettings.DIGITAL_BACKGROUND]);
			const defaultHsBackground = visualSettings[CompanySettings.HS_DEFAULT_BACKGROUND]?.value;

			if (!backgroundConfig.value) {
				setIsBackgroundImgsLoading(false);
				return;
			}
			const response = await getBackgroundImages(getCompanyId(), userSession.healthSystem.id);
			if (response.error) {
				setError(response.error.message);
				return;
			}

			const ids = response.map((_, index) => index + 1);
			setLoadingBgIds(ids);
			const images = await Promise.all(response.map((item, index) => mapBlob(item.pictureUrl, index)));
			if (images) {
				setBackgrounds(prevState => [...prevState, ...images]);
			}
			setIsBackgroundImgsLoading(false);
			setLoadingBgIds([]);

			const newSelectedBackground = callBackground || defaultHsBackground;
			if ([VirtualBackgroundTypes.NONE, VirtualBackgroundTypes.BLUR].includes(newSelectedBackground)) {
				setSelectedBackground({ url: newSelectedBackground, pictureUrl: null });
				setIsBackgroundImgsLoading(false);
				return;
			}
			if (newSelectedBackground) {
				getBackground(newSelectedBackground, images);
			}
		};
		setInitialImage();
	}, []);

	const getBackground = async (background, images) => {
		let blobResponse = {};
		if (background) {
			blobResponse = await getBlobSasPicture(background, 'team-call-background-pictures');
		}
		if (!blobResponse.error && !blobResponse.errorResponse) {
			const found = images.find(item => item.pictureUrl === background);
			if (found) {
				setSelectedBackground({ url: blobResponse.result.uri.uri, pictureUrl: background });
				return;
			}
		}
		setSelectedBackground(prevState => ({ ...prevState, url: VirtualBackgroundTypes.NONE }));
	};

	useEffect(() => {
		if (videoRef?.current?.srcObject) {
			setTrackOnToggle();
		}
	}, [videoRef, streamEffect, selectedBackground]);

	const setTrackOnToggle = async () => {
		if (!selectedBackground) {
			return;
		}
		if (selectedBackground.url === VirtualBackgroundTypes.NONE) {
			if (streamEffect) {
				streamEffect.removeBackground();
			}
			getStorage().setItem('virtualBackground', 'none');
			return;
		}

		if (selectedBackground.url === VirtualBackgroundTypes.BLUR) {
			if (streamEffect) {
				streamEffect.blurBackground(20);
			}
			getStorage().setItem('virtualBackground', 'blur');
			return;
		}

		if (selectedBackground.url) {
			const base64Image = await imageUrlToBase64(selectedBackground.url);
			if (streamEffect) {
				streamEffect.changeBackground(base64Image);
			}
		}
	};

	const mapBlob = async (pictureUrl, index) => {
		const response = await getBlobSasPicture(pictureUrl, 'team-call-background-pictures');
		if (!response.hasSucceeded) {
			setError(response.errorResponse.message);
			return {};
		}
		if (response.error || !response.hasSucceeded) {
			setError(response.error.message);
			return {};
		}
		const imgToUpload = { url: response.result.uri.uri, id: index, pictureUrl };
		return imgToUpload;
	};

	const onSubmit = async () => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		const backgroundValue = selectedBackground?.pictureUrl ?? selectedBackground.url;
		const dataToSend = {
			teamSettings: [
				{
					settingTypeId: UserSettings.CALL_BACKGROUND,
					value: backgroundValue,
				},
			],
		};
		const response = await setUserHealthSystemPreferences(userSession.healthSystem.id, dataToSend);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		dispatch(
			configurationActionCreators.setUserSettings({
				[UserSettings.CALL_BACKGROUND]: backgroundValue,
			})
		);

		if (localParticipant && localParticipant.localTrackController.tracks[Cam]) {
			changeLocalParticipantBackground({
				localParticipant,
				streamEffect,
				selectedBackground: selectedBackground.url,
				backgroundUrl: backgroundValue,
			});
		}
		setIsLoading(false);
		onDismiss();
	};
	return (
		<StyledStreamSettings>
			{!isBackgroundImgsLoading && (
				<div className='stream-settings-wrapper no-padding-top'>
					{backgrounds.map(({ url, id, pictureUrl, onClick, description }) => (
						<Fragment key={id}>
							{onClick && (
								<CustomButton
									type='button'
									onClick={onClick}
									className={
										selectedBackground.url === url ? 'virtual-background-image-selected' : 'virtual-background-image-button'
									}
									border='1px solid var(--gray-3)'
									borderRadius='var(--spacing-s)'
									marginRight='var(--spacing-s)'
									marginTop='var(--spacing-s)'
									svgIcon={
										<span className='virtual-background-text' style={{ margin: 'auto' }}>
											{url === VirtualBackgroundTypes.BLUR && <i className='material-icons'>blur_on</i>}
											{url === VirtualBackgroundTypes.NONE && <i className='material-icons'>no_accounts</i>}
											<p className='no-margin no-padding'>{description}</p>
										</span>
									}
								/>
							)}
							{!onClick && (
								<CustomButton
									border='1px solid var(--gray-3)'
									marginRight='var(--spacing-s)'
									marginTop='var(--spacing-s)'
									type='button'
									onClick={() => setSelectedBackground({ url, pictureUrl })}
									className={
										selectedBackground.pictureUrl === pictureUrl
											? 'virtual-background-image-selected'
											: 'virtual-background-image-button'
									}
									background='var(--dark-theme-gray-2)'
									svgIcon={<img src={url} alt='Virtual background thumbnail' className='stream-settings-img' />}
								/>
							)}
						</Fragment>
					))}
					{loadingBgIds.map(val => (
						<CustomButton
							key={val}
							className='virtual-background-image-button'
							svgIcon={<SpinLoader color={LightTheme.colors.blueOne} />}
						/>
					))}
				</div>
			)}
			{isBackgroundImgsLoading && <SpinLoader />}

			<div className='virtual-background-buttons-container'>
				<CustomButton
					text={translate('selectBackground')}
					className={`${conferenceConfigs.isDarkMode ? 'virtual-background-dark-select-bg-button' : ''}`}
					onClick={onSubmit}
					isLoading={isLoading}
				/>
			</div>

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</StyledStreamSettings>
	);
};

export default BackgroundImagesSettings;
