import { useContext, useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import SleepQualityIcon from 'icons/Monitoring/SleepQualityIcon.jsx';
import {
	FallPreventionAlertTypes,
	SleepQualityStats,
	SleepTypesMapping,
	SleepWidget,
	ValidSleepQualityTypes,
} from 'constants/ai.jsx';
import { AiStatWidget } from 'components/AI/Monitoring/Stats/index.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { getPatientSleepStatistics } from 'api/alerts.js';
import Loader from 'components/Loader.jsx';
import Alert from 'components/Alert.jsx';
import { useSelector } from 'react-redux';
import { AiAnalyticsTypeId, PatientAiSetting } from 'constants/enums.js';
import { convertTimeRangeToUnix } from 'infrastructure/helpers/dateHelper.js';
import { getDeviceOwnerPatient } from 'api/patients.js';

const AiSleepQuality = ({ deviceId, patientId }) => {
	const socket = useContext(SocketContext);
	const [expandedBox, setExpandedBox] = useState(true);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [sleepStats, setSleepStats] = useState(null);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const [timeRange, setTimeRange] = useState(null);
	const { backgroundColor, color } = SleepWidget.PURPLE;

	useEffect(() => {
		const deviceSettings = aiSettingList.find(item => item.deviceId === deviceId)?.settings;
		const sleepSetting = deviceSettings.find(item => item.settingTypeId === PatientAiSetting.SLEEP_QUALITY);

		if (sleepSetting) {
			const { sleepQualityTrackingStart: start, sleepQualityTrackingEnd: end } = sleepSetting;

			if (timeRange?.start !== start || timeRange?.end !== end) {
				setTimeRange({ start, end });
			}
		}
	}, [aiSettingList]);

	useEffect(() => {
		if (!timeRange) {
			return;
		}
		const { startTimeUnix, endTimeUnix } = convertTimeRangeToUnix(timeRange?.start, timeRange?.end);
		const fetchSleepStatistics = async () => {
			let existingPatientId = patientId;
			if (!patientId) {
				const deviceOwnerResponse = await getDeviceOwnerPatient(deviceId);
				if (deviceOwnerResponse.error) {
					setError(deviceOwnerResponse.error.message);
					return;
				}
				existingPatientId = deviceOwnerResponse?.healthcareUserId;
			}
			const params = {
				userId: existingPatientId,
				alertTypes: FallPreventionAlertTypes,
				start: startTimeUnix,
				end: endTimeUnix,
			};
			const response = await getPatientSleepStatistics(params);
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setSleepStats(response);
			setIsLoading(false);
		};
		fetchSleepStatistics();
	}, [patientId, timeRange]);

	useEffect(() => {
		const onAlertAnalyticsAdded = data => {
			const { analyticsType, deviceId: alertDeviceId, patientId: alertPatientId, value } = data;

			if (!ValidSleepQualityTypes.includes(analyticsType) || alertDeviceId !== deviceId || alertPatientId !== patientId) {
				return;
			}
			const statType = SleepTypesMapping[analyticsType];
			if (statType) {
				setSleepStats(updateSleepStats(statType, value, analyticsType));
			}
		};

		socket.on(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, onAlertAnalyticsAdded);
		return () => {
			socket.off(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, onAlertAnalyticsAdded);
		};
	}, [socket, sleepStats]);

	const updateSleepStats = (statType, value, analyticsType) => {
		if (analyticsType === AiAnalyticsTypeId.DECIBEL_LEVEL) {
			return {
				...sleepStats,
				[statType]: value,
			};
		}
		const currentStatValue = sleepStats?.[statType] ?? 0;
		return {
			...sleepStats,
			[statType]: currentStatValue + parseInt(value),
		};
	};

	return (
		<div className='monitoring-timeline-box sleep-quality'>
			<div className='timeline-box-header'>
				<p className='timeline-box-title flex-1'>
					<SleepQualityIcon />
					{translate('sleepQuality')}
				</p>
				<div className='timeline-box-actions'>
					<Button
						type='button'
						onClick={() => setExpandedBox(prevState => !prevState)}
						icon={expandedBox ? 'expand_less' : 'expand_more'}
					/>
				</div>
			</div>
			{expandedBox && (
				<div className='timeline-box-content'>
					<div className='bottom-20'>
						{isLoading && (
							<div className='text-align-center'>
								<Loader />
							</div>
						)}
						{!isLoading && (
							<>
								{SleepQualityStats.map(item => (
									<AiStatWidget
										key={item.id}
										label={translate(item.label)}
										value={sleepStats?.[item?.label]}
										icon={item.icon}
										backgroundColor={backgroundColor}
										color={color}
									/>
								))}
							</>
						)}
					</div>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default AiSleepQuality;
