import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'calls/components/index.js';
import LightTheme from 'calls/styles/LightTheme.js';

/**
 * @type {import('styled-components').StyledComponent<"button", any, { $background: string, $color: string }, never>}
 */
const StyledIconButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--spacing-m);
	border-radius: 100%;
	background: ${props => (props.$background ? props.$background : 'none')};
	transition: 0.2s;
	user-select: none;

	span {
		color: ${props => (props.$color ? props.$color : LightTheme.colors.grayFive)};
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.85;
	}
`;

/**
 * @param {object} props
 * @param {any} [props.children]
 * @param {string} [props.icon] Material icon name
 * @param {string} props.background
 * @param {string} props.color
 * @param {boolean} [props.disabled=false]
 * @param {(...args: any) => void} [props.onClick=null]
 */
const IconButton = ({ children, icon, background, color, disabled = false, onClick = null, ...props }) => (
	<StyledIconButton type='button' $color={color} $background={background} disabled={disabled} onClick={onClick} {...props}>
		{children || <Icon name={icon} />}
	</StyledIconButton>
);

export default IconButton;
