import React, { useEffect } from 'react';
import _ from 'lodash';
import { ControlsActions, ScreenTypes } from 'calls/enums/index.js';
import { useConferenceConfigurations, useConferenceParticipants, useLocalParticipant, useScreenType } from 'calls/hooks/index.js';
import { LocalParticipantView, MoreParticipantsView, RemoteParticipantView } from 'calls/views/index.js';
import { isMedicalInfoModalsOpen } from 'calls/helpers/index.js';
import ParticipantProvider from 'calls/ParticipantProvider.jsx';
import { MainParticipantControls } from 'calls/components/index.js';
import { StyledParticipantWrapper } from 'calls/styles/styled-components/index.js';
import translate from 'i18n-translations/translate.jsx';

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant Local or remote participant
 * @param {import('@solaborate/calls/webrtc').TrackType} props.activeTrackType
 * @param {(...args: any)=>void} [props.setMainParticipant] Set main participant callback
 * @param {(...args: any)=>void} [props.setActiveTrackType] Set main participant active track type callback
 */
const ParticipantsView = ({ mainParticipant, activeTrackType, setMainParticipant, setActiveTrackType }) => {
	const screenType = useScreenType();
	const conferenceConfigs = useConferenceConfigurations();
	const conferenceParticipants = useConferenceParticipants();
	const localParticipant = useLocalParticipant();

	const gridSize = conferenceConfigs.isGridView ? conferenceConfigs.gridCount : conferenceConfigs.gridCount - 1;

	const getParticipantsBoxLimit = () =>
		screenType.type === ScreenTypes.MOBILE.type ? 3 : screenType.type === ScreenTypes.TABLET.type ? 4 : 5;

	const shouldShowMoreParticipantsBox = () => gridSize > getParticipantsBoxLimit();

	const isCallSettingsPanelOpen = () =>
		conferenceConfigs.isInviteParticipantsModalViewOpen || conferenceConfigs.isAudioAndVideoSettingsModalOpen;

	const isMedicalInfoOpen = isMedicalInfoModalsOpen(conferenceConfigs.medicalDataControls);

	const isPatientMeasurementsOpen =
		conferenceConfigs.isLiveExaminationOpen || conferenceConfigs.isPatientHistoryOpen || conferenceConfigs.isTelemetryModalOpen;

	const showMoreParticipantsBox =
		(!conferenceConfigs.isGridView &&
			gridSize > getParticipantsBoxLimit() &&
			(!(
				isPatientMeasurementsOpen ||
				isMedicalInfoOpen ||
				conferenceConfigs.isLiveCaptionsOpen ||
				conferenceConfigs.isWhiteboardVisible ||
				conferenceConfigs.isAiOpen ||
				conferenceConfigs.isRoomSignOpen
			) ||
				isCallSettingsPanelOpen())) ||
		(!conferenceConfigs.isGridView && screenType.isSmall && gridSize > getParticipantsBoxLimit());

	useEffect(() => {
		if (!showMoreParticipantsBox && conferenceConfigs.isMoreParticipantsViewOpen) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_MORE_PARTICIPANTS, false);
		}
	}, [conferenceConfigs, conferenceConfigs.isMoreParticipantsViewOpen, showMoreParticipantsBox]);

	return (
		<StyledParticipantWrapper
			$isDarkMode={conferenceConfigs.isDarkMode}
			$isMinimizedView={conferenceConfigs.isMinimizedView}
			$isGridView={conferenceConfigs.isGridView}
			$isRightToLeft={conferenceConfigs.isRightToLeft}
			$gridCount={conferenceConfigs.gridCount}
			$isParticipantsViewHidden={conferenceConfigs.isParticipantsViewHidden}
			$isCallSettingsPanelOpen={isCallSettingsPanelOpen()}
			$isMedicalInfoOpen={isMedicalInfoOpen}
			$isPatientMeasurementsOpen={isPatientMeasurementsOpen}
			$isMoreParticipantsBoxDisplayed={shouldShowMoreParticipantsBox()}
			$isMoreParticipantsViewOpen={conferenceConfigs.isMoreParticipantsViewOpen}
			$isLiveCaptionsOpen={conferenceConfigs.isLiveCaptionsOpen}
			$isWhiteboardVisible={conferenceConfigs.isWhiteboardVisible}
			$isAiOpen={conferenceConfigs.isAiOpen}
			$isRoomSignOpen={conferenceConfigs.isRoomSignOpen}>
			<section className={`grid-${gridSize}`}>
				<LocalParticipantView
					mainParticipant={mainParticipant}
					activeTrackType={activeTrackType}
					onClick={trackType => {
						if (_.isFunction(setMainParticipant)) {
							setMainParticipant(localParticipant);
						}
						if (_.isFunction(setActiveTrackType)) {
							setActiveTrackType(trackType);
						}
					}}
				/>
				{conferenceParticipants.map(participant => (
					<ParticipantProvider key={participant.id} participantData={participant}>
						<RemoteParticipantView
							mainParticipant={mainParticipant}
							activeTrackType={activeTrackType}
							onClick={trackType => {
								if (_.isFunction(setMainParticipant)) {
									setMainParticipant(participant);
								}
								if (_.isFunction(setActiveTrackType)) {
									setActiveTrackType(trackType);
								}
							}}
						/>
					</ParticipantProvider>
				))}
				{showMoreParticipantsBox && (
					<div
						className='more-participants'
						onClick={() => {
							conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PATIENT_HISTORY, false);
							conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_DIAGNOSES, false);
							conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PROCEDURES, false);
							conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_NOTES, false);
							conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_TELEMETRY, false);
							conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_LIVE_EXAMINATIONS, false);
							conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL, false);
							conferenceConfigs.onConfigurationToggleAction(
								ControlsActions.TOGGLE_MORE_PARTICIPANTS,
								!conferenceConfigs.isMoreParticipantsViewOpen
							);
						}}>
						<h1>+{gridSize - (getParticipantsBoxLimit() - 1)}</h1>
						<div>
							<p>{translate('moreParticipants')}</p>
							<span>{translate('clickToViewParticipants')}</span>
						</div>
					</div>
				)}
				{conferenceConfigs.gridCount <= 2 && !conferenceConfigs.isGridView && (
					<MainParticipantControls mainParticipant={mainParticipant} activeTrackType={activeTrackType} />
				)}
				{conferenceConfigs.isMoreParticipantsViewOpen && (
					<MoreParticipantsView setMainParticipant={setMainParticipant} setActiveTrackType={setActiveTrackType} />
				)}
			</section>
		</StyledParticipantWrapper>
	);
};

export default ParticipantsView;
