import { Suspense, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import SocketEvents from 'constants/socket-events.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { AiVitalSignsIcon } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import { getMonitoringActiveIconColor, getMonitoringIconColor } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import { PatientAiSetting } from 'constants/enums.js';
import { AiMeasurements, findSettingProperty, getCommonRoomAiConfigs } from 'infrastructure/helpers/aiHelper.jsx';
import { AiConfigField } from 'constants/ai.jsx';
import { AiVitalSignDetails, AiThreeVitalSigns } from 'components/AI/Monitoring/Stats/index.js';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
import Loader from 'components/Loader.jsx';
const AiIcon = lazyWithRetry(() => import('icons/Monitoring/AI.jsx'));

const VitalSignsTab = { AI: 0 };

const AiVitalSigns = props => {
	const intl = useIntl();
	const socket = useContext(SocketContext);
	const darkMode = useSelector(state => state.user.darkMode);
	const preferredUnits = useSelector(state => state.user.unitPreferences);
	const aiSettings = useSelector(state => state.aiSettingsList.aiSettings);
	const measurementTypes = findSettingProperty({
		deviceId: props.deviceId,
		aiSettings,
		settingTypeId: PatientAiSetting.EWS_AI_VITAL_SIGNS,
		property: AiConfigField.MEASUREMENT_TYPES,
	});
	const [currentTab, setCurrentTab] = useState(VitalSignsTab.AI);
	const [expandedBox, setExpandedBox] = useState(true);
	const [vitalSigns, setVitalSigns] = useState([]);

	useEffect(() => {
		setVitalSigns(getCommonRoomAiConfigs(AiMeasurements(intl), measurementTypes));
	}, [intl, aiSettings]);

	useEffect(() => {
		const onPatientHealthMeasurements = data => {
			setVitalSigns(prevVitalSigns => {
				const newVitalSigns = [...prevVitalSigns];
				const { measurementType, measurementValue, measurementUnit, unitCategoryId } = data;
				const found = newVitalSigns.find(element => measurementType === element.value);
				if (found) {
					found.result =
						measurementValue === 'Measuring'
							? `${intl.formatMessage({ id: 'measuring' })}...`
							: convertMeasurementTypes(unitCategoryId, measurementValue, getUnitPreference(unitCategoryId)?.unitSystemId);
					found.unit = measurementValue === 'Measuring' ? '' : measurementUnit;
				}
				return newVitalSigns;
			});
		};

		socket.on(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, onPatientHealthMeasurements);
		return () => {
			socket.off(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, onPatientHealthMeasurements);
		};
	}, [socket, intl]);

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const vitalSignsInFeed = () => (
		<div className='flex monitoring-vital-signs-ai-wrapper row'>
			{props.showAllVitalSigns && (
				<div className='flex flex-wrap vital-signs-ai'>
					{vitalSigns.map(vitalSign => (
						<AiVitalSignDetails vitalSign={vitalSign} className='vital-signs-details one-feed-view' />
					))}
				</div>
			)}
			{!props.showAllVitalSigns && <AiThreeVitalSigns vitalSigns={vitalSigns} />}
		</div>
	);

	const vitalSignsInTimeline = () => (
		<div className='monitoring-timeline-box vital-signs-box'>
			<div className='timeline-box-header'>
				<p className='timeline-box-title flex-1'>
					<AiVitalSignsIcon />
					{translate('vitalSigns')}
				</p>
				<div className='timeline-box-actions'>
					<Button
						type='button'
						onClick={() => setExpandedBox(prevState => !prevState)}
						icon={expandedBox ? 'expand_less' : 'expand_more'}
					/>
				</div>
			</div>
			{expandedBox && (
				<div className='timeline-box-content'>
					<div className='flex flex-align-center bottom-20'>
						<div className='flex alert-subtabs'>
							<Suspense fallback={<Loader />}>
								<Button
									type='button'
									className={classNames('setting-alert-tab margin-b-0', {
										active: currentTab === VitalSignsTab.AI,
									})}
									onClick={() => setCurrentTab(VitalSignsTab.AI)}
									text={translate('ai')}
									svgIcon={
										<AiIcon
											color={
												darkMode && currentTab === VitalSignsTab.AI
													? getMonitoringActiveIconColor(darkMode)
													: getMonitoringIconColor(darkMode)
											}
										/>
									}
								/>
							</Suspense>
						</div>
					</div>
					{currentTab === VitalSignsTab.AI && (
						<div className='flex flex-wrap margin-s'>
							{vitalSigns.map(vitalSign => (
								<AiVitalSignDetails vitalSign={vitalSign} className=' vital-signs-item' />
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);

	return (
		<>
			{props.isInFeed && vitalSignsInFeed()}
			{!props.isInFeed && vitalSignsInTimeline()}
		</>
	);
};

export default AiVitalSigns;
