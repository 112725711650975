import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { LocalTrackFactory, Cam, Mic, ScreenShare } from '@solaborate/calls/webrtc';
import { Button, IconButton, Modal, Tooltip } from 'calls/components/index.js';
import { MediaDeviceKinds, StartQueryStringKeys, WindowSize } from 'calls/enums/index.js';
import { getSavedStreamSettings } from 'calls/helpers/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { isAuthenticated } from 'infrastructure/auth.js';
import { ButtonType, CallTypes } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { useSelector } from 'react-redux';
import {
	CompanyCallSettings,
	MonitoringSettings,
	PatientInfoFieldsVariants,
	RoundingSettings,
} from 'constants/configurationEnums.js';
import BackgroundImagesSettings from 'calls/views/BackgroundImagesSettings.jsx';
import { loadTfLite, StreamBackgroundEffect } from 'calls/views/TrackWithBackground.jsx';
import StreamSettings from 'calls/views/StreamSettings.jsx';
import {
	getConfigurationValue,
	getConfigurationVariant,
	getRoleConfigurationValue,
	hasNonEmptyValues,
} from 'infrastructure/helpers/commonHelpers.js';
import { useIntl } from 'react-intl';
import { getDeviceOwnerPatient, getPatientLangAndInterpreter } from 'api/patients.js';
import { getAllVerbalRedirectionLanguages } from 'api/verbalRedirections.js';
import { getMonthDayYearDateFormat } from 'infrastructure/helpers/dateHelper.js';
import React from 'react';
import { StyledStreamSettings } from 'calls/styles/styled-components/index.js';

const StyledJoinWithAdjustments = styled.div`
	width: 100dvw;
	height: 100dvh;
	overflow: auto;
	display: flex;
	align-items: center;
	background: ${DarkTheme.colors.grayThree};

	> section {
		width: 70%;
		place-items: center;

		> main {
			width: 65%;
			text-align: center;
			margin: ${LightTheme.spacing[7]}px 0;
			> header {
				overflow: hidden;
				position: relative;
				aspect-ratio: 16 / 9;
				background: ${LightTheme.colors.grayFourteen};
				border-radius: ${LightTheme.borderRadius.base}px;
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
				margin-bottom: ${LightTheme.spacing[7]}px;

				> video {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform: scaleX(-1);
				}

				> p {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					color: ${DarkTheme.colors.grayFive};
				}

				> div {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					padding: ${LightTheme.spacing[5]}px;

					> div + div {
						margin-left: ${LightTheme.spacing[2]}px;
					}
				}
			}
			> div {
				display: flex;
				justify-content: space-between;
				> div {
					width: 40%;
					display: flex;
					flex-direction: column;
					text-align: justify;
					&:has(button) {
						width: unset;
						> button {
							margin: ${LightTheme.spacing[7]}px 0;
							padding: ${LightTheme.spacing[4]}px ${LightTheme.spacing[8]}px;
							font-size: ${LightTheme.spacing[4]}px;

							&:hover {
								background: ${LightTheme.colors.blueFour08};
								transition: all 0.2s linear;
							}
						}
					}
				}
			}
		}
		&:nth-of-type(2) {
			width: 30%;
			height: 100%;
			border-left: 1px solid var(--gray-6);

			> div {
				height: 100%;
			}
		}
	}
	&:not(:has(> section:nth-of-type(2))) {
		> section:first-of-type {
			width: 100%;
		}
	}
	@media (max-width: ${WindowSize.LAPTOP}px) {
		> section {
			> main {
				width: 90%;
				> div {
					flex-direction: column;
					> div {
						width: 100%;
					}
				}
			}
		}
	}
`;

/**
 * @type {import('styled-components').StyledComponent<"ul", any, never>}
 */
const StyledPatientInfo = styled.ul`
	justify-items: left;
	font-size: ${LightTheme.spacing[5]}px;
	color: ${DarkTheme.colors.grayFive};

	span {
		padding-right: ${LightTheme.spacing[1]}px;
		color: ${DarkTheme.colors.grayFive};
		font-weight: 700;
	}
`;

function ChildComponent({ children, props }) {
	const modifiedChildren = React.Children.map(children, child => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, props);
		}
		return child;
	});
	return modifiedChildren;
}

const renderPatientInfo = patientInfo =>
	// patientName, dateOfBirth, mrn, preferredLanguage, interpreterNeeded
	Object.entries(patientInfo)
		.filter(([key, item]) => key && item)
		.map(([key, item]) => (
			<li key={key}>
				<span className={key === 'patientName' ? 'capitalized-text' : ''}>
					{translate(key === 'patientName' ? 'patient' : key)}:
				</span>
				{item}
			</li>
		));

const JoinWithAdjustments = ({ children, conferenceInfo }) => {
	/** @type {import('react-router').match<{ conferenceId: string, invitationSecret: string, callType: string, deviceId: string, name: string, startConferenceId: string }>} */
	const callSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.callSettings);
	const healthSystemConfigurations = useSelector(state => state.configurations.healthSystemConfigurations);
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const intl = useIntl();

	const queryParams = new URLSearchParams(location.search);
	const helloDeviceId = queryParams.get(StartQueryStringKeys.OBJECT_ID);
	const roomName = queryParams.get(StartQueryStringKeys.CONFERENCE_NAME);
	const isVideoCall = Number(queryParams.get(StartQueryStringKeys.CALL_TYPE)) === CallTypes.VIDEO;

	const videoPreviewConfig = getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.VideoPreview);

	const videoRef = useRef(null);
	const localTrackFactoryRef = useRef(null);
	/** @type {import('react').MutableRefObject<import('@solaborate/calls/webrtc').Constraints>} */
	const constraintsRef = useRef({ [Mic]: true, [Cam]: true, [ScreenShare]: false });

	let { current: modelOptions } = useRef({
		height: 144,
		width: 256,
		virtualBackground: {
			backgroundEffectEnabled: false,
			backgroundType: 'none',
		},
	});

	const [shouldShowVideoPreview, setShouldShowVideoPreview] = useState(isVideoCall && (true || videoPreviewConfig));
	const [tracks, setTracks] = useState({});
	const [patientInfo, setPatientInfo] = useState({});
	const [isStreamSettingsOpen, setIsStreamSettingsOpen] = useState(false);
	const [streamEffect, setStreamEffect] = useState(null);
	const [, setShowStreamErrorMessage] = useState(false);

	useEffect(() => {
		setOrCreateLocalTracks();
	}, []);

	const setOrCreateLocalTracks = async () => {
		if (!shouldShowVideoPreview) {
			return;
		}
		if (localTrackFactoryRef.current) {
			localTrackFactoryRef.current.tracks.forEach(({ track }) => {
				track.stop();
			});
			localTrackFactoryRef.current = null;
		}

		const { [MediaDeviceKinds.AUDIO_INPUT]: audioInputId, [MediaDeviceKinds.VIDEO_INPUT]: videoInputId } =
			getSavedStreamSettings();

		if (audioInputId) {
			constraintsRef.current[Mic] = { deviceId: audioInputId };
		}
		if (videoInputId && constraintsRef.current[Cam]) {
			constraintsRef.current[Cam] = { deviceId: videoInputId };
		}

		try {
			localTrackFactoryRef.current = new LocalTrackFactory(constraintsRef.current);
			const tracksToCreate = [];

			if (callSettings[CompanyCallSettings.PROVIDER_MIC]) {
				tracksToCreate.push(Mic);
			}
			if (callSettings[CompanyCallSettings.PROVIDER_CAMERA] && constraintsRef.current[Cam]) {
				tracksToCreate.push(Cam);
			}
			const tracks = await localTrackFactoryRef.current.createTracks(tracksToCreate);
			const localTracks = tracks.reduce((acc, track) => ({ ...acc, [track.type]: track.track }), {});
			setTracks(localTracks);
		} catch (error) {
			setShowStreamErrorMessage(true);
		}
	};

	const setModel = async createdFactoryTracks => {
		const videoTrack = createdFactoryTracks.find(item => item.type === Cam);
		if (!videoTrack) {
			return;
		}
		if (videoTrack && videoTrack instanceof StreamBackgroundEffect) {
			modelOptions = { ...videoTrack.options };
		}
		const tflite = await loadTfLite();
		const sbe = new StreamBackgroundEffect(videoTrack, tflite, modelOptions);
		setStreamEffect(sbe);
		videoRef.current.srcObject = new MediaStream([sbe.track]);
	};

	const setLocalTracks = (createdFactoryTracks, toggleTrackType) => {
		const localTracks = createdFactoryTracks.reduce((acc, track) => ({ ...acc, [track.type]: track.track }), {});
		setModel(createdFactoryTracks);
		setTracks(localTracks);

		if (!toggleTrackType || toggleTrackType !== Mic) {
			videoRef.current.srcObject = new MediaStream(createdFactoryTracks.map(tr => tr.track));
		}
	};

	const toggleTrack = async trackType => {
		const { current: localTrackFactory } = localTrackFactoryRef;
		if (tracks[trackType]) {
			tracks[trackType].stop();
			delete tracks[trackType];
			localTrackFactory.tracks.delete(trackType);
		} else {
			try {
				await localTrackFactory.createTracks(trackType);
			} catch (error) {
				setShowStreamErrorMessage(true);
			}
		}

		setLocalTracks([...localTrackFactory.tracks.values()], trackType);
	};

	const isEhrField = key =>
		getConfigurationValue(healthSystemConfigurations[key]) &&
		getConfigurationVariant(healthSystemConfigurations[key]) === PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD;

	useEffect(() => {
		const fetchDeviceOwner = async () => {
			if (!helloDeviceId) {
				setPatientInfo({ patientName: '' });
				return;
			}

			const response = await getDeviceOwnerPatient(helloDeviceId);
			if (response && !response.error) {
				const languageResponse = await getAllVerbalRedirectionLanguages();
				if (languageResponse.error) {
					languageResponse.languages = [];
				}
				const languages = languageResponse.languages.map(lang => ({ ...lang, localeName: lang.localeName.split('(')[0].trim() }));
				const { preferredLanguage, interpreterNeeded } = await getPatientLangAndInterpreter(
					response.userId,
					languages,
					isEhrField(MonitoringSettings.PreferredLanguage)
				);
				setPatientInfo({
					patientName: response.isVirtualPatient ? '' : response.fullName,
					...(response.dateOfBirth && { dateOfBirth: getMonthDayYearDateFormat(response.dateOfBirth) }),
					mrn: response.mrn,
					preferredLanguage,
					interpreterNeeded,
				});
				return;
			}
			setPatientInfo({ patientName: '' });
		};

		fetchDeviceOwner();
	}, []);

	return (
		<>
			{shouldShowVideoPreview && (
				<StyledJoinWithAdjustments>
					<section>
						<main>
							<header>
								<video ref={videoRef} autoPlay muted playsInline />
								{!tracks[Cam] && <p>{translate('cameraIsOff')}</p>}
								<div>
									<Tooltip text={intl.formatMessage({ id: tracks[Cam] ? 'turnCameraOff' : 'turnCameraOn' })}>
										<IconButton
											icon={tracks[Cam] ? 'videocam' : 'videocam_off'}
											background={LightTheme.colors.grayZero}
											color={LightTheme.colors.grayFive}
											onClick={evt => {
												evt.stopPropagation();
												toggleTrack(Cam);
											}}
										/>
									</Tooltip>
									<Tooltip text={intl.formatMessage({ id: tracks[Mic] ? 'turnOffMic' : 'turnOnMic' })}>
										<IconButton
											icon={tracks[Mic] ? 'mic_none' : 'mic_off'}
											background={LightTheme.colors.grayZero}
											color={LightTheme.colors.grayFive}
											onClick={evt => {
												evt.stopPropagation();
												toggleTrack(Mic);
											}}
										/>
									</Tooltip>
									<Tooltip text={intl.formatMessage({ id: 'checkYourAudioAndVideoSettings' })}>
										<IconButton
											icon='settings'
											background={LightTheme.colors.grayZero}
											color={LightTheme.colors.grayFive}
											onClick={() => setIsStreamSettingsOpen(prevState => !prevState)}
										/>
									</Tooltip>
								</div>
							</header>
							<div>
								<div>
									<StyledPatientInfo>
										<span>{translate('room')}:</span> {roomName}
									</StyledPatientInfo>
									{hasNonEmptyValues(patientInfo) && <StyledPatientInfo>{renderPatientInfo(patientInfo)}</StyledPatientInfo>}
								</div>
								{isAuthenticated() && (
									<div>
										<Button
											type='submit'
											variant={ButtonType.SUBMIT}
											onClick={ev => {
												ev.preventDefault();
												setShouldShowVideoPreview(false);
											}}>
											{translate('startCall')}
										</Button>
									</div>
								)}
								<div></div>
							</div>
						</main>
					</section>
					{isAuthenticated() && isStreamSettingsOpen && (
						<StyledStreamSettings $isDarkMode={true}>
							<Modal
								onDismiss={() => setIsStreamSettingsOpen(false)}
								title={intl.formatMessage({ id: 'yourAudioAndVideoSettings' })}
								position='right'>
								<Modal.Content>
									<StreamSettings tracks={tracks} />
									<BackgroundImagesSettings videoRef={videoRef} streamEffect={streamEffect} tracks={tracks} />
								</Modal.Content>
							</Modal>
						</StyledStreamSettings>
					)}
				</StyledJoinWithAdjustments>
			)}
			{!shouldShowVideoPreview && (
				<ChildComponent
					children={children}
					props={{ conferenceInfo: { ...conferenceInfo, localTrackTypes: Object.keys(tracks) } }}
				/>
			)}
		</>
	);
};

export default JoinWithAdjustments;
