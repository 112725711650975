import { SettingsCategories } from 'constants/configurationsConstants.js';
import { PatientAiSetting, SectorTypes, StatAlarmType, TalkToPatient, UserLanguage, UserRoles } from 'constants/enums.js';
import { RoomTypes } from 'constants/visitEnums.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
const healthCareCdnUrl = 'https://static.solaborate.com/healthcare-system/';
const statAlarmCdnUrl = `${healthCareCdnUrl}admin/super-admin/stat-alarms/alarm-`;

export const INDEPENDENT_FEATURE_TYPE = 999;

export const NurseMenu = {
	ROUNDING: 27,
	WAITING_ROOM: 24,
	CHAT: 33,
	PATIENTS: 997,
	TEAMS: 34,
	MONITORING: 35,
	PATIENT_MONITORING: 28,
	WAITING_FOR_RESULTS: 43,
	CHECK_IN: 36,
	VISITORS: 55,
	LOGISTICS: 276,
	AMBIENT_MONITORING: 207,
	CARE_EVENTS: 197,
	HOSPITAL_AT_HOME: 308,
};

export const VirtualSitterMenu = {
	TEAMS: 34,
	MONITORING: 35,
	AMBIENT_MONITORING: 207,
};

export const PatientMenu = {
	HOME: 23,
	RECENT: 29,
	APPOINTMENTS: 26,
	HEALTH: 30,
	SUMMARY: 31,
	HELLO: 64,
};

export const DoctorMenu = {
	HOME: 23,
	WAITING_ROOM: 24,
	WAITING_ROOM_IN_PERSON: 105,
	PATIENTS: 25,
	HOSPITAL_AT_HOME: 308,
	APPOINTMENTS: 26,
	ROUNDING: 27,
	PATIENT_MONITORING: 28,
	LOGISTICS: 276,
	SESSIONS_QUEUE: 186,
};

export const SuperUserMenu = {
	ORGANIZATION: 19,
	CALL_LOGS: 20,
	USER_MANAGEMENT: 18,
	CONFIGURATION: 21,
	QUEUE_MANAGEMENT: 22,
	DASHBOARD: 192,
	FLEET_MANAGEMENT: 222,
	NURSE_STATION: 334,
};

export const DigitalClinicianMenu = {
	CHECK_IN: 36,
	PATIENTS: 25,
	VISITS: 44,
	REGISTER_PATIENT: 45,
};

export const SuperAdminMenu = {
	COMPANIES: 17,
	USER_MANAGEMENT: 18,
	AUDIT_LOGS: 42,
};

export const AdminMenu = {
	USER_MANAGEMENT: 18,
	ORGANIZATION: 19,
	CALL_LOGS: 20,
	CONFIGURATION: 21,
	AUDIT_LOGS: 42,
	DASHBOARD: 192,
	FLEET_MANAGEMENT: 222,
	NURSE_STATION: 334,
};

export const UserSettingTypes = {
	Other: 4, // Company Settings
	Monitoring: 3,
	Navigation: 2,
	GeneralSettings: 1,
	CallsSettings: 8,
	AiSettings: 14,
};

export const MonitoringSettings = {
	RaiseAlert: 57,
	ContactNurses: 58,
	CallPatient: 59,
	WalkieTalkie: 60,
	MicrosoftTeams: 61,
	Call911: 62,
	NightVision: 12,
	EHR: 65,
	Notes: 37,
	CareEventsForNurses: 38,
	CareEventsForSafetyCompanions: 39,
	ForwardToNurses: 40,
	Precautions: 46,
	Privacy: 48,
	Recording: 49,
	DisableVisits: 15,
	PTZCam: 117,
	SmartWatchVitalSigns: 118,
	BiobeatVitalSigns: 119,
	StatAlarm: 144,
	LifeSignals: 145,
	EditRoomNameMonitoring: 147,
	EnableMultipleFeedAudio: 66,
	PatientCommunication: 202,
	MonitoringHealthData: 216,
	RTLS: 219,
	VerbalRedirection: 220,
	PatientOutOfRoom: 221,
	MixedMonitoringView: 235,
	PatientInfo: 251,
	Whiteboard: 252,
	DisplayControl: 286,
	DiscontinueMonitoring: 287,
	PatientName: 297,
	PatientAge: 298,
	NurseNameNumber: 299,
	NurseAssistantNameNumber: 300,
	TlNameNumber: 301,
	PrimaryAdverseEvent: 302,
	PrimaryPatientCondition: 303,
	PrimaryPatientRiskFactor: 304,
	PreferredLanguage: 305,
	TitleBarNotes: 306,
	AdditionalPatientNotes: 307,
	PatientGenderIdentity: 328,
	IndicationForVideoMonitoring: 329,
	SuspiciousBehavior: 332,
	InterpreterNeeded: 335,
	TitleBarPatientName: 338,
	TitleBarPatientAge: 339,
	TitleBarNurseNameNumber: 340,
	TitleBarNurseAssistantNameNumber: 341,
	TitleBarTlNameNumber: 342,
	TitleBarPrimaryAdverseEvent: 343,
	TitleBarPrimaryPatientCondition: 344,
	TitleBarPrimaryPatientRiskFactor: 345,
	TitleBarPreferredLanguage: 346,
	TitleBarTitleBarNotes: 347,
	TitleBarAdditionalPatientNotes: 348,
	TitleBarPatientGenderIdentity: 349,
	TitleBarIndicationForVideoMonitoring: 350,
	TitleBarInterpreterNeeded: 351,
	Mindray: 999111,
};

export const CompanySettings = {
	CLIENT_THEME_COLOR: 1,
	TWO_FACTOR_TYPE: 2,
	LENGTH_UNIT: 4,
	WEIGHT_UNIT: 5,
	TEMPERATURE_UNIT: 6,
	HEIGHT_UNIT: 7,
	BLOOD_GLUCOSE_UNIT: 8,
	DATE_FORMAT: 9,
	TIME_FORMAT: 10,
	LANGUAGE: 11,
	TALK_TO_PATIENT_ROUTE: 101,
	MONITORING_ROUTE: 102,
	VIEW_PATIENT_ROUTE: 103,
	E2EE: 106,
	DIAL_OUT: 108,
	MULTIPARTY_CALL: 123,
	HELLO_DEVICE: 121,
	HUDDLE_CAMERA: 122,
	VCP_DISPLAY_NAME: 250,
	DOCTOR_DISPLAY_NAME: 314,
	VIRTUAL_SITTER_DISPLAY_NAME: 331,
	BEING_MONITORED_MESSAGE: 261,
	THEME: 3,
	PATIENT_VIRTUAL_ADMISSION: 203,
	PATIENT_MANUAL_ADMISSION: 204,
	PATIENT_EHR_ADMISSION: 205,
	DIGITAL_BACKGROUND: 190,
	HS_DEFAULT_BACKGROUND: 191,
	HELLO_DEFAULT_BACKGROUND: 196,
	HELLO_DEFAULT_WALLPAPER: 208,
	SUPPORT_CHAT: 211,
	CAMERA_USE: 218,
	AUDIO_CALL_BACKGROUND: 322,
	CALL_ROUTING_OPTIONS: 333,
};

export const CameraUseOption = {
	SINGLE_CAMERA_USE: 'single-use',
	MULTI_CAMERA_USE: 'multi-use',
};

export const CallRoutingValues = {
	DEFAULT: 'default',
	TURN: 'turn',
};

export const RouteSetting = {
	TALK_TO_PATIENT_ROUTE: 101,
	MONITORING_ROUTE: 102,
	VIEW_PATIENT_ROUTE: 103,
};

export const UserSettings = {
	CALL_BACKGROUND: 194,
};

export const CompanyCallSettings = {
	PROVIDER_MIC: 195,
	PATIENT_MIC: 200,
	PROVIDER_CAMERA: 199,
	PATIENT_CAMERA: 201,
	ROUNDING_DEFAULT_CAMERA: 282,
	MONITORING_DEFAULT_CAMERA: 283,
	RS_PROVIDER_MIC: 309,
	RS_PATIENT_MIC: 310,
	RS_PROVIDER_CAM: 311,
	RS_PATIENT_CAM: 312,
};

export const ConfigurableCallSettings = [
	{
		key: CompanyCallSettings.PROVIDER_MIC,
		title: 'providerMic',
		name: 'providerMic',
		description: 'providerMicDesc',
		icon: `${healthCareCdnUrl}microphone/mic-black.svg`,
	},
	{
		key: CompanyCallSettings.PATIENT_MIC,
		title: 'patientMicrophone',
		name: 'patientMicrophone',
		description: 'patientMicDesc',
		icon: `${healthCareCdnUrl}microphone/mic-black.svg`,
	},
	{
		key: CompanyCallSettings.PROVIDER_CAMERA,
		title: 'providerCamera',
		name: 'providerCamera',
		description: 'disableProviderCameraDesc',
		icon: `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`,
	},
	{
		key: CompanyCallSettings.PATIENT_CAMERA,
		title: 'patientCamera',
		name: 'patientCamera',
		description: 'patientCameraDesc',
		icon: `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`,
	},
];

export const ConfigurableRoomSignCallSettings = [
	{
		key: CompanyCallSettings.RS_PROVIDER_MIC,
		title: 'providerMic',
		name: 'rsProviderMic',
		description: 'providerMicDesc',
		icon: `${healthCareCdnUrl}microphone/mic-black.svg`,
	},
	{
		key: CompanyCallSettings.RS_PATIENT_MIC,
		title: 'patientMicrophone',
		name: 'rsPatientMic',
		description: 'patientMicDesc',
		icon: `${healthCareCdnUrl}microphone/mic-black.svg`,
	},
	{
		key: CompanyCallSettings.RS_PROVIDER_CAM,
		title: 'providerCamera',
		name: 'rsProviderCamera',
		description: 'disableProviderCameraDesc',
		icon: `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`,
	},
	{
		key: CompanyCallSettings.RS_PATIENT_CAM,
		title: 'patientCamera',
		name: 'rsPatientCamera',
		description: 'patientCameraDesc',
		icon: `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`,
	},
];

export const IntegrationTypesSettings = {
	ALEXA: 124,
	TELEHEALTH: 148,
	EVIDEON: 149,
	PCARE: 150,
	GET_WELL: 151,
	SONIFI: 152,
	AZURE_AD: 153,
	PING_FEDERATE: 154,
	OKTA: 254,
	HILL_ROM: 223,
	EPIC: 155,
	CERNER: 156,
	GLOBO: 157,
	AMN: 158,
	HL7_V2: 237,
	MDM: 241,
	DUO_SSO: 281,
	LANGUAGE_LINE: 327,
	VOYCE: 326,
	AZURE_BLOB_STORAGE: 360,
	SHOW_CCC: 359,
	FUJI: 355,
	GE: 356,
	CCAD: 373,
	CBORD: 376,
};

export const HelloCareEngagementSettings = {
	HOME: 172,
	EDUCATION: 173,
	LIVE_TV: 174,
	MOVIES: 175,
	MUSIC: 176,
	MOODS: 177,
	APPS: 178,
	FAMILY_FRIENDS: 179,
	MEALS: 180,
	WHITEBOARD: 181,
	CARE_TEAM: 182,
	HEALTH_DATA: 187,
	PODCASTS: 242,
	GAMES: 243,
	HDMI_IN: 244,
	ROOM_CONTROLS: 245,
	SETTINGS: 260,
	WHITEBOARD_PIP: 273,
	VIRTUAL_NURSE: 323,
	DEFAULT_VIEW: 325,
};

export const WhiteboardSettings = {
	WHITEBOARD_URL: 226,
	PIN: 246,
};

export const configurableWhiteboardSettings = () => ({
	[WhiteboardSettings.WHITEBOARD_URL]: getConfigurationObj({
		title: 'whiteboardURL',
		value: 'false',
		isLocked: false,
		isURLField: true,
		valid: true,
		description: 'engagementWhiteboardURLDescription',
		category: 'whiteboardSettings',
	}),
	[WhiteboardSettings.PIN]: getConfigurationObj({
		title: 'userAutoGeneratedPin',
		value: false,
		isLocked: false,
		description: 'autoGeneratedPinDesc',
		category: 'whiteboardSettings',
	}),
});

export const configurableHelloCareEngagement = (sectorLevel = null) => ({
	[HelloCareEngagementSettings.DEFAULT_VIEW]: getConfigurationObj({
		title: 'defaultView',
		value: true,
		isLocked: false,
		description: 'engagementDefaultViewDescription',
		category: 'helloCareEngagementSettings',
		orderNr: 1,
		...(sectorLevel === SectorTypes.HEALTH_SYSTEM && {
			isSelectDefaultConfig: true,
			variant: { dependentFrom: HelloCareEngagementSettings.HOME, value: HellocareEngagementVariants.HOME, label: 'homeWidget' },
			options: [
				{ dependentFrom: HelloCareEngagementSettings.HOME, value: HellocareEngagementVariants.HOME, label: 'homeWidget' },
				{
					dependentFrom: HelloCareEngagementSettings.EDUCATION,
					value: HellocareEngagementVariants.EDUCATION,
					label: 'educationWidget',
				},
				{ dependentFrom: HelloCareEngagementSettings.LIVE_TV, value: HellocareEngagementVariants.LIVE_TV, label: 'liveTvWidget' },
				{ dependentFrom: HelloCareEngagementSettings.MOVIES, value: HellocareEngagementVariants.MOVIES, label: 'moviesWidget' },
				{ dependentFrom: HelloCareEngagementSettings.MUSIC, value: HellocareEngagementVariants.MUSIC, label: 'musicWidget' },
				{ dependentFrom: HelloCareEngagementSettings.APPS, value: HellocareEngagementVariants.APPS, label: 'appsWidget' },
				{
					dependentFrom: HelloCareEngagementSettings.WHITEBOARD,
					value: HellocareEngagementVariants.WHITEBOARD,
					label: 'whiteboard',
				},
			],
		}),
	}),
	[HelloCareEngagementSettings.HOME]: getConfigurationObj({
		title: 'homeWidget',
		value: true,
		isLocked: false,
		description: 'engagementHomeDescription',
		category: 'helloCareEngagementSettings',
		orderNr: 2,
	}),
	[HelloCareEngagementSettings.EDUCATION]: getConfigurationObj({
		title: 'educationWidget',
		value: true,
		isLocked: false,
		description: 'engagementEducationDescription',
		category: 'helloCareEngagementSettings',
		orderNr: 3,
	}),
	[HelloCareEngagementSettings.LIVE_TV]: getConfigurationObj({
		title: 'liveTvWidget',
		value: true,
		isLocked: false,
		description: 'engagementLiveTVDescription',
		category: 'helloCareEngagementSettings',
		orderNr: 4,
	}),
	[HelloCareEngagementSettings.MOVIES]: getConfigurationObj({
		title: 'moviesWidget',
		value: true,
		isLocked: false,
		description: 'engagementMoviesDescription',
		category: 'helloCareEngagementSettings',
		orderNr: 5,
	}),
	[HelloCareEngagementSettings.MUSIC]: getConfigurationObj({
		title: 'musicWidget',
		value: true,
		isLocked: false,
		description: 'engagementMusicDescription',
		category: 'helloCareEngagementSettings',
		orderNr: 6,
	}),
	[HelloCareEngagementSettings.MOODS]: getConfigurationObj({
		title: 'moodsWidget',
		value: true,
		isLocked: false,
		description: 'engagementMoodsDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.APPS]: getConfigurationObj({
		title: 'appsWidget',
		value: true,
		isLocked: false,
		description: 'engagementAppsDescription',
		category: 'helloCareEngagementSettings',
		orderNr: 7,
	}),
	[HelloCareEngagementSettings.FAMILY_FRIENDS]: getConfigurationObj({
		title: 'familyAndFriendsWidget',
		value: true,
		isLocked: false,
		description: 'engagementFamilyFriendsDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.MEALS]: getConfigurationObj({
		title: 'orderMealsWidget',
		value: true,
		isLocked: false,
		description: 'engagementOrderMealDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.WHITEBOARD]: getConfigurationObj({
		title: 'whiteBoardWidget',
		value: true,
		isLocked: false,
		description: 'engagementWhiteboardDescription',
		category: 'helloCareEngagementSettings',
		orderNr: 8,
	}),
	[HelloCareEngagementSettings.CARE_TEAM]: getConfigurationObj({
		title: 'careTeamWidget',
		value: true,
		isLocked: false,
		description: 'engagementCareTeamDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.HEALTH_DATA]: getConfigurationObj({
		title: 'healthDataWidget',
		value: true,
		isLocked: false,
		description: 'engagementHealthDataDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.PODCASTS]: getConfigurationObj({
		title: 'podcastsWidget',
		value: true,
		isLocked: false,
		description: 'podcastsWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.GAMES]: getConfigurationObj({
		title: 'gamesWidget',
		value: true,
		isLocked: false,
		description: 'gamesWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.HDMI_IN]: getConfigurationObj({
		title: 'hdmiInWidget',
		value: true,
		isLocked: false,
		description: 'hdmiInWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.ROOM_CONTROLS]: getConfigurationObj({
		title: 'roomControlsWidget',
		value: true,
		isLocked: false,
		description: 'roomControlsWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.SETTINGS]: getConfigurationObj({
		title: 'settingsWidget',
		value: true,
		isLocked: false,
		description: 'settingsWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.WHITEBOARD_PIP]: getConfigurationObj({
		title: 'whiteboardPiPWidget',
		value: true,
		isLocked: false,
		description: 'whiteboardPiPWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.VIRTUAL_NURSE]: getConfigurationObj({
		title: 'virtualNurse',
		value: false,
		isLocked: false,
		description: 'virtualNurseAvatarDescription',
		category: 'helloCareEngagementSettings',
	}),
});

export const MonitoringFeatureFlagCategories = {
	GENERAL: 'general',
	COMMUNICATE_WITH_PATIENT: 'communicatingWithThePatient',
	COMMUNICATE_WITH_NURSES: 'communicatingWithNurses',
	AUTOMATIC_ALERTS: 'automaticAlerts',
	PATIENT_INFO: 'patientInfo',
};

export const RoundingFeaturesCategories = {
	GENERAL: 'general',
	LIVE_EXAMINATIONS: 'liveExaminations',
	INVITING_PEOPLE: 'invitingPeople',
	MEDICAL_INFO: 'medicalInfo',
	PATIENT_HISTORY: 'patientHistory',
};
export const AiSettingFeaturesCategory = {
	GENERAL_SETTINGS: 'generalSettings',
	AI_SETTINGS: 'aiSettings',
	EVIDENCE_CONFIGURATIONS: 'alertsEvidenceConfigurations',
};

export const PatientTabsFeaturesCategories = {
	PATIENTS: 'patients',
};

export const RoomTypesFeaturesCategories = {
	ROOM_TYPES_SETTINGS: 'roomTypesSettings',
};

export const UserExperienceFeaturesCategories = {
	USER_EXPERIENCE: 'userExperience',
};

export const GeneralAndMenuFeaturesCategories = {
	GENERAL_SETTINGS: 'generalSettings',
	MENU_SETTINGS: 'menuSettings',
};

export const IntegrationFeaturesCategories = {
	EXTERNAL_IDENTITY_PROVIDERS: 'externalIdentityProviders',
	EHR: 'ehr',
	PATIENT_ENGAGEMENT: 'patientEngagement',
	TRANSLATION_SERVICES: 'interpretationServices',
	ALEXA: 'alexaEnabled',
	NURSE_CALLS_SYSTEMS: 'nurseCallsSystems',
	CLOUD_STORAGE: 'cloudStorage',
	PACS: 'pacs',
	MEAL_ORDER_SERVICE: 'mealOrderServices',
};

export const NoteVariants = {
	SHOW_UNDER_TILE: 'show-under-tile',
	SHOW_ON_EXPAND: 'show-on-expand',
};

export const MixedViewVariants = {
	VIDEO_MONITORING: 'video-monitoring',
	AMBIENT_MONITORING: 'ambient-monitoring',
};

export const PatientInfoFieldsVariants = {
	HELLOCARE_PATIENT_DATA_LOAD: 'hellocare-patient-data-load',
	EHR_PATIENT_DATA_LOAD: 'ehr-patient-data-load',
};

export const CallerNameVariant = {
	FULL_NAME: 'fullname',
	CREDENTIALS: 'credentials',
};

export const InviteViaSmsVariant = {
	WITH_INVITATION_NOTE: 'with-invitation-note',
	WITHOUT_INVITATION_NOTE: 'without-invitation-note',
};

export const SettingsCategory = {
	GENERAL: 1,
	CUSTOMIZATION: 2,
	MONITORING: 3,
	OTHER: 4,
	MOBILE_LIVE_EXAMINATIONS: 5,
	TV_NAVIGATION: 6,
	ROUNDING: 7,
	CALL: 8,
	AUDIO_ROUTING: 9,
	INTEGRATIONS: 10,
	HELLO_CARE_ENGAGEMENT: 11,
	AI_SETTINGS: 14,
	USER_EXPERIENCE: 16,
	DASHBOARD_REPORTS: 17,
	WHITEBOARD: 18,
	ROOM_TYPES: 19,
	HOSPITAL_AT_HOME: 20,
	VOICE_COMMANDS: 21,
	NICU: 22,
};

export const VoiceCommandsSettings = {
	VC_EMERGENCY_ASSISTANCE: 315,
	VC_CARE_EVENTS: 316,
	VC_PRIVACY_ENABLING: 317,
	VC_CALL_NURSE: 318,
	VC_CALL_INTERPRETER: 374,
};

export const AiSetting = {
	DISABLE_AI: 16,
	EWS_AI_VITAL_SIGNS: 109,
	GET_OUT_OF_BED: 110,
	RAILS: 111,
	PATIENT_MOBILITY: 112,
	FALL_DETECTION: 113,
	HAND_WASHING: 114,
	AI_PRIVACY_MODE: 115,
	PRESSURE_INJURY: 120,
	IV_BAG_FLUID_LEVEL: 170,
	DATA_COLLECTION: 217,
	AI_DETECTION_DRAWING: 256,
	SOUND_ON_AI_ALERT: 257,
	TRAINING_MODE: 277,
	AI_VOICE_ANALYSIS: 319,
	ALERTS_EVIDENCE_ADMIN: 321,
	ALERTS_EVIDENCE_NURSE: 324,
	SLEEP_QUALITY_TRACKING: 357,
	GET_OUT_OF_CHAIR: 370,
};

export const ConfigSettingType = {
	TOGGLE: 'toggle',
	SELECT: 'select',
	LOCK: 'lock',
	CHECKBOX: 'checkbox',
};

export const RoundingSettings = {
	ExamKit: 91,
	Wearables: 92,
	MedicalDevices: 93,
	AiMeasurements: 94,
	BiobeatPatch: 125,
	LifeSignalsPatch: 126,
	RoundingVisits: 134,
	RoundingHealthData: 135,
	RoundingAlertHistory: 132,
	PatientDiagnoses: 127,
	PatientProcedures: 128,
	PatientNotes: 129,
	PatientPrescriptions: 130,
	PatientPhysicalTherapy: 131,
	ScreenSharing: 136,
	FitToScreen: 137,
	GridView: 138,
	RoundingNightVision: 139,
	MeetingUrl: 140,
	InviteViaSms: 141,
	TranslationServices: 142,
	InviteViaEmail: 143,
	DialIn: 107,
	DialOut: 108,
	EditRoomNameRounding: 146,
	SessionWrapUp: 185,
	CareEventsForNurses: 188,
	CareEventsForSafetyCompanions: 189,
	DefaultHelloBackground: 196,
	CaregiversName: 193,
	LiveCaptions: 198,
	TalkToPatient: 224,
	ViewPatient: 225,
	RoundingCareEvents: 253,
	RateCall: 255,
	Whiteboard: 274,
	CancelInvite: 279,
	RoomSign: 280,
	DisplayControl: 284,
	Ear: 68,
	Throat: 69,
	Heart: 70,
	Lungs: 71,
	HeartRate: 72,
	OxygenSaturation: 73,
	Temperature: 74,
	Skin: 75,
	BloodPressure: 76,
	ECG: 77,
	BloodGlucose: 78,
	Weight: 79,
	Spirometer: 80,
	Abdomen: 81,
	TVControls: 336,
	ThirdPartyMembersMic: 337,
	EnableCameraToggleBtn: 363,
	VideoPreview: 364,
};

export const MedicalDevicesConfigsList = [
	RoundingSettings.Ear,
	RoundingSettings.Throat,
	RoundingSettings.Heart,
	RoundingSettings.Lungs,
	RoundingSettings.HeartRate,
	RoundingSettings.OxygenSaturation,
	RoundingSettings.Temperature,
	RoundingSettings.Skin,
	RoundingSettings.BloodPressure,
	RoundingSettings.ECG,
	RoundingSettings.BloodGlucose,
	RoundingSettings.Weight,
	RoundingSettings.Spirometer,
	RoundingSettings.Abdomen,
];

export const GeneralAndMenuSettings = {
	MONITORING_PRECAUTIONS_LEGEND: 14,
	COMPANIES: 17,
	USER_MANAGEMENT: 18,
	ORGANIZATION: 19,
	CALL_LOGS: 20,
	CONFIGURATION: 21,
	QUEUE_MANAGEMENT: 22,
	HOME: 23,
	WAITING_ROOM: 24,
	PATIENTS: 25,
	APPOINTMENTS: 26,
	ROUNDING: 27,
	PATIENT_MONITORING: 28,
	RECENT: 29,
	HEALTH: 30,
	SUMMARY: 31,
	CHAT: 33,
	TEAMS: 34,
	MONITORING: 35,
	CHECK_IN: 36,
	AUDIT_LOGS: 42,
	WAITING_FOR_RESULTS: 43,
	VISITS: 44,
	REGISTER_PATIENT: 45,
	POOLING_FLOW: 51,
	CONVERSATION_HISTORY: 52,
	NOTIFICATIONS: 53,
	MONITORING_SESSIONS: 54,
	VISITORS: 55,
	HELLO: 64,
	CONVERSATION_FILES: 104,
	CONVERSATION_PHOTOS: 105,
	SESSIONS_QUEUE: 186,
	DASHBOARD: 192,
	AMBIENT_MONITORING: 207,
	SUPPORT_CHAT: 211,
	CARE_EVENTS: 197,
	IS_OLD_USER_EXPERIENCE: 234,
	FLEET_MANAGEMENT: 222,
	IS_EXPERIENCE_HS_CHANGEABLE: 236,
	LOGISTICS: 276,
	AI_NOTIFICATIONS: 285,
	HOSPITAL_AT_HOME: 308,
	REGISTRATION_FORM: 313,
	NURSE_STATION: 334,
	OVERVIEW: 227,
	PATIENT_HEALTH: 230,
	PHYSICAL_THERAPY: 231,
	PATIENT_VISITS: 232,
	ALERT_HISTORY: 233,
	SOUND_NOTIFICATION: 354,
	RPM: 352,
	CPM: 353,
	LOCAL_USERS: 361,
	EXTERNAL_USERS: 362,
	RPM_PROGRAMS: 366,
};

export const PatientsConfigsList = [
	GeneralAndMenuSettings.OVERVIEW,
	GeneralAndMenuSettings.PATIENT_HEALTH,
	GeneralAndMenuSettings.CONVERSATION_HISTORY,
	GeneralAndMenuSettings.PHYSICAL_THERAPY,
	GeneralAndMenuSettings.PATIENT_VISITS,
	GeneralAndMenuSettings.ALERT_HISTORY,
];

export const RoomModesSettings = {
	HELLOCARE: 169,
	AI_WIDGET: 161,
	WIDGET: 162,
	DIGITAL_CLINICIAN: 163,
	TELEMEDICINE: 164,
	HOSPITAL: 165,
	NICU: 159,
	VIRTUAL_EXAMINATION: 166,
	SIMPLIFIED: 167,
	E_VIDEON: 168,
	HELLOCARE_ENGAGEMENT: 160,
	HOSPITAL_AT_HOME: 296,
};

export const HospitalAtHomeSettings = {
	APPOINTMENTS: 288,
	LEAD_NURSE: 289,
	VITAL_SIGNS: 290,
	CALL_CARE_TEAM: 291,
	EDUCATION: 292,
	TAKE_PICTURE: 293,
	MEDICATION: 294,
	CHAT: 295,
};

export const RoomTypesSettings = {
	BABY_ROOM: 262,
	FRONT_DESK: 263,
	DOCTOR_ROOM: 264,
	LABORATORY_ROOM: 265,
	INJECTION_ROOM: 266,
	INFUSION_ROOM: 267,
	DIGITAL_CLINIC: 268,
	PHARMACY_ROOM: 269,
	RADIOLOGY_ROOM: 270,
	KIOSK: 271,
	TRIAGE: 272,
};

export const DashboardSettings = {
	OVERVIEW: 238,
	PROVIDER_CENTRIC: 239,
	PATIENT_CENTRIC: 240,
	AI_ALERTS: 278,
	PATIENT_INTERVENTIONS: 171,
	HAND_HYGIENE: 320,
	SESSION_LOGS: 372,
};

export const CallWorkflowType = {
	MONITORING: 1,
	ROUNDING: 2,
	TRAINING_MODE: 3,
	BACKGROUND: 4,
};

const getConfigurationObj = config => ({ ...config });

export const getConfig = config =>
	config
		? {
				isLocked: config.isLocked,
				value: JSON.parse(config.value),
				description: config.description,
		  }
		: { isLocked: false, value: false, description: '' };

export const TelemedicineModesSettings = {
	INTAKE_FORM: 183,
	DOCTOR_POOLING: 184,
	RAPID_CALL: 247,
	REGULAR_CALL: 248,
	ADMISSION_CALL: 249,
	SUBMIT_NEW_CASE: 259,
	SHARED_QUEUE: 275,
};

export const IntakeFormVariants = {
	SCP_HEALTH_FORM: 'scp-health-form',
	BAYCARE_FORM: 'baycare-form',
};

export const NICUSettings = {
	NICU_MIC: 368,
	NICU_PARENT_SOUND: 369,
};

export const configurableNICUSettings = () => ({
	[NICUSettings.NICU_MIC]: getConfigurationObj({
		title: 'nICUMicrophone',
		value: false,
		description: 'nICUMicrophoneDesc',
		category: 'nicuSettings',
	}),
	[NICUSettings.NICU_PARENT_SOUND]: getConfigurationObj({
		title: 'nICUParentsSound',
		value: false,
		description: 'nICUParentsSoundDesc',
		category: 'nicuSettings',
	}),
});

export const configurableVoiceCommandsSettings = () => ({
	[VoiceCommandsSettings.VC_EMERGENCY_ASSISTANCE]: getConfigurationObj({
		title: 'voiceCommandForEmergencyAssistance',
		value: false,
		description: 'vCEADesc',
		category: 'voiceCommandsSettings',
	}),
	[VoiceCommandsSettings.VC_CARE_EVENTS]: getConfigurationObj({
		title: 'voiceCommandForCareEvents',
		value: false,
		description: 'vCCEDesc',
		category: 'voiceCommandsSettings',
	}),
	[VoiceCommandsSettings.VC_PRIVACY_ENABLING]: getConfigurationObj({
		title: 'voiceCommandForPrivacyEnabling',
		value: false,
		description: 'vCPDesc',
		category: 'voiceCommandsSettings',
	}),
	[VoiceCommandsSettings.VC_CALL_NURSE]: getConfigurationObj({
		title: 'voiceCommandForCallNurse',
		value: false,
		description: 'vCCNDesc',
		category: 'voiceCommandsSettings',
		variant: { value: UserLanguage.ENGLISH, label: 'english' },
		options: [
			{ value: UserLanguage.ENGLISH, label: 'english' },
			{ value: UserLanguage.ARABIC, label: 'arabic' },
		],
	}),
	[VoiceCommandsSettings.VC_CALL_INTERPRETER]: getConfigurationObj({
		title: 'voiceCommandInterpreterCalls',
		value: false,
		description: 'vCIDesc',
		category: 'voiceCommandsSettings',
	}),
});

export const configurableDashboardSettings = () => ({
	[DashboardSettings.OVERVIEW]: getConfigurationObj({
		title: 'overview',
		value: true,
		description: 'overviewDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.SESSION_LOGS]: getConfigurationObj({
		title: 'allSessions',
		value: true,
		description: 'sessionLogsDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.PROVIDER_CENTRIC]: getConfigurationObj({
		title: 'careTeamReport',
		value: true,
		description: 'providerCentricReportDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.PATIENT_CENTRIC]: getConfigurationObj({
		title: 'patientReport',
		value: true,
		description: 'patientCentricReportDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.AI_ALERTS]: getConfigurationObj({
		title: 'aiAlerts',
		value: true,
		description: 'aiAlertsDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.PATIENT_INTERVENTIONS]: getConfigurationObj({
		title: 'patientInterventionsReport',
		value: false,
		description: 'patientInterventionsReportDesc',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.HAND_HYGIENE]: getConfigurationObj({
		title: 'dashboardHandHygiene',
		value: false,
		description: 'hHDashboardDesc',
		category: 'dashboardSettings',
	}),
});

const TelemedicineModesCategories = {
	GENERAL: 'general',
	DOCTOR_LIST: 'doctorList',
	QUEUE_MANAGEMENT: 'queueManagement',
	SHARED_QUEUE: 'sharedQueue',
};

export const configurableTelemedicineRoomModesSettings = () => ({
	[TelemedicineModesSettings.INTAKE_FORM]: getConfigurationObj({
		title: 'intakeForm',
		value: false,
		description: 'intakeFormDescription',
		variant: { value: IntakeFormVariants.SCP_HEALTH_FORM, label: 'scpHealthForm' },
		options: [
			{ value: IntakeFormVariants.SCP_HEALTH_FORM, label: 'scpHealthForm' },
			{ value: IntakeFormVariants.BAYCARE_FORM, label: 'baycareForm' },
		],
		category: TelemedicineModesCategories.GENERAL,
	}),
	[TelemedicineModesSettings.DOCTOR_POOLING]: getConfigurationObj({
		title: 'doctorPooling',
		value: false,
		description: 'doctorPoolingDescription',
		category: TelemedicineModesCategories.DOCTOR_LIST,
	}),
	[TelemedicineModesSettings.RAPID_CALL]: getConfigurationObj({
		title: 'rapidCall',
		value: false,
		description: 'rapidCallDescription',
		category: TelemedicineModesCategories.QUEUE_MANAGEMENT,
	}),
	[TelemedicineModesSettings.REGULAR_CALL]: getConfigurationObj({
		title: 'regularCall',
		value: false,
		description: 'regularCallDescription',
		category: TelemedicineModesCategories.QUEUE_MANAGEMENT,
	}),
	[TelemedicineModesSettings.ADMISSION_CALL]: getConfigurationObj({
		title: 'admissionCall',
		value: false,
		description: 'admissionCallDescription',
		category: TelemedicineModesCategories.QUEUE_MANAGEMENT,
	}),
	[TelemedicineModesSettings.SHARED_QUEUE]: getConfigurationObj({
		title: 'sharedQueue',
		value: false,
		description: 'sharedQueueDescription',
		category: TelemedicineModesCategories.SHARED_QUEUE,
	}),
	[TelemedicineModesSettings.SUBMIT_NEW_CASE]: getConfigurationObj({
		title: 'submitNewCase',
		value: false,
		description: 'submitNewCaseDescription',
		category: TelemedicineModesCategories.SHARED_QUEUE,
	}),
});

export const configurableRoomType = () => [
	{
		value: RoomTypes.DEFAULT.type,
		label: 'room',
	},
	{
		value: RoomTypes.BABY_ROOM.type,
		label: 'babyRoom',
	},
];

export const configurableRoomTypesSettings = () => ({
	[RoomTypesSettings.BABY_ROOM]: {
		title: 'babyRoom',
		value: false,
		description: 'babyRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.FRONT_DESK]: {
		title: 'frontDesk',
		value: false,
		description: 'frontDeskRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.DOCTOR_ROOM]: {
		title: 'doctor',
		value: false,
		description: 'doctorRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.LABORATORY_ROOM]: {
		title: 'laboratory',
		value: false,
		description: 'laboratoryRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.INJECTION_ROOM]: {
		title: 'injectionRoom',
		value: false,
		description: 'injectionRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.INFUSION_ROOM]: {
		title: 'infusionRoom',
		value: false,
		description: 'infusionRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.DIGITAL_CLINIC]: {
		title: 'digitalClinic',
		value: false,
		description: 'digitalClinicRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.PHARMACY_ROOM]: {
		title: 'pharmacy',
		value: false,
		description: 'pharmacyRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.RADIOLOGY_ROOM]: {
		title: 'radiology',
		value: false,
		description: 'radiologyRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.KIOSK]: {
		title: 'kiosk',
		value: false,
		description: 'kioskRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.TRIAGE]: {
		title: 'triage',
		value: false,
		description: 'triageRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
});

export const configurableWorkflowTypes = () => [
	{
		value: CallWorkflowType.MONITORING,
		label: 'monitoring',
	},
	{
		value: CallWorkflowType.ROUNDING,
		label: 'rounding',
	},
	{
		value: CallWorkflowType.BACKGROUND,
		label: 'backgroundAI',
	},
];

export const HospitalAtHomeCategories = {
	HOSPITAL_AT_HOME: 'hospitalAtHomeSettings',
};

export const configurableHospitalAtHome = () => ({
	[HospitalAtHomeSettings.APPOINTMENTS]: getConfigurationObj({
		title: 'hospitalAtHomeAppointments',
		value: false,
		description: 'appointmentsDescription',
		category: HospitalAtHomeCategories.HOSPITAL_AT_HOME,
	}),
	[HospitalAtHomeSettings.LEAD_NURSE]: getConfigurationObj({
		title: 'hospitalAtHomeLeadNurse',
		value: false,
		description: 'leadNurseDescription',
		category: HospitalAtHomeCategories.HOSPITAL_AT_HOME,
	}),
	[HospitalAtHomeSettings.VITAL_SIGNS]: getConfigurationObj({
		title: 'hospitalAtHomeVitalSigns',
		value: false,
		description: 'vitalSignsHAHDescription',
		category: HospitalAtHomeCategories.HOSPITAL_AT_HOME,
	}),
	[HospitalAtHomeSettings.CALL_CARE_TEAM]: getConfigurationObj({
		title: 'hospitalAtHomeCallCareTeam',
		value: false,
		description: 'callCareTeamDescription',
		category: HospitalAtHomeCategories.HOSPITAL_AT_HOME,
	}),
	[HospitalAtHomeSettings.EDUCATION]: getConfigurationObj({
		title: 'hospitalAtHomeEducation',
		value: false,
		description: 'educationDescription',
		category: HospitalAtHomeCategories.HOSPITAL_AT_HOME,
	}),
	[HospitalAtHomeSettings.TAKE_PICTURE]: getConfigurationObj({
		title: 'hospitalAtHomeTakePicture',
		value: false,
		description: 'takeAPictureDescription',
		category: HospitalAtHomeCategories.HOSPITAL_AT_HOME,
	}),
	[HospitalAtHomeSettings.MEDICATION]: getConfigurationObj({
		title: 'hospitalAtHomeMedication',
		value: false,
		description: 'medicationDescription',
		category: HospitalAtHomeCategories.HOSPITAL_AT_HOME,
	}),
	[HospitalAtHomeSettings.CHAT]: getConfigurationObj({
		title: 'hospitalAtHomeChat',
		value: false,
		description: 'chatHAHDescription',
		category: HospitalAtHomeCategories.HOSPITAL_AT_HOME,
	}),
});

export const configurableRoomModesSettings = () => ({
	[getConfigurationWithDiffTypeKey(RoomModesSettings.NICU, RoomTypes.BABY_ROOM.type)]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.BABY_ROOM.type,
		settingTypeId: RoomModesSettings.NICU,
		title: 'nicuMode',
		description: 'nicuDescription',
		category: 'roomModes',
	}),
	[getConfigurationWithDiffTypeKey(RoomModesSettings.NICU, RoomTypes.DEFAULT.type)]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		settingTypeId: RoomModesSettings.NICU,
		title: 'nicuMode',
		description: 'nicuDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.HOSPITAL_AT_HOME]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'hospitalAtHomeMode',
		description: 'hospitalAtHomeRMDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.AI_WIDGET]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'aiWidgetMode',
		description: 'aiWidgetDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.WIDGET]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'widgetMode',
		description: 'widgetDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.DIGITAL_CLINICIAN]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'digitalClinicMode',
		description: 'digitalClinicDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.TELEMEDICINE]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'telemedicineMode',
		description: 'telemedicineDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.HOSPITAL]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'hospitalMode',
		description: 'hospitalDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.VIRTUAL_EXAMINATION]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'virtualExaminationMode',
		description: 'virtualExaminationDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.SIMPLIFIED]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'simplifiedMode',
		description: 'simplifiedDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.E_VIDEON]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'eVideonMode',
		description: 'eVideonDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.HELLOCARE_ENGAGEMENT]: getConfigurationObj({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'hellocareEngagement',
		description: 'patientExperienceDescription',
		category: 'roomModes',
	}),
});

export const SelectTypes = {
	TEAMS_SETTINGS: 1,
	ROLE_SETTINGS: 2,
};

export const configurableRoundingMenu = (role = UserRoles.ADMIN) => ({
	[RoundingSettings.EditRoomNameRounding]: getConfigurationObj({
		title: 'editRoomNameRounding',
		value: false,
		description: 'editRoomNameRoundingDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.LiveCaptions]: getConfigurationObj({
		title: 'liveCaptions',
		value: false,
		description: 'liveCaptionsDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.TalkToPatient]: getConfigurationObj({
		title: 'talkToPatient',
		value: true,
		description: 'talkToPatientDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.CancelInvite]: getConfigurationObj({
		title: 'cancelInvite',
		value: false,
		description: 'cancelInviteDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.RateCall]: getConfigurationObj({
		title: 'rateCallQuality',
		value: true,
		description: 'rateCallQualityDesc',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.ViewPatient]: getConfigurationObj({
		title: 'viewPatient',
		value: true,
		description: 'viewPatientDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.Whiteboard]: getConfigurationObj({
		title: 'whiteboard',
		value: true,
		description: 'roundingWhiteboardDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.DisplayControl]: getConfigurationObj({
		title: 'whiteboardControls',
		value: true,
		description: 'whiteboardControlsDesc',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.TVControls]: getConfigurationObj({
		title: 'tvControls',
		value: false,
		description: 'tvControlsDesc',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.RoomSign]: getConfigurationObj({
		title: 'roomSign',
		value: false,
		description: 'roundingRoomSignDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.EnableCameraToggleBtn]: getConfigurationObj({
		title: 'enableCameraToggleButton',
		value: false,
		description: 'enableCameraToggleButtonDesc',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.VideoPreview]: getConfigurationObj({
		title: 'videoPreview',
		value: false,
		description: 'videoPreviewDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.DialIn]: getConfigurationObj({
		title: 'dialIn',
		value: false,
		description: 'dialInDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.DialOut]: getConfigurationObj({
		title: 'dialOut',
		value: false,
		description: 'dialOutDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.InviteViaEmail]: getConfigurationObj({
		title: 'inviteViaEmail',
		value: true,
		description: 'inviteViaEmailDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.TranslationServices]: getConfigurationObj({
		title: 'interpretationServices',
		value: false,
		description: 'interpretationServiceDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
		conditionalDependencies: {
			dependedFromConfigCategories: [SettingsCategories.INTEGRATION_TYPES_SETTINGS],
			someConfigs: [
				IntegrationTypesSettings.GLOBO,
				IntegrationTypesSettings.AMN,
				IntegrationTypesSettings.LANGUAGE_LINE,
				IntegrationTypesSettings.VOYCE,
				IntegrationTypesSettings.CCAD,
			],
		},
	}),
	[RoundingSettings.InviteViaSms]: getConfigurationObj({
		title: 'inviteSms',
		type: SelectTypes.ROLE_SETTINGS,
		value: true,
		description: 'inviteViaSmsDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
		variant: { value: InviteViaSmsVariant.WITH_INVITATION_NOTE, label: 'smsWithInvitationNote' },
		...([UserRoles.NURSE, UserRoles.DOCTOR].includes(role) && {
			options: [
				{ value: InviteViaSmsVariant.WITH_INVITATION_NOTE, label: 'smsWithInvitationNote' },
				{ value: InviteViaSmsVariant.WITHOUT_INVITATION_NOTE, label: 'smsWithoutInvitationNote' },
			],
		}),
	}),
	[RoundingSettings.MeetingUrl]: getConfigurationObj({
		title: 'meetingUrl',
		value: true,
		description: 'meetingUrlDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.ThirdPartyMembersMic]: getConfigurationObj({
		title: 'thirdPartyMic',
		value: true,
		description: 'thirdPartyMembersMicDes',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.ExamKit]: getConfigurationObj({
		title: 'examKit',
		value: false,
		description: 'examKitDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.Wearables]: getConfigurationObj({
		title: 'wearables',
		value: false,
		description: 'wearablesDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.MedicalDevices]: getConfigurationObj({
		title: 'medicalDevices',
		value: false,
		description: 'medicalDevicesDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
		disableChildren: false,
		isExpandable: true,
		childrenConfigs: {
			[RoundingSettings.Ear]: getConfigurationObj({
				title: 'ear',
				value: false,
				isLocked: false,
				description: 'earDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.Lungs]: getConfigurationObj({
				title: 'lungs',
				value: false,
				isLocked: false,
				description: 'lungsDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.Heart]: getConfigurationObj({
				title: 'heart',
				value: false,
				isLocked: false,
				description: 'heartDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.Throat]: getConfigurationObj({
				title: 'throat',
				value: false,
				isLocked: false,
				description: 'throatDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.HeartRate]: getConfigurationObj({
				title: 'heartRate',
				value: false,
				isLocked: false,
				description: 'heartRateDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.OxygenSaturation]: getConfigurationObj({
				title: 'oxygenSaturation',
				value: false,
				isLocked: false,
				description: 'oxygenSaturationDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.Temperature]: getConfigurationObj({
				title: 'temperature',
				value: false,
				isLocked: false,
				description: 'temperatureDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.Skin]: getConfigurationObj({
				title: 'skin',
				value: false,
				isLocked: false,
				description: 'skinDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.BloodPressure]: getConfigurationObj({
				title: 'bloodPressure',
				value: false,
				isLocked: false,
				description: 'bloodPressureDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.ECG]: getConfigurationObj({
				title: 'eCG',
				value: false,
				isLocked: false,
				description: 'eCGDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.BloodGlucose]: getConfigurationObj({
				title: 'bloodGlucose',
				value: false,
				isLocked: false,
				description: 'bloodGlucoseDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.Weight]: getConfigurationObj({
				title: 'weight',
				value: false,
				isLocked: false,
				description: 'weightDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.Spirometer]: getConfigurationObj({
				title: 'spirometer',
				value: false,
				isLocked: false,
				description: 'spirometerDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
			[RoundingSettings.Abdomen]: getConfigurationObj({
				title: 'abdomen',
				value: false,
				isLocked: false,
				description: 'abdomenDescription',
				category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
				conditionalDependencies: {
					dependedFromConfigCategories: [SettingsCategories.ROUNDING_SETTINGS],
					everyConfig: [RoundingSettings.MedicalDevices],
				},
			}),
		},
	}),
	[RoundingSettings.AiMeasurements]: getConfigurationObj({
		title: 'aiMeasurements',
		value: false,
		description: 'AIMeasurementsDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.BiobeatPatch]: getConfigurationObj({
		title: 'biobeatPatch',
		value: false,
		description: 'biobeatPatchDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.LifeSignalsPatch]: getConfigurationObj({
		title: 'lifesignalsPatch',
		value: false,
		description: 'lifeSignalsPatchDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.RoundingHealthData]: getConfigurationObj({
		title: 'roundingHealthData',
		value: false,
		description: 'healthDataDescription',
		category: RoundingFeaturesCategories.PATIENT_HISTORY,
	}),
	[RoundingSettings.RoundingVisits]: getConfigurationObj({
		title: 'roundingVisits',
		value: false,
		description: 'visitsDescription',
		category: RoundingFeaturesCategories.PATIENT_HISTORY,
	}),
	[RoundingSettings.RoundingAlertHistory]: getConfigurationObj({
		title: 'alertHistory',
		value: false,
		description: 'alertHistoryDescription',
		category: RoundingFeaturesCategories.PATIENT_HISTORY,
	}),
	...([UserRoles.NURSE, UserRoles.SUPER_ADMIN, UserRoles.ADMIN].includes(role) && {
		[RoundingSettings.CareEventsForNurses]: getConfigurationObj({
			title: 'roundingCareEventsForNurses',
			value: false,
			description: 'careEventsForNursesDescription',
			category: RoundingFeaturesCategories.PATIENT_HISTORY,
			otherConfigToDisable: [RoundingSettings.CareEventsForSafetyCompanions],
		}),
		[RoundingSettings.CareEventsForSafetyCompanions]: getConfigurationObj({
			title: 'careEventsForSafetyCompanions',
			value: false,
			description: 'careEventsForNursesDesc',
			category: RoundingFeaturesCategories.PATIENT_HISTORY,
			otherConfigToDisable: [RoundingSettings.CareEventsForNurses],
		}),
		[RoundingSettings.RoundingCareEvents]: getConfigurationObj({
			title: 'roundingCareEvents',
			value: false,
			description: 'roundingCareEventsDesc',
			category: RoundingFeaturesCategories.PATIENT_HISTORY,
		}),
	}),
	[RoundingSettings.CaregiversName]: getConfigurationObj({
		title: 'displayCaregiverName',
		type: SelectTypes.ROLE_SETTINGS,
		value: false,
		isLocked: false,
		description: 'displayCaregiversNameDescription',
		category: RoundingFeaturesCategories.GENERAL,
		variant: { value: CallerNameVariant.FULL_NAME, label: 'displayFullName' },
		...([UserRoles.NURSE, UserRoles.DOCTOR].includes(role) && {
			options: [
				{ value: CallerNameVariant.FULL_NAME, label: 'displayFullName' },
				{ value: CallerNameVariant.CREDENTIALS, label: 'displayCredentialsName' },
			],
		}),
	}),
	...(role !== UserRoles.NURSE && {
		[RoundingSettings.PatientDiagnoses]: getConfigurationObj({
			title: 'medicalInfoPatientDiagnosis',
			value: false,
			description: 'patientDiagnosesDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientProcedures]: getConfigurationObj({
			title: 'medicalInfoProcedures',
			value: false,
			description: 'proceduresDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientNotes]: getConfigurationObj({
			title: 'medicalInfoNotes',
			value: false,
			description: 'roundingNotesDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientPrescriptions]: getConfigurationObj({
			title: 'medicalInfoPrescriptions',
			value: false,
			description: 'prescriptionsDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientPhysicalTherapy]: getConfigurationObj({
			title: 'medicalInfoPhysicalTherapy',
			value: false,
			description: 'physicalTherapyDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.SessionWrapUp]: getConfigurationObj({
			title: 'wrapUpPage',
			value: false,
			description: 'sessionWrapUpDescription',
			category: RoundingFeaturesCategories.GENERAL,
		}),
	}),
});

export const getConfigurationWithDiffTypeKey = (config, type) => +`999${config}${type}`;

export const configurableAISettings = () => ({
	[AiSetting.ALERTS_EVIDENCE_ADMIN]: getConfigurationObj({
		title: 'admin',
		value: false,
		isLocked: false,
		description: 'evidenceAdminDescription',
		category: AiSettingFeaturesCategory.EVIDENCE_CONFIGURATIONS,
		callWorkflowTypeId: INDEPENDENT_FEATURE_TYPE,
		isNotUserPreference: true,
		settingTypeId: AiSetting.ALERTS_EVIDENCE_ADMIN,
	}),
	[AiSetting.ALERTS_EVIDENCE_NURSE]: getConfigurationObj({
		title: 'evidenceNurse',
		value: false,
		isLocked: false,
		description: 'evidenceNurseDescription',
		category: AiSettingFeaturesCategory.EVIDENCE_CONFIGURATIONS,
		callWorkflowTypeId: INDEPENDENT_FEATURE_TYPE,
		isNotUserPreference: true,
		settingTypeId: AiSetting.ALERTS_EVIDENCE_NURSE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.TRAINING_MODE, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'trainingMode',
		value: false,
		isLocked: false,
		description: 'trainingModeDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.TRAINING_MODE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.DATA_COLLECTION, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'dataCollectionAlerts',
		value: false,
		isLocked: false,
		description: 'dataCollectionAlertsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.DATA_COLLECTION,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.DATA_COLLECTION, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'dataCollectionAlerts',
		value: false,
		isLocked: false,
		description: 'dataCollectionAlertsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.DATA_COLLECTION,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.DATA_COLLECTION, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'dataCollectionAlerts',
		value: false,
		isLocked: false,
		description: 'dataCollectionAlertsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.DATA_COLLECTION,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_DETECTION_DRAWING, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'aIDetectionDrawings',
		value: false,
		isLocked: false,
		description: 'aiDetectionDrawingsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.AI_DETECTION_DRAWING,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_DETECTION_DRAWING, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'aIDetectionDrawings',
		value: false,
		isLocked: false,
		description: 'aiDetectionDrawingsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.AI_DETECTION_DRAWING,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.SOUND_ON_AI_ALERT, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'soundOnAiAlert',
		value: false,
		isLocked: false,
		description: 'soundOnAiAlertDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.SOUND_ON_AI_ALERT,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.SOUND_ON_AI_ALERT, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'soundOnAiAlert',
		value: false,
		isLocked: false,
		description: 'soundOnAiAlertDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.SOUND_ON_AI_ALERT,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.EWS_AI_VITAL_SIGNS, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'ewsAiVitalSigns',
		value: false,
		isLocked: false,
		description: 'aIVitalSignsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.EWS_AI_VITAL_SIGNS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.EWS_AI_VITAL_SIGNS,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.EWS_AI_VITAL_SIGNS, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'ewsAiVitalSigns',
		value: false,
		isLocked: false,
		description: 'aIVitalSignsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.EWS_AI_VITAL_SIGNS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.EWS_AI_VITAL_SIGNS,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.EWS_AI_VITAL_SIGNS, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'ewsAiVitalSigns',
		value: false,
		isLocked: false,
		description: 'aIVitalSignsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.EWS_AI_VITAL_SIGNS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.EWS_AI_VITAL_SIGNS,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_BED, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'getOutOfBed',
		value: false,
		isLocked: false,
		description: 'gettingOutOfBedDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.GET_OUT_OF_BED,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_BED, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'getOutOfBed',
		value: false,
		isLocked: false,
		description: 'gettingOutOfBedDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.GET_OUT_OF_BED,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_BED, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'getOutOfBed',
		value: false,
		isLocked: false,
		description: 'gettingOutOfBedDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.GET_OUT_OF_BED,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.RAILS, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'rails',
		value: false,
		isLocked: false,
		description: 'railsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.RAILS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.RAILS,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.RAILS, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'rails',
		value: false,
		isLocked: false,
		description: 'railsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.RAILS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.RAILS,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.RAILS, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'rails',
		value: false,
		isLocked: false,
		description: 'railsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.RAILS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.RAILS,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.IV_BAG_FLUID_LEVEL, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'ivBagFluidLevel',
		value: false,
		isLocked: false,
		description: 'ivBagDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.IV_BAG_FLUID_LEVEL,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.IV_BAG_FLUID_LEVEL, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'ivBagFluidLevel',
		value: false,
		isLocked: false,
		description: 'ivBagDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.IV_BAG_FLUID_LEVEL,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.IV_BAG_FLUID_LEVEL, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'ivBagFluidLevel',
		value: false,
		isLocked: false,
		description: 'ivBagDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.IV_BAG_FLUID_LEVEL,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.FALL_DETECTION, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'fallDetection',
		value: false,
		isLocked: false,
		description: 'fallDetectionDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.FALL_DETECTED,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.FALL_DETECTION,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.FALL_DETECTION, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'fallDetection',
		value: false,
		isLocked: false,
		description: 'fallDetectionDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.FALL_DETECTED,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.FALL_DETECTION,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.FALL_DETECTION, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'fallDetection',
		value: false,
		isLocked: false,
		description: 'fallDetectionDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.FALL_DETECTED,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.FALL_DETECTION,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.HAND_WASHING, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'handWashing',
		value: false,
		isLocked: false,
		description: 'handWashingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.HAND_WASHING,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.HAND_WASHING,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.HAND_WASHING, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'handWashing',
		value: false,
		isLocked: false,
		description: 'handWashingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.HAND_WASHING,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.HAND_WASHING,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.HAND_WASHING, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'handWashing',
		value: false,
		isLocked: false,
		description: 'handWashingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.HAND_WASHING,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.HAND_WASHING,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_PRIVACY_MODE, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'aiPrivacyMode',
		value: false,
		isLocked: false,
		description: 'aIPrivacyModeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.AI_PRIVACY_MODE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_PRIVACY_MODE, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'aiPrivacyMode',
		value: false,
		isLocked: false,
		description: 'aIPrivacyModeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.AI_PRIVACY_MODE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_PRIVACY_MODE, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'aiPrivacyMode',
		value: false,
		isLocked: false,
		description: 'aIPrivacyModeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.AI_PRIVACY_MODE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.DISABLE_AI, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'disableAiTemporarily',
		value: false,
		isLocked: false,
		description: 'disableAiDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.DISABLE_AI,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.DISABLE_AI, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'disableAiTemporarily',
		value: false,
		isLocked: false,
		description: 'disableAiDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.DISABLE_AI,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PRESSURE_INJURY, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'pressureInjury',
		value: false,
		isLocked: false,
		description: 'inactiveTimeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PRESSURE_INJURY,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.PRESSURE_INJURY,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PRESSURE_INJURY, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'pressureInjury',
		value: false,
		isLocked: false,
		description: 'inactiveTimeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PRESSURE_INJURY,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.PRESSURE_INJURY,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PRESSURE_INJURY, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'pressureInjury',
		value: false,
		isLocked: false,
		description: 'inactiveTimeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PRESSURE_INJURY,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.PRESSURE_INJURY,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PATIENT_MOBILITY, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'patientMobility',
		value: false,
		isLocked: false,
		description: 'patientWalkingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.PATIENT_MOBILITY,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_MOBILITY,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PATIENT_MOBILITY, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'patientMobility',
		value: false,
		isLocked: false,
		description: 'patientWalkingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.PATIENT_MOBILITY,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_MOBILITY,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PATIENT_MOBILITY, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'patientMobility',
		value: false,
		isLocked: false,
		description: 'patientWalkingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.PATIENT_MOBILITY,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_MOBILITY,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_VOICE_ANALYSIS, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'aiVoiceAnalysis',
		value: false,
		isLocked: false,
		description: 'aiVoiceDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.AI_VOICE_ANALYSIS,
		aiConfigurationTypeId: PatientAiSetting.AI_VOICE_ANALYSIS,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_VOICE_ANALYSIS, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'aiVoiceAnalysis',
		value: false,
		isLocked: false,
		description: 'aiVoiceDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.AI_VOICE_ANALYSIS,
		aiConfigurationTypeId: PatientAiSetting.AI_VOICE_ANALYSIS,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_VOICE_ANALYSIS, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'aiVoiceAnalysis',
		value: false,
		isLocked: false,
		description: 'aiVoiceDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.AI_VOICE_ANALYSIS,
		aiConfigurationTypeId: PatientAiSetting.AI_VOICE_ANALYSIS,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.SLEEP_QUALITY_TRACKING, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'sleepQuality',
		value: false,
		isLocked: false,
		description: 'sleepQualityDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.SLEEP_QUALITY_TRACKING,
		aiConfigurationTypeId: PatientAiSetting.SLEEP_QUALITY,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.SLEEP_QUALITY_TRACKING, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'sleepQuality',
		value: false,
		isLocked: false,
		description: 'sleepQualityDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.SLEEP_QUALITY_TRACKING,
		aiConfigurationTypeId: PatientAiSetting.SLEEP_QUALITY,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.SLEEP_QUALITY_TRACKING, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'sleepQuality',
		value: false,
		isLocked: false,
		description: 'sleepQualityDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.SLEEP_QUALITY_TRACKING,
		aiConfigurationTypeId: PatientAiSetting.SLEEP_QUALITY,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_CHAIR, CallWorkflowType.MONITORING)]: getConfigurationObj({
		title: 'getOutOfChair',
		value: false,
		isLocked: false,
		description: 'gettingOutOfChairDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_CHAIR,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.GET_OUT_OF_CHAIR,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_CHAIR, CallWorkflowType.ROUNDING)]: getConfigurationObj({
		title: 'getOutOfChair',
		value: false,
		isLocked: false,
		description: 'gettingOutOfChairDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_CHAIR,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.GET_OUT_OF_CHAIR,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_CHAIR, CallWorkflowType.BACKGROUND)]: getConfigurationObj({
		title: 'getOutOfChair',
		value: false,
		isLocked: false,
		description: 'gettingOutOfChairDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_CHAIR,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.GET_OUT_OF_CHAIR,
		isExpandable: true,
	}),
});

export const configurableUserExperienceSettings = (role, isUserExperienceHSEditable) => ({
	...(((isUserExperienceHSEditable && UserRoles.ADMIN === role) || UserRoles.SUPER_ADMIN === role) && {
		[GeneralAndMenuSettings.IS_OLD_USER_EXPERIENCE]: getConfigurationObj({
			title: 'isOldUserExperience',
			value: false,
			isLocked: false,
			description: 'isOldUserExperienceDescription',
			category: UserExperienceFeaturesCategories.USER_EXPERIENCE,
		}),
	}),
	...([UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.IS_EXPERIENCE_HS_CHANGEABLE]: getConfigurationObj({
			title: 'isEnabledForHs',
			value: false,
			isLocked: false,
			description: 'isEnabledForHsDescription',
			category: UserExperienceFeaturesCategories.USER_EXPERIENCE,
		}),
	}),
});

export const configurableGeneralAndMenuSettings = (role = UserRoles.ADMIN) => ({
	...([UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.CONVERSATION_PHOTOS]: getConfigurationObj({
			title: 'conversationPhotos',
			value: true,
			description: 'conversationPhotosDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.HOSPITAL_AT_HOME]: getConfigurationObj({
			title: 'hospitalAtHome',
			value: true,
			description: 'hospitalAtHomeDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONVERSATION_FILES]: getConfigurationObj({
			title: 'conversationFiles',
			value: true,
			description: 'conversationFilesDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.NOTIFICATIONS]: getConfigurationObj({
			title: 'notifications',
			value: true,
			description: 'notificationsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.LOGISTICS]: getConfigurationObj({
			title: 'logistics',
			value: true,
			description: 'logisticsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.WAITING_ROOM]: getConfigurationObj({
			title: 'waitingRoom',
			value: true,
			description: 'waitingRoomDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.ROUNDING]: getConfigurationObj({
			title: 'rounding',
			value: true,
			description: 'roundingDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.DOCTOR, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.HOME]: getConfigurationObj({
			title: 'homePage',
			value: true,
			description: 'homePageDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.SESSIONS_QUEUE]: getConfigurationObj({
			title: 'sessionsQueue',
			value: true,
			description: 'sessionsQueueDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.APPOINTMENTS]: getConfigurationObj({
			title: 'appointments',
			value: true,
			description: 'apppointmentsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.REGISTRATION_FORM]: getConfigurationObj({
			title: 'doctorRegistrationForm',
			value: false,
			description: 'registrationFormPhotosDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.SOUND_NOTIFICATION]: getConfigurationObj({
			title: 'soundNotification',
			value: false,
			description: 'soundNotificationDesc',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
	}),
	...([UserRoles.NURSE, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.CONVERSATION_HISTORY]: getConfigurationObj({
			title: 'conversation',
			value: true,
			description: 'conversationDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.AI_NOTIFICATIONS]: getConfigurationObj({
			title: 'aiNotifications',
			value: true,
			description: 'aiNotificationsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.CARE_EVENTS]: getConfigurationObj({
			title: 'careEventsDashboard',
			value: true,
			description: 'careEventsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CHAT]: getConfigurationObj({
			title: 'chat',
			value: true,
			description: 'chatDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING]: getConfigurationObj({
			title: 'monitoring',
			value: true,
			description: 'monitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.VISITORS]: getConfigurationObj({
			title: 'visitors',
			value: true,
			description: 'visitorsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.POOLING_FLOW]: getConfigurationObj({
			title: 'poolingFlow',
			value: true,
			description: 'poolingFlowDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING_SESSIONS]: getConfigurationObj({
			title: 'savedSessions',
			value: true,
			description: 'savedSessionsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.AMBIENT_MONITORING]: getConfigurationObj({
			title: 'careNotifications',
			value: true,
			description: 'ambientMonitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.VIRTUAL_SITTER, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.MONITORING]: getConfigurationObj({
			title: 'monitoring',
			value: true,
			description: 'monitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING_SESSIONS]: getConfigurationObj({
			title: 'savedSessions',
			value: true,
			description: 'savedSessionsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.AMBIENT_MONITORING]: getConfigurationObj({
			title: 'careNotifications',
			value: true,
			description: 'ambientMonitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.VIRTUAL_SITTER, UserRoles.NURSE, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.TEAMS]: getConfigurationObj({
			title: 'teams',
			value: true,
			description: 'teamsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING_PRECAUTIONS_LEGEND]: getConfigurationObj({
			title: 'monitoringPrecautionsLegend',
			value: true,
			description: 'monitoringPrecautionsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
	}),
	...([UserRoles.DIGITAL_CLINICIAN, UserRoles.DOCTOR, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.PATIENTS]: getConfigurationObj({
			title: 'patients',
			value: true,
			description: 'patientsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
			disableChildren: false,
			isExpandable: true,
			childrenConfigs: {
				[GeneralAndMenuSettings.OVERVIEW]: getConfigurationObj({
					title: 'overview',
					value: false,
					isLocked: false,
					description: 'overviewTab',
					category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
						everyConfig: [GeneralAndMenuSettings.PATIENTS],
					},
				}),
				...(role !== UserRoles.SUPER_ADMIN && {
					[GeneralAndMenuSettings.CONVERSATION_HISTORY]: getConfigurationObj({
						title: 'conversation',
						value: false,
						isLocked: false,
						description: 'conversationDescription',
						category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
						conditionalDependencies: {
							dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
							everyConfig: [GeneralAndMenuSettings.PATIENTS],
						},
					}),
				}),
				[GeneralAndMenuSettings.PATIENT_HEALTH]: getConfigurationObj({
					title: 'health',
					value: false,
					isLocked: false,
					description: 'healthTab',
					category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
						everyConfig: [GeneralAndMenuSettings.PATIENTS],
					},
				}),
				[GeneralAndMenuSettings.PHYSICAL_THERAPY]: getConfigurationObj({
					title: 'physicalTherapy',
					value: false,
					isLocked: false,
					description: 'physicalTherapyTab',
					category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
						everyConfig: [GeneralAndMenuSettings.PATIENTS],
					},
				}),
				[GeneralAndMenuSettings.PATIENT_VISITS]: getConfigurationObj({
					title: 'visits',
					value: false,
					isLocked: false,
					description: 'visitTab',
					category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
						everyConfig: [GeneralAndMenuSettings.PATIENTS],
					},
				}),
				[GeneralAndMenuSettings.ALERT_HISTORY]: getConfigurationObj({
					title: 'alertHistory',
					value: false,
					isLocked: false,
					description: 'alertHistoryTab',
					category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
						everyConfig: [GeneralAndMenuSettings.PATIENTS],
					},
				}),
			},
		}),
	}),
	...([UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.PATIENT_MONITORING]: getConfigurationObj({
			title: 'patientMonitoring',
			value: true,
			description: 'patientMonitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
			disableChildren: false,
			isExpandable: true,
			childrenConfigs: {
				[GeneralAndMenuSettings.RPM]: getConfigurationObj({
					title: 'rpm',
					value: true,
					isLocked: false,
					description: 'rpmDescription',
					category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
						everyConfig: [GeneralAndMenuSettings.PATIENT_MONITORING],
					},
				}),
				[GeneralAndMenuSettings.CPM]: getConfigurationObj({
					title: 'cpm',
					value: true,
					isLocked: false,
					description: 'cpmDescription',
					category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
						everyConfig: [GeneralAndMenuSettings.PATIENT_MONITORING],
					},
				}),
			},
		}),
	}),
	...([UserRoles.SUPER_USER, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.DASHBOARD]: getConfigurationObj({
			title: 'dashboard',
			value: true,
			description: 'dashboardDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.FLEET_MANAGEMENT]: getConfigurationObj({
			title: 'fleetDashboard',
			value: true,
			description: 'fleetDashboardDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.NURSE_STATION]: getConfigurationObj({
			title: 'nurseStationTablet',
			value: true,
			description: 'nurseStationDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.QUEUE_MANAGEMENT]: getConfigurationObj({
			title: 'queueManagement',
			value: true,
			description: 'queueManagementDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.ORGANIZATION]: getConfigurationObj({
			title: 'organization',
			value: true,
			description: 'organizationDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CALL_LOGS]: getConfigurationObj({
			title: 'callLogs',
			value: true,
			description: 'callLogsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.USER_MANAGEMENT]: getConfigurationObj({
			title: 'userManagement',
			value: true,
			description: 'userManagementDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
			...(UserRoles.SUPER_ADMIN === role && {
				isExpandable: true,
				childrenConfigs: {
					[GeneralAndMenuSettings.LOCAL_USERS]: getConfigurationObj({
						title: 'localUsers',
						value: false,
						description: 'localUsersDescription',
						category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
						conditionalDependencies: {
							dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
							everyConfig: [GeneralAndMenuSettings.USER_MANAGEMENT],
						},
					}),
					[GeneralAndMenuSettings.EXTERNAL_USERS]: getConfigurationObj({
						title: 'externalUsers',
						value: false,
						description: 'externalUsersDescription',
						category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
						conditionalDependencies: {
							dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
							everyConfig: [GeneralAndMenuSettings.USER_MANAGEMENT],
						},
					}),
				},
			}),
		}),
		[GeneralAndMenuSettings.CONFIGURATION]: getConfigurationObj({
			title: 'configurations',
			value: true,
			description: 'configurationsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
			disableChildren: false,
			isExpandable: true,
			childrenConfigs: {
				[GeneralAndMenuSettings.RPM_PROGRAMS]: getConfigurationObj({
					title: 'rpmPrograms',
					value: false,
					isLocked: false,
					description: 'rpmProgramDescription',
					category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.GENERAL_AND_MENU_SETTINGS],
						everyConfig: [GeneralAndMenuSettings.CONFIGURATION],
					},
				}),
			},
		}),
	}),
	...(UserRoles.SUPER_ADMIN === role && {
		[GeneralAndMenuSettings.SUPPORT_CHAT]: getConfigurationObj({
			title: 'supportChat',
			value: true,
			description: 'supportChatDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.AUDIT_LOGS]: getConfigurationObj({
			title: 'auditLogs',
			value: true,
			description: 'auditLogsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
});

export const configurableMonitoringMenu = (role, sectorLevel = null, isNewExperience = true) => ({
	[MonitoringSettings.EditRoomNameMonitoring]: getConfigurationObj({
		title: 'editRoomNameMonitoring',
		value: false,
		description: 'editRoomNameMonitoringDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.LifeSignals]: getConfigurationObj({
		title: 'lifeSignals',
		value: false,
		isLocked: false,
		description: 'lifeSignalDescription',
		category: MonitoringFeatureFlagCategories.AUTOMATIC_ALERTS,
	}),
	[MonitoringSettings.StatAlarm]: getConfigurationObj({
		title: 'statAlarm',
		value: false,
		isLocked: false,
		description: 'statAlarmDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		hasButton: true,
		...(role === UserRoles.SUPER_ADMIN && {
			variant: { label: 'alarmCount', translateValue: 1, value: StatAlarmType.ALARM_1, url: `${statAlarmCdnUrl}1.mp3` },
			options: [
				{ label: 'alarmCount', translateValue: 1, value: StatAlarmType.ALARM_1, url: `${statAlarmCdnUrl}1.mp3` },
				{ label: 'alarmCount', translateValue: 2, value: StatAlarmType.ALARM_2, url: `${statAlarmCdnUrl}2.mp3` },
			],
		}),
	}),
	[MonitoringSettings.SmartWatchVitalSigns]: getConfigurationObj({
		title: 'smartWatchVitalSigns',
		value: false,
		isLocked: false,
		description: 'smartWatchVitalSignsDescription',
		category: MonitoringFeatureFlagCategories.AUTOMATIC_ALERTS,
	}),
	[MonitoringSettings.BiobeatVitalSigns]: getConfigurationObj({
		title: 'biobeatVitalSigns',
		value: false,
		isLocked: false,
		description: 'biobeatVitalSignsDescription',
		category: MonitoringFeatureFlagCategories.AUTOMATIC_ALERTS,
	}),
	[MonitoringSettings.PTZCam]: getConfigurationObj({
		title: 'ptzCam',
		value: true,
		isLocked: false,
		description: 'pTZControlsDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.ContactNurses]: getConfigurationObj({
		title: 'contactNurse',
		value: false,
		isLocked: true,
		description: 'contactNursesDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.CallPatient]: getConfigurationObj({
		title: 'callPatient',
		value: false,
		isLocked: false,
		description: 'callPatientDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.PatientCommunication]: getConfigurationObj({
		title: 'patientCommunication',
		value: false,
		isLocked: false,
		description: 'patientCommunicationDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.WalkieTalkie]: getConfigurationObj({
		title: 'walkieTalkie',
		value: false,
		isLocked: true,
		description: 'walkieTalkieDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.RTLS]: getConfigurationObj({
		title: 'rtls',
		value: false,
		isLocked: false,
		description: 'rtlsDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.MicrosoftTeams]: getConfigurationObj({
		title: 'microsoftTeams',
		value: false,
		isLocked: true,
		description: 'microsoftTeamsDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.Call911]: getConfigurationObj({
		title: 'call911',
		value: false,
		isLocked: true,
		description: 'call911Description',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.NightVision]: getConfigurationObj({
		title: 'nightVision',
		value: true,
		isLocked: false,
		description: 'nightVisionDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
		orderNr: 1,
	}),
	[MonitoringSettings.EHR]: getConfigurationObj({
		title: 'ehr',
		value: false,
		isLocked: true,
		description: 'EHRDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.Whiteboard]: getConfigurationObj({
		title: 'whiteboard',
		value: false,
		isLocked: true,
		description: 'monitoringWhiteboardDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.DisplayControl]: getConfigurationObj({
		title: 'whiteboardControls',
		value: false,
		isLocked: true,
		description: 'whiteboardControlsDesc',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.CareEventsForNurses]: getConfigurationObj({
		title: 'careEventsForNurses',
		value: false,
		isLocked: true,
		description: 'careEventsForNursesDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.CareEventsForSafetyCompanions]: getConfigurationObj({
		title: 'careEventsForSafetyCompanions',
		value: false,
		isLocked: true,
		description: 'interventionsAndAlertsDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.Notes]: getConfigurationObj({
		title: 'notes',
		value: false,
		isLocked: false,
		description: 'notesDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
		variant: { value: NoteVariants.SHOW_ON_EXPAND, label: 'showNotesOnExpand' },
		options: [
			{ value: NoteVariants.SHOW_ON_EXPAND, label: 'showNotesOnExpand' },
			{ value: NoteVariants.SHOW_UNDER_TILE, label: 'showNotesUnderTile' },
		],
		orderNr: 2,
	}),
	[MonitoringSettings.Precautions]: getConfigurationObj({
		title: 'precautions',
		value: false,
		isLocked: false,
		description: 'precautionsDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
		orderNr: 3,
		variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
		options: [
			{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
			{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
		],
		variantTitle: 'dataInput',
	}),
	[MonitoringSettings.SuspiciousBehavior]: getConfigurationObj({
		title: 'suspiciousBehavior',
		value: false,
		isLocked: false,
		description: 'suspiciousBehaviorDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
		isNotUserPreference: true,
		orderNr: 4,
	}),
	[MonitoringSettings.Recording]: getConfigurationObj({
		title: 'recordTheSession',
		value: false,
		isLocked: true,
		description: 'recordingDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.Privacy]: getConfigurationObj({
		title: 'enablePrivacyMode',
		value: false,
		isLocked: false,
		description: 'privacyDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.MonitoringHealthData]: getConfigurationObj({
		title: 'healthData',
		value: false,
		isLocked: false,
		description: 'monitoringHealthDataDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.PatientInfo]: getConfigurationObj({
		title: 'patientInfoBox',
		value: false,
		isLocked: false,
		description: 'patientInfoDescription',
		category: MonitoringFeatureFlagCategories.PATIENT_INFO,
		...([SectorTypes.COMPANY, SectorTypes.HEALTH_SYSTEM].includes(sectorLevel) && {
			isExpandable: true,
			disableChildren: true,
			dependentConfigsTitle: 'patientInfoConfigsTitle',
			secondDependentConfigsTitle: 'chooseEhrHellocare',
			childrenConfigs: {
				[MonitoringSettings.PatientName]: getConfigurationObj({
					title: 'patientName',
					value: false,
					isLocked: false,
					description: 'patientNameDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					orderNr: 1,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [
						{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
						{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					],
					checkboxVariant: {
						value: false,
						key: MonitoringSettings.TitleBarPatientName,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.PatientAge]: getConfigurationObj({
					title: 'patientAge',
					value: false,
					isLocked: false,
					description: 'patientAgeDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					orderNr: 2,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [
						{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
						{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarPatientAge,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.PatientGenderIdentity]: getConfigurationObj({
					title: 'patientGenderIdentity',
					value: false,
					isLocked: false,
					description: 'genderIdentityDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					orderNr: 3,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [
						{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
						{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarPatientGenderIdentity,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.NurseNameNumber]: getConfigurationObj({
					title: 'nurseNameNumber',
					value: false,
					isLocked: false,
					description: 'nurseNameNumberDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					orderNr: 4,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [
						{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
						{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarNurseNameNumber,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.NurseAssistantNameNumber]: getConfigurationObj({
					title: 'nurseAssistantNameNumber',
					value: false,
					isLocked: false,
					description: 'nurseAssistantNameNumberDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					orderNr: 5,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [
						{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
						{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarNurseAssistantNameNumber,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.TlNameNumber]: getConfigurationObj({
					title: 'tlNameNumber',
					value: false,
					isLocked: false,
					description: 'tlNameNumberDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					orderNr: 6,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [
						{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
						{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarTlNameNumber,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.IndicationForVideoMonitoring]: getConfigurationObj({
					title: 'indicationForVideoMonitoring',
					value: false,
					isLocked: false,
					description: 'indicationForVideoMonitoringDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					orderNr: 7,
					variantTitle: 'dataInput',
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					variant: { value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					options: [{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' }],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarIndicationForVideoMonitoring,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.PrimaryAdverseEvent]: getConfigurationObj({
					title: 'primaryAdverseEvent',
					value: false,
					isLocked: false,
					description: 'primaryAdverseEventDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					orderNr: 8,
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [
						{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
						{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarPrimaryAdverseEvent,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.PrimaryPatientCondition]: getConfigurationObj({
					title: 'primaryPatientCondition',
					value: false,
					isLocked: false,
					description: 'primaryPatientConditionDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					orderNr: 9,
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [
						{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
						{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarPrimaryPatientCondition,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.PrimaryPatientRiskFactor]: getConfigurationObj({
					title: 'primaryPatientRiskFactor',
					value: false,
					isLocked: false,
					description: 'primaryPatientRiskFactorDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					orderNr: 10,
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [
						{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
						{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarPrimaryPatientRiskFactor,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.PreferredLanguage]: getConfigurationObj({
					title: 'preferredLanguage',
					value: false,
					isLocked: false,
					description: 'preferredLanguageDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					orderNr: 11,
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [
						{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
						{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarPreferredLanguage,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.InterpreterNeeded]: getConfigurationObj({
					title: 'interpreterNeededText',
					value: false,
					isLocked: false,
					description: 'interpreterNeededDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					orderNr: 12,
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' },
					options: [{ value: PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD, label: 'ehr' }],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarInterpreterNeeded,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.TitleBarNotes]: getConfigurationObj({
					title: 'titleBarNotes',
					value: false,
					isLocked: false,
					description: 'titleBarNotesDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' }],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarTitleBarNotes,
						title: 'showOnTitleBar',
					},
				}),
				[MonitoringSettings.AdditionalPatientNotes]: getConfigurationObj({
					title: 'additionalPatientNotes',
					value: false,
					isLocked: false,
					description: 'additionalPatientNotesDescription',
					category: MonitoringFeatureFlagCategories.PATIENT_INFO,
					conditionalDependencies: {
						dependedFromConfigCategories: [SettingsCategories.MONITORING_SETTINGS],
						everyConfig: [MonitoringSettings.PatientInfo],
					},
					variantTitle: 'dataInput',
					variant: { value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' },
					options: [{ value: PatientInfoFieldsVariants.HELLOCARE_PATIENT_DATA_LOAD, label: 'hellocare' }],
					checkboxVariant: {
						value: true,
						key: MonitoringSettings.TitleBarAdditionalPatientNotes,
						title: 'showOnTitleBar',
					},
				}),
			},
		}),
	}),
	[MonitoringSettings.ForwardToNurses]: getConfigurationObj({
		title: 'forwardToNurses',
		value: false,
		isLocked: true,
		description: 'forwardToNursesDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.VerbalRedirection]: getConfigurationObj({
		title: 'voiceAnnouncements',
		value: false,
		isLocked: true,
		description: 'voiceAnnouncementsDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		isNotUserPreference: true,
		isExpandable: sectorLevel === SectorTypes.HEALTH_SYSTEM,
	}),
	[MonitoringSettings.PatientOutOfRoom]: getConfigurationObj({
		title: 'patientOutOfRoom',
		value: false,
		isLocked: true,
		description: 'patientOutOfRoomDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		isNotUserPreference: true,
		isExpandable: sectorLevel === SectorTypes.HEALTH_SYSTEM,
	}),
	...(isNewExperience && {
		[MonitoringSettings.DiscontinueMonitoring]: getConfigurationObj({
			title: 'discontinuingMonitoringReason',
			value: false,
			isLocked: true,
			description: 'discontinueMonitoringDescription',
			category: MonitoringFeatureFlagCategories.GENERAL,
			isNotUserPreference: true,
			isExpandable: sectorLevel === SectorTypes.HEALTH_SYSTEM,
		}),
	}),
	...(role !== UserRoles.VIRTUAL_SITTER && {
		[MonitoringSettings.RaiseAlert]: getConfigurationObj({
			title: 'raiseAnAlertManually',
			value: false,
			isLocked: true,
			description: 'raiseAlertDescription',
			category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		}),
		[MonitoringSettings.DisableVisits]: getConfigurationObj({
			title: 'disableVisits',
			value: false,
			isLocked: true,
			description: 'disableVisitsDescription',
			category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
		}),
	}),
	...([SectorTypes.COMPANY, SectorTypes.HEALTH_SYSTEM].includes(sectorLevel) && {
		[MonitoringSettings.EnableMultipleFeedAudio]: getConfigurationObj({
			title: 'enableMultipleFeedAudio',
			value: false,
			isLocked: false,
			description: 'enableMultipleFeedAudioDescription',
			category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
		}),
		...(isNewExperience && {
			[MonitoringSettings.MixedMonitoringView]: getConfigurationObj({
				title: 'mixedMonitoringView',
				value: false,
				isLocked: true,
				description: 'mixedMonitoringViewDescription',
				category: MonitoringFeatureFlagCategories.GENERAL,
				variant: { value: MixedViewVariants.VIDEO_MONITORING, label: 'defaultVideoMonitoring' },
				options: [
					{ value: MixedViewVariants.VIDEO_MONITORING, label: 'defaultVideoMonitoring' },
					{ value: MixedViewVariants.AMBIENT_MONITORING, label: 'defaultAmbientMonitoring' },
				],
				isNotUserPreference: true,
			}),
		}),
	}),
});

export const configurableIntegrationMenu = () => ({
	[IntegrationTypesSettings.AZURE_AD]: getConfigurationObj({
		title: 'azureAd',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.PING_FEDERATE]: getConfigurationObj({
		title: 'pingFederate',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.DUO_SSO]: getConfigurationObj({
		title: 'duo',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.OKTA]: getConfigurationObj({
		title: 'okta',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.HILL_ROM]: getConfigurationObj({
		title: 'hillRom',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.NURSE_CALLS_SYSTEMS,
	}),
	[IntegrationTypesSettings.EPIC]: getConfigurationObj({
		title: 'epic',
		value: false,
		description: 'ehrDescription',
		category: IntegrationFeaturesCategories.EHR,
	}),
	[IntegrationTypesSettings.CERNER]: getConfigurationObj({
		title: 'cerner',
		value: false,
		description: 'ehrDescription',
		category: IntegrationFeaturesCategories.EHR,
	}),
	[IntegrationTypesSettings.HL7_V2]: getConfigurationObj({
		title: 'hl7v2',
		value: false,
		description: 'hl7v2Description',
		category: IntegrationFeaturesCategories.EHR,
	}),
	[IntegrationTypesSettings.TELEHEALTH]: getConfigurationObj({
		title: 'telehealth',
		value: false,
		isLocked: false,
		description: 'telehealthDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.EVIDEON]: getConfigurationObj({
		title: 'evideon',
		value: false,
		isLocked: false,
		description: 'evideoDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.PCARE]: getConfigurationObj({
		title: 'pcare',
		value: false,
		isLocked: false,
		description: 'pcareDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.GET_WELL]: getConfigurationObj({
		title: 'getwell',
		value: false,
		isLocked: false,
		description: 'getwellDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.SONIFI]: getConfigurationObj({
		title: 'sonifi',
		value: false,
		isLocked: false,
		description: 'sonifiDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.MDM]: getConfigurationObj({
		title: 'mdm',
		value: false,
		isLocked: false,
		description: 'mdmDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.GLOBO]: getConfigurationObj({
		title: 'globo',
		value: false,
		description: 'translationTypeDescription',
		category: IntegrationFeaturesCategories.TRANSLATION_SERVICES,
		checkboxVariant: {
			value: false,
			key: IntegrationTypesSettings.SHOW_CCC,
			title: 'showCostCenterCode',
		},
	}),
	[IntegrationTypesSettings.AMN]: getConfigurationObj({
		title: 'amn',
		value: false,
		description: 'translationTypeDescription',
		category: IntegrationFeaturesCategories.TRANSLATION_SERVICES,
	}),
	[IntegrationTypesSettings.LANGUAGE_LINE]: getConfigurationObj({
		title: 'languageLine',
		value: false,
		description: 'languageLineTypeDescription',
		category: IntegrationFeaturesCategories.TRANSLATION_SERVICES,
	}),
	[IntegrationTypesSettings.VOYCE]: getConfigurationObj({
		title: 'voyce',
		value: false,
		description: 'voyceDescription',
		category: IntegrationFeaturesCategories.TRANSLATION_SERVICES,
	}),
	[IntegrationTypesSettings.ALEXA]: getConfigurationObj({
		title: 'alexaEnabled',
		value: false,
		description: 'enableAlexaConfiguration',
		category: IntegrationFeaturesCategories.ALEXA,
	}),
	[IntegrationTypesSettings.AZURE_BLOB_STORAGE]: getConfigurationObj({
		title: 'azureBlobStorage',
		value: false,
		description: 'enableAzureBlobStorage',
		category: IntegrationFeaturesCategories.CLOUD_STORAGE,
	}),
	[IntegrationTypesSettings.FUJI]: getConfigurationObj({
		title: 'fuji',
		value: false,
		description: 'fujiDescription',
		category: IntegrationFeaturesCategories.PACS,
	}),
	[IntegrationTypesSettings.GE]: getConfigurationObj({
		title: 'ge',
		value: false,
		description: 'geDescription',
		category: IntegrationFeaturesCategories.PACS,
	}),
	[IntegrationTypesSettings.CCAD]: getConfigurationObj({
		title: 'ccad',
		value: false,
		description: 'ccadDescription',
		category: IntegrationFeaturesCategories.TRANSLATION_SERVICES,
	}),
	[IntegrationTypesSettings.CBORD]: getConfigurationObj({
		title: 'cbord',
		value: false,
		description: 'cbordDescription',
		category: IntegrationFeaturesCategories.MEAL_ORDER_SERVICE,
	}),
});

export const ThemeTypes = {
	LIGHT: 'light',
	DARK: 'dark',
};

export const themeOptions = [
	{
		id: 0,
		value: ThemeTypes.LIGHT,
		icon: 'light_mode',
	},
	{
		id: 1,
		value: ThemeTypes.DARK,
		icon: 'dark_mode',
	},
];

export const talkToPatientTypes = intl => [
	{
		id: 0,
		content: intl.formatMessage({ id: 'audioOnly' }),
		value: TalkToPatient.AUDIO_ONLY,
	},
	{
		id: 1,
		content: intl.formatMessage({ id: 'audioVideo' }),
		value: TalkToPatient.AUDIO_VIDEO,
	},
];

const getConfigurableCallMenu = (description, value) => ({ value, description });

export const ConfigurableDigitalBackgroundSettings = {
	[CompanySettings.DIGITAL_BACKGROUND]: getConfigurableCallMenu('providerCallBackgrounds', 'false'),
	[CompanySettings.HS_DEFAULT_BACKGROUND]: getConfigurableCallMenu('defaultHSBackground', 'none'),
	[CompanySettings.HELLO_DEFAULT_BACKGROUND]: getConfigurableCallMenu('helloDefaultBackground', 'false'),
	[CompanySettings.HELLO_DEFAULT_WALLPAPER]: getConfigurableCallMenu('helloDefaultWallpaper', ''),
	[CompanySettings.AUDIO_CALL_BACKGROUND]: getConfigurableCallMenu('helloAudioCallBackground', 'false'),
};

export const PTZCameraSettings = {
	REVERSE_CAMERA: 19,
};

// Configurations View enums
export const ConfigHistoryTypes = {
	UserIdle: '1',
	Devices: '2',
	ADs: '3',
	TeamConfigurationProfiles: '4',
	NetworkBandwidth: '5',
	NetworkWiFiBand: '6',
	NetworkAccess: '7',
	NtpConfigurations: '8',
};

export const TeamTypeLabels = {
	4: 'Health System',
	5: 'Region',
	6: 'Hospital',
	7: 'Department',
	8: 'Floor',
};

export const TVs = {
	LG: { id: 1, value: 'LG (MPI)' },
	SAMSUNG: { id: 2, value: 'Samsung (Exlink)' },
	SONY: { id: 10, value: 'Sony' },
	CEC: { id: 9, value: 'CEC' },
	TELEHEALTH_CONTROLLED: { id: 3, value: 'Telehealth' },
	EVIDEON: { id: 4, value: 'eVideon' },
	PCARE: { id: 5, value: 'pCare' },
	GET_WELL: { id: 6, value: 'GetWell' },
	SONIFY: { id: 7, value: 'Sonifi' },
	MDM: { id: 8, value: 'MDM' },
};

export const EndCallSource = intl => ({
	TV_CHANNELS: { id: 0, value: intl.formatMessage({ id: 'tvChannels' }) },
	HDMI_1: { id: 1, value: 'HDMI 1' },
	HDMI_2: { id: 2, value: 'HDMI 2' },
	HDMI_3: { id: 3, value: 'HDMI 3' },
	PREVIOUS_STATE: { id: 4, value: intl.formatMessage({ id: 'previousState' }) },
});

export const TeamConfigurationIntegrationTypes = {
	TELEHEALTH: 1,
	PCARE: 2,
	EVIDEON: 3,
	GET_WELL: 4,
	SONIFI: 5,
	MDM: 6,
};

export const VisitorMenu = {
	DEVICES: 67,
	CHAT: 50,
};

export const defaultConfigurableMenu = showItem => ({
	[UserRoles.SUPER_ADMIN]: {
		[SuperAdminMenu.COMPANIES]: { isGeneralSetting: false, reDirectUrl: '/companies', value: showItem, orderNo: 1 },
		[SuperAdminMenu.USER_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/users', value: showItem, orderNo: 2 },
		[SuperAdminMenu.AUDIT_LOGS]: { isGeneralSetting: false, reDirectUrl: '/audit-logs', value: showItem, orderNo: 3 },
	},
	[UserRoles.ADMIN]: {
		[AdminMenu.ORGANIZATION]: { isGeneralSetting: false, reDirectUrl: '/health-system', value: true, orderNo: 1 },
		[AdminMenu.CALL_LOGS]: { isGeneralSetting: false, reDirectUrl: '/call-logs', value: true, orderNo: 2 },
		[AdminMenu.USER_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/users', value: true, orderNo: 3 },
		[AdminMenu.CONFIGURATION]: { isGeneralSetting: false, reDirectUrl: '/configurations', value: true, orderNo: 4 },
		[AdminMenu.AUDIT_LOGS]: { isGeneralSetting: false, reDirectUrl: '/audit-logs', value: showItem, orderNo: 5 },
		[AdminMenu.DASHBOARD]: { isGeneralSetting: false, reDirectUrl: '/dashboard', value: showItem, orderNo: 6 },
		[AdminMenu.FLEET_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/fleet-management', value: showItem, orderNo: 7 },
		[AdminMenu.NURSE_STATION]: { isGeneralSetting: false, reDirectUrl: '/nurse-station', value: showItem, orderNo: 8 },
	},
	[UserRoles.SUPER_USER]: {
		[SuperUserMenu.ORGANIZATION]: { isGeneralSetting: false, reDirectUrl: '/health-system', value: showItem, orderNo: 1 },
		[SuperUserMenu.CALL_LOGS]: { isGeneralSetting: false, reDirectUrl: '/call-logs', value: showItem, orderNo: 2 },
		[SuperUserMenu.USER_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/users', value: showItem, orderNo: 3 },
		[SuperUserMenu.CONFIGURATION]: { isGeneralSetting: false, reDirectUrl: '/configurations', value: showItem, orderNo: 4 },
		[SuperUserMenu.QUEUE_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/queue-management', value: showItem, orderNo: 5 },
		[SuperUserMenu.FLEET_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/fleet-management', value: showItem, orderNo: 6 },
		[SuperUserMenu.NURSE_STATION]: { isGeneralSetting: false, reDirectUrl: '/nurse-station', value: showItem, orderNo: 7 },
		[GeneralAndMenuSettings.CONVERSATION_FILES]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 15 },
		[GeneralAndMenuSettings.CONVERSATION_PHOTOS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 16 },
		[SuperUserMenu.DASHBOARD]: { isGeneralSetting: false, reDirectUrl: '/dashboard', value: showItem, orderNo: 17 },
	},
	[UserRoles.DOCTOR]: {
		[DoctorMenu.HOME]: { isGeneralSetting: false, reDirectUrl: '/doctor-home', component: null, value: showItem, orderNo: 1 },
		[DoctorMenu.SESSIONS_QUEUE]: {
			isGeneralSetting: false,
			reDirectUrl: '/sessions-queue',
			value: showItem,
			orderNo: 2,
		},
		[DoctorMenu.WAITING_ROOM]: {
			isGeneralSetting: false,
			reDirectUrl: '/waiting-room',
			value: showItem,
			orderNo: 3,
		},
		[DoctorMenu.WAITING_ROOM_IN_PERSON]: {
			isGeneralSetting: false,
			reDirectUrl: '/waiting-room-inperson',
			value: showItem,
			orderNo: 11,
		},
		[DoctorMenu.PATIENTS]: { isGeneralSetting: false, reDirectUrl: '/patients', value: showItem, orderNo: 4 },
		[DoctorMenu.HOSPITAL_AT_HOME]: { isGeneralSetting: false, reDirectUrl: '/hospital-at-home', value: showItem, orderNo: 8 },
		[DoctorMenu.APPOINTMENTS]: { isGeneralSetting: false, reDirectUrl: '/appointments', value: showItem, orderNo: 5 },
		[DoctorMenu.ROUNDING]: { isGeneralSetting: false, reDirectUrl: '/health-system', value: showItem, orderNo: 6 },
		[DoctorMenu.PATIENT_MONITORING]: { isGeneralSetting: false, reDirectUrl: '/patient-monitoring', value: showItem, orderNo: 7 },
		[DoctorMenu.LOGISTICS]: {
			isGeneralSetting: false,
			reDirectUrl: '/logistics',
			value: showItem,
			orderNo: 11,
		},
		[GeneralAndMenuSettings.CONVERSATION_HISTORY]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 8 },
		[GeneralAndMenuSettings.NOTIFICATIONS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 9 },
		[GeneralAndMenuSettings.CONVERSATION_FILES]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 15 },
		[GeneralAndMenuSettings.CONVERSATION_PHOTOS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 16 },
		[RoundingSettings.CaregiversName]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 17 },
		[GeneralAndMenuSettings.REGISTRATION_FORM]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 18 },
	},
	[UserRoles.PATIENT]: {
		[PatientMenu.HOME]: { isGeneralSetting: false, reDirectUrl: '/patient-home', value: showItem, orderNo: 1 },
		[PatientMenu.RECENT]: { isGeneralSetting: false, reDirectUrl: '/recent', value: showItem, orderNo: 2 },
		[PatientMenu.APPOINTMENTS]: { isGeneralSetting: false, reDirectUrl: '/appointments', value: showItem, orderNo: 3 },
		[PatientMenu.HEALTH]: { isGeneralSetting: false, reDirectUrl: '/health-data', value: showItem, orderNo: 4 },
		[PatientMenu.SUMMARY]: { isGeneralSetting: false, reDirectUrl: '/summary', value: showItem, orderNo: 5 },
		[PatientMenu.HELLO]: { isGeneralSetting: false, reDirectUrl: '/my-hello-devices', value: showItem, orderNo: 6 },
		[GeneralAndMenuSettings.CONVERSATION_HISTORY]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 7 },
		[GeneralAndMenuSettings.NOTIFICATIONS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 8 },
	},
	[UserRoles.NURSE]: {
		[NurseMenu.ROUNDING]: { isGeneralSetting: false, reDirectUrl: '/health-system', value: showItem, orderNo: 1 },
		[NurseMenu.CHAT]: { isGeneralSetting: false, reDirectUrl: '/nurses', value: showItem, orderNo: 2 },
		[NurseMenu.WAITING_ROOM]: { isGeneralSetting: false, reDirectUrl: '/waiting-room', value: showItem, orderNo: 3 },
		[NurseMenu.TEAMS]: { isGeneralSetting: false, reDirectUrl: '/teams', value: showItem, orderNo: 4 },
		[NurseMenu.HOSPITAL_AT_HOME]: { isGeneralSetting: false, reDirectUrl: '/hospital-at-home', value: showItem, orderNo: 5 },
		[NurseMenu.MONITORING]: { isGeneralSetting: false, reDirectUrl: '/monitoring', value: showItem, orderNo: 6 },
		[NurseMenu.AMBIENT_MONITORING]: { isGeneralSetting: false, reDirectUrl: '/care-notifications', value: showItem, orderNo: 7 },
		[NurseMenu.PATIENT_MONITORING]: { isGeneralSetting: false, reDirectUrl: '/patient-monitoring', value: showItem, orderNo: 8 },
		[NurseMenu.PATIENTS]: {
			isGeneralSetting: false,
			reDirectUrl: '/nurse-patients',
			value: true,
			orderNo: 9,
			parentDependency: NurseMenu.PATIENT_MONITORING,
		},
		[NurseMenu.VISITORS]: { isGeneralSetting: false, reDirectUrl: '/visitors', value: showItem, orderNo: 10 },
		[NurseMenu.LOGISTICS]: {
			isGeneralSetting: false,
			reDirectUrl: '/logistics',
			value: showItem,
			orderNo: 11,
		},

		[GeneralAndMenuSettings.CONVERSATION_HISTORY]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 15 },
		[GeneralAndMenuSettings.NOTIFICATIONS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 16 },
		[GeneralAndMenuSettings.AI_NOTIFICATIONS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 17 },
		[GeneralAndMenuSettings.POOLING_FLOW]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 18 },
		[GeneralAndMenuSettings.MONITORING_PRECAUTIONS_LEGEND]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 19,
		},
		[GeneralAndMenuSettings.MONITORING_SESSIONS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 20 },
		[GeneralAndMenuSettings.CONVERSATION_FILES]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 21 },
		[GeneralAndMenuSettings.CONVERSATION_PHOTOS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 22 },
		[RoundingSettings.CaregiversName]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 23 },
		// PC menu settings
		[NurseMenu.CARE_EVENTS]: { isGeneralSetting: false, reDirectUrl: '/care-events-dashboard', value: showItem, orderNo: 12 },
		[NurseMenu.CHECK_IN]: { isGeneralSetting: false, reDirectUrl: '/nurse-home', value: true, orderNo: 13 },
		[NurseMenu.WAITING_FOR_RESULTS]: { isGeneralSetting: false, reDirectUrl: '/waiting-results', value: showItem, orderNo: 14 },
	},
	[UserRoles.DIGITAL_CLINICIAN]: {
		[DigitalClinicianMenu.CHECK_IN]: {
			isGeneralSetting: false,
			reDirectUrl: '/check-in-dc',
			value: true,
			orderNo: 1,
		},
		[DigitalClinicianMenu.PATIENTS]: {
			isGeneralSetting: false,
			reDirectUrl: '/digital-clinician/patients',
			value: showItem,
			orderNo: 2,
		},
		[DigitalClinicianMenu.VISITS]: { isGeneralSetting: false, reDirectUrl: '/front-desk-visits', value: showItem, orderNo: 4 },
		[DigitalClinicianMenu.REGISTER_PATIENT]: {
			isGeneralSetting: false,
			reDirectUrl: '/check-in-patient',
			value: showItem,
			orderNo: 5,
		},
	},
	[UserRoles.VISITOR]: {
		[VisitorMenu.DEVICES]: { isGeneralSetting: false, reDirectUrl: '/devices', value: true, orderNo: 1 },
		[GeneralAndMenuSettings.CONVERSATION_FILES]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 15 },
		[GeneralAndMenuSettings.CONVERSATION_PHOTOS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 16 },
		// [FamilyMemberMenu.CHAT]: { isGeneralSetting: false, reDirectUrl: '', value: showItem, orderNo: 2 },
	},
	[UserRoles.VIRTUAL_SITTER]: {
		[VirtualSitterMenu.MONITORING]: { isGeneralSetting: false, reDirectUrl: '/monitoring', value: showItem, orderNo: 1 },
		[VirtualSitterMenu.AMBIENT_MONITORING]: {
			isGeneralSetting: false,
			reDirectUrl: '/care-notifications',
			value: showItem,
			orderNo: 2,
		},
		[VirtualSitterMenu.TEAMS]: { isGeneralSetting: false, reDirectUrl: '/teams', value: showItem, orderNo: 3 },
		[GeneralAndMenuSettings.MONITORING_PRECAUTIONS_LEGEND]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 13,
		},
		[GeneralAndMenuSettings.MONITORING_SESSIONS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 14 },
	},
	[UserRoles.GUEST]: {},
});

export const adminsLogTypes = [
	'health.system.settings.updated',
	'hospital.settings.updated',
	'department.settings.updated',
	'floor.settings.updated',
	'room.settings.updated',
	'device.settings.updated',
];

export const menuUrls = [
	'/companies',
	'/users',
	'/audit-logs',
	'/health-system',
	'/dashboard',
	'/call-logs',
	'/configurations',
	'/doctor-home',
	'/nurse-home',
	'/patient-home',
	'/waiting-room',
	'/waiting-room-inperson',
	'/patients',
	'/appointments',
	'/vital-signs-monitoring',
	'/recent',
	'/health-data',
	'/summary',
	'/my-hello-devices',
	'/nurses',
	'/teams',
	'/monitoring',
	'/patient-monitoring',
	'/waiting-results',
	'/visitors',
	'/check-in-dc',
	'/digital-clinician/patients',
	'/front-desk-visits',
	'/queue-management',
	'/sessions-queue',
	'/nurse-patients',
	'/care-notifications',
	'/logistics',
	'/care-events-dashboard',
	'/fleet-management',
	'/nurse-station',
	'/hospital-at-home',
];

export const LandingPrivateRoutes = {
	NURSE_HOME: '/nurse-home',
	DOCTOR_HOME: '/doctor-home',
	CHECK_IN_DC: '/check-in-dc',
};

export const AiSettingsTabs = [
	{ id: 0, title: translate('monitoring') },
	{ id: 1, title: translate('aiSettings') },
];

export const LanguageTypes = {
	'en-US': 'english',
	'ar-AE': 'arabic',
	'de-DE': 'german',
	sq: 'albanian',
	'es-ES': 'spanish',
};

const roundingRolesList = companySettings => [
	{ value: UserRoles.NURSE, label: companySettings.nurseDisplayName },
	{ value: UserRoles.DOCTOR, label: translate('doctor') },
];

const customizationRolesList = companySettings => [
	...roundingRolesList(companySettings),
	{ value: UserRoles.VIRTUAL_SITTER, label: companySettings.virtualSitterDisplayName },
	{ value: UserRoles.DIGITAL_CLINICIAN, label: translate('digitalClinician') },
	{ value: UserRoles.SUPER_USER, label: translate('superUser') },
];

export const hsFeatureFlagsCategories = ({ selectedRole, isNewExperience, isUserExperienceHSEditable, companySettings }) =>
	[
		{
			title: translate('roleCustomization'),
			settingCategory: SettingsCategory.CUSTOMIZATION,
			defaultConfigurations: configurableGeneralAndMenuSettings(selectedRole.value),
			roleList: customizationRolesList(companySettings),
			categoryOrder: Object.values(GeneralAndMenuFeaturesCategories),
		},
		{
			title: translate('monitoring'),
			settingCategory: SettingsCategory.MONITORING,
			defaultConfigurations: configurableMonitoringMenu(getUserRole(), SectorTypes.HEALTH_SYSTEM, isNewExperience),
			categoryOrder: Object.values(MonitoringFeatureFlagCategories),
		},
		{
			title: translate('rounding'),
			settingCategory: SettingsCategory.ROUNDING,
			defaultConfigurations: configurableRoundingMenu(selectedRole.value),
			roleList: roundingRolesList(companySettings),
			categoryOrder: Object.values(RoundingFeaturesCategories),
		},
		{
			title: translate('patientEngagement'),
			settingCategory: SettingsCategory.HELLO_CARE_ENGAGEMENT,
			defaultConfigurations: configurableHelloCareEngagement(SectorTypes.HEALTH_SYSTEM),
		},
		getConfigurationValue(companySettings.companyConfigurations?.[GeneralAndMenuSettings.HOSPITAL_AT_HOME]) && {
			title: translate('hospitalAtHome'),
			settingCategory: SettingsCategory.HOSPITAL_AT_HOME,
			defaultConfigurations: configurableHospitalAtHome(),
		},
		{
			title: translate('aiSettings'),
			settingCategory: SettingsCategory.AI_SETTINGS,
			defaultConfigurations: configurableAISettings(),
			categoryOrder: Object.values(AiSettingFeaturesCategory),
		},
		{
			title: translate('userExperience'),
			settingCategory: SettingsCategory.USER_EXPERIENCE,
			defaultConfigurations: configurableUserExperienceSettings(getUserRole(), isUserExperienceHSEditable),
			categoryOrder: Object.values(UserExperienceFeaturesCategories),
		},
		{
			title: translate('voiceCommands'),
			settingCategory: SettingsCategory.VOICE_COMMANDS,
			defaultConfigurations: configurableVoiceCommandsSettings(),
		},
		{
			title: 'NICU',
			settingCategory: SettingsCategory.NICU,
			defaultConfigurations: configurableNICUSettings(),
		},
	].filter(Boolean);

// atm Hospital, Department, Floor, and Room have the same categories
export const hospitalFeatureFlagsCategories = () => [
	{
		title: translate('monitoring'),
		settingCategory: SettingsCategory.MONITORING,
		defaultConfigurations: configurableMonitoringMenu(getUserRole()),
		categoryOrder: Object.values(MonitoringFeatureFlagCategories),
	},
	{
		title: translate('aiSettings'),
		settingCategory: SettingsCategory.AI_SETTINGS,
		defaultConfigurations: configurableAISettings(),
		categoryOrder: Object.values(AiSettingFeaturesCategory),
	},
	{
		title: translate('voiceCommands'),
		settingCategory: SettingsCategory.VOICE_COMMANDS,
		defaultConfigurations: configurableVoiceCommandsSettings(),
	},
	{
		title: 'NICU',
		settingCategory: SettingsCategory.NICU,
		defaultConfigurations: configurableNICUSettings(),
	},
];

export const HellocareEngagementVariants = {
	HOME: 'home',
	LIVE_TV: 'live-tv',
	MUSIC: 'music',
	MOVIES: 'movies',
	EDUCATION: 'education',
	APPS: 'apps',
	WHITEBOARD: 'whiteboard',
};

export const CompanyCustomizeRoomPanels = [
	{
		id: SettingsCategory.ROOM_TYPES,
		settingCategory: 'roomModesSettings',
		categoryOrder: null,
		featureTypes: configurableRoomType(),
		typeTitle: 'roomType',
		typeKey: 'roomTypeId',
		title: translate('roomModes'),
	},
	{
		id: SettingsCategories.TELEMEDICINE_MODES_SETTINGS,
		settingCategory: 'telemedicineModesSettings',
		title: translate('telemedicineMode'),
	},
	{
		id: SettingsCategories.HELLOCARE_ENGAGEMENT_SETTINGS,
		settingCategory: 'helloCareEngagementSettings',
		title: translate('patientEngagement'),
	},
	{
		id: SettingsCategories.WHITEBOARD_SETTINGS,
		settingCategory: 'whiteboardSettings',
		title: translate('whiteboard'),
	},
	{
		id: SettingsCategory.HOSPITAL_AT_HOME,
		settingCategory: 'hospitalAtHomeSettings',
		title: translate('hospitalAtHome'),
	},
];

export const CompanyFeatureFlagsPanels = [
	{ id: SettingsCategory.CUSTOMIZATION, settingCategory: 'generalAndMenuSettings', title: translate('roleCustomization') },
	{
		id: SettingsCategory.MONITORING,
		settingCategory: 'monitoringSettings',
		categoryOrder: Object.values(MonitoringFeatureFlagCategories),
		title: translate('monitoring'),
	},
	{
		id: SettingsCategory.ROUNDING,
		settingCategory: 'roundingSettings',
		categoryOrder: Object.values(RoundingFeaturesCategories),
		title: translate('rounding'),
	},
	{ id: SettingsCategory.INTEGRATIONS, settingCategory: 'integrationTypesSettings', title: translate('integrations') },
	{
		id: SettingsCategory.ROOM_TYPES,
		settingCategory: 'roomTypesSettings',
		title: translate('roomTypes'),
	},
	{
		id: SettingsCategory.AI_SETTINGS,
		settingCategory: 'aiSettings',
		categoryOrder: Object.values(AiSettingFeaturesCategory),
		featureTypes: configurableWorkflowTypes(),
		typeTitle: 'workflow',
		typeKey: 'callWorkflowTypeId',
		title: translate('aiSettings'),
	},
	{
		id: SettingsCategory.USER_EXPERIENCE,
		settingCategory: 'userExperience',
		categoryOrder: Object.values(UserExperienceFeaturesCategories),
		title: translate('userExperience'),
	},
	{ id: SettingsCategory.DASHBOARD_REPORTS, settingCategory: 'dashboardSettings', title: translate('dashboard') },
	{ id: SettingsCategory.VOICE_COMMANDS, settingCategory: 'voiceCommandsSettings', title: translate('voiceCommands') },
	{ id: SettingsCategory.NICU, settingCategory: 'nicuSettings', title: 'NICU' },
];
