import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import { Form, Textarea, Loader, Alert, Grid } from 'components/index.js';
import { capitalizeFirstLetter, findDeviceById, getRoleConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { getCareEvents } from 'api/teamConfigurationProfiles.js';
import SpeechToText from 'components/SpeechToText/SpeechToText.jsx';
import { CareEventTypes } from 'constants/enums.js';
import classNames from 'classnames';
import { forwardManualAlert, saveManualAlert } from 'api/alerts.js';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import CareEvents from 'components/CareEvents.jsx';
import { getTeams } from 'api/teams.js';
import Button from 'components/Button.jsx';

const AddCareEventsForm = props => {
	const intl = useIntl();
	const [careEvents, setCareEvents] = useState([]);
	const userSession = useSelector(state => state.user.userSession);
	const [selectedAlertType, setSelectedAlertType] = useState(null);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const isCareEventsForNurses = getRoleConfigurationValue(props.roundingConfigurations, RoundingSettings.CareEventsForNurses);
	const [currentTab, setCurrentTab] = useState(0);
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const healthSystem = useSelector(state => state.user.userSession.healthSystem);

	const sendManualAlert = async () => {
		if (!selectedAlertType) {
			return;
		}
		setIsLoading(true);
		const params = {
			comment: recognizedTranscription,
			deviceId: props.deviceId,
			manualAlertTypeId: selectedAlertType,
			patientId: props.patientInfo.guidId,
			conferenceId: props.conferenceId,
		};
		const raiseManualAlertResponse = await saveManualAlert(params);
		if (raiseManualAlertResponse.error) {
			setError(raiseManualAlertResponse.error.message);
		}
		setRecognizedTranscription('');
		setLiveTranscription('');
		setSelectedAlertType(null);
		setIsLoading(false);
	};

	const getHierarchyNaming = deviceId => {
		const device = findDeviceById(tree, deviceId);
		if (!device?.breadcrumb) {
			return {};
		}
		const { breadcrumb } = device;
		return {
			hospital: breadcrumb[0].name,
			department: breadcrumb[1]?.name,
			floor: breadcrumb[2]?.name,
			room: breadcrumb[3]?.name,
		};
	};

	const getSelectedFloor = (deviceId, channels) => {
		const device = findDeviceById(tree, deviceId);
		return channels.find(channel => channel.floorId === device?.floorId);
	};

	const forwardToNurses = async comment => {
		if (!props.patientInfo.guidId) {
			return;
		}
		setIsLoading(true);
		const { hospital, department, floor, room } = getHierarchyNaming(props.deviceId);
		const response = await getTeams(healthSystem.id);
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		let channels = [];
		response.teams.forEach(team => {
			channels = channels.concat(team.channels.map(channel => ({ ...channel, team })));
		});
		const { conversationId } = getSelectedFloor(props.deviceId, channels);
		const params = {
			conversationId,
			hospital,
			department,
			floor,
			room,
			patientId: props.patientInfo.guidId,
			deviceId: props.deviceId,
			manualAlertTypeId: selectedAlertType,
			comment: recognizedTranscription,
		};
		const raiseManualAlertResponse = await forwardManualAlert(params);
		if (raiseManualAlertResponse.error) {
			setError(raiseManualAlertResponse.error.message);
		}
		setRecognizedTranscription('');
		setLiveTranscription('');
		setSelectedAlertType(null);
		setIsLoading(false);
	};

	useEffect(() => {
		const getEvents = async () => {
			const response = await getCareEvents({
				pageSize: 10,
				pageIndex: 0,
				healthSystemId: userSession.healthSystem.id,
				teamCareEventType: isCareEventsForNurses ? CareEventTypes.FrontLineNurses : CareEventTypes.SafetyCompanions,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setCareEvents(response?.teamCareEvents || []);
			setIsLoading(false);
		};
		getEvents();
	}, [userSession.healthSystem.id]);

	return (
		<aside
			className={classNames(
				'right-side medical-info-aside add-notes-wrapper',
				props.isRightToLeft ? 'rtl-medical-info-aside' : '',
				props.isDarkMode ? 'dark-medical-info-aside' : ''
			)}>
			{!isLoading && (
				<>
					<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
						<TabList>
							<Tab className='cursor-pointer'>{translate('addCareEvent')}</Tab>
							<Tab className='cursor-pointer'>{translate('careEventHistory')}</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<Form
									className='modal-form-wrapper send-group-message add-care-events'
									onSubmit={event => event.preventDefault()}>
									<h3>{translate('typeOfCareEvent')}</h3>
									{careEvents.length > 0 && <p>{translate('selectCareEvent')}:</p>}
									{careEvents.length === 0 && (
										<>
											<p className='no-padding'>{translate('noCareEvents')}</p>
											<p className='no-padding'>{translate('askAdministratorForCareEvents')}</p>
										</>
									)}
									{careEvents.length > 0 && (
										<>
											<div className='flex flex-wrap' style={{ marginTop: '20px' }}>
												{careEvents?.map(event => (
													<div
														key={event.id}
														className={selectedAlertType === event.id ? `pill-button selected-pill` : `pill-button`}
														onClick={() => setSelectedAlertType(event.id)}>
														<input type='radio' name='choice' checked={selectedAlertType === event.id} onChange={() => null} />
														<h5 className={selectedAlertType === event.id ? 'selected-pill' : ''}>{event.name}</h5>
													</div>
												))}
											</div>
											<p>{capitalizeFirstLetter(intl.formatMessage({ id: 'note' }))}</p>
											<Textarea
												disabled={!selectedAlertType || isSpeechToText || careEvents.length === 0}
												maxLength={400}
												onChange={event => setRecognizedTranscription(event.target.value)}
												value={
													isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription
												}
												maxNumberLimit={40}
												rows={10}
												name='text'
												placeholder={intl.formatMessage({ id: 'message' })}
												icon={
													<SpeechToText
														setRecognizedTranscription={setRecognizedTranscription}
														setLiveTranscription={setLiveTranscription}
														setIsSpeechToText={setIsSpeechToText}
														isSpeechToText={isSpeechToText}
														isDisabled={careEvents.length === 0 || !selectedAlertType}
													/>
												}
											/>
										</>
									)}
								</Form>
								{careEvents.length > 0 && (
									<div className='add-care-events-buttons'>
										<Button
											className='button'
											disabled={!selectedAlertType}
											onClick={forwardToNurses}
											text={translate('forwardToNurses')}
										/>
										<Button
											className='button white'
											type='button'
											disabled={!selectedAlertType}
											text={translate('save')}
											onClick={sendManualAlert}
										/>
									</div>
								)}
							</TabPanel>
							<TabPanel>
								<CareEvents patientId={props.patientInfo.guidId} />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</>
			)}
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</aside>
	);
};

export default AddCareEventsForm;
