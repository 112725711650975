import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { LocalTrackFactory, Mic, Cam, ScreenShare } from '@solaborate/calls/webrtc';
import { Modal } from 'calls/components/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { attachSinkId, getSavedStreamSettings } from 'calls/helpers/index.js';
import { MediaDeviceKinds, WindowSize } from 'calls/enums/index.js';
import { isMobileOrTablet } from 'infrastructure/helpers/commonHelpers.js';
import { loadTfLite, StreamBackgroundEffect } from 'calls/views/TrackWithBackground.jsx';
import { UserSettings } from 'constants/configurationEnums.js';
import { getUserHealthSystemPreferences } from 'api/users.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import Alert from 'components/Alert.jsx';
import BackgroundImagesSettings from 'calls/views/BackgroundImagesSettings.jsx';
import StreamSettings from 'calls/views/StreamSettings.jsx';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean, $isOutsideOfCall: boolean, $isFromMonitoring: boolean }, never>}
 */
const StyledStreamSettings = styled.div`
	section {
		background: ${LightTheme.colors.grayZero};
		header {
			border-bottom: 1px solid ${LightTheme.colors.grayOne};
			h1 {
				color: ${LightTheme.colors.grayFive};
			}
			button {
				span {
					color: ${LightTheme.colors.grayFive};
				}
			}
		}
	}

	select {
		padding-right: ${LightTheme.spacing[6]}px !important;
	}

	> div {
		video {
			max-width: 200px;
			max-height: 150px;
			transform: scaleX(-1);
		}

		main {
			> header {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: ${LightTheme.spacing[3]}px;
				background: ${LightTheme.colors.graySix};
				border-bottom: none;
			}

			> div {
				&:last-of-type label button {
					padding: 0;
					box-shadow: none;
					span {
						font-size: 18px;
					}
				}

				button {
					span {
						text-align: center;
						i {
							font-size: 22px !important;
						}
					}
				}
			}

			label {
				color: ${LightTheme.colors.grayFive};
				button {
					padding: 0;
					span {
						color: ${LightTheme.colors.grayFive};
					}
				}
			}

			select {
				appearance: none;
				background-color: ${LightTheme.colors.grayZero};
				color: ${LightTheme.colors.grayFive};
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='.87'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
			}
		}
	}

	> section {
		width: 960px;
		display: flex;
		align-items: center;
		margin: auto;

		h1,
		h2,
		p {
			margin: 0;
			padding: 0;
			color: ${LightTheme.colors.grayZero};
		}

		h1 {
			font-size: 18px;
			margin-bottom: ${LightTheme.spacing[2]}px;
		}

		h2 {
			font-size: 16px;
		}

		> main {
			position: relative;
			flex: 2;
			aspect-ratio: 16/9;
			background: ${LightTheme.colors.graySix};
			border-radius: ${LightTheme.borderRadius.base}px;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
			overflow: hidden;

			> video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			> header,
			> div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				padding: ${LightTheme.spacing[5]}px;

				> button {
					margin-right: ${LightTheme.spacing[2]}px;
				}
			}

			> header {
				align-items: center;
				flex-direction: column;

				> div {
					margin-bottom: ${LightTheme.spacing[2]}px;
				}
			}

			> aside {
				position: absolute;
				top: ${LightTheme.spacing[1]}px;
				right: ${LightTheme.spacing[1]}px;
			}
		}

		> aside {
			padding: ${LightTheme.spacing[8]}px;
			flex: 1;
		}
	}

	@media (max-width: ${WindowSize.TABLET}px) {
		min-height: 100vh;
		> section {
			flex-direction: column;
			padding: ${LightTheme.spacing[5]}px;

			> main {
				width: 100%;
			}

			> aside {
				padding: ${LightTheme.spacing[7]}px ${LightTheme.spacing[5]}px;
			}
		}
	}

	${props =>
		props.$isDarkMode &&
		css`
			section {
				background: ${DarkTheme.colors.grayThree};

				header {
					border-bottom: 1px solid ${DarkTheme.colors.grayFour};

					h1 {
						color: ${DarkTheme.colors.grayFive};
					}

					button span {
						color: ${DarkTheme.colors.grayFive};
					}
				}
			}

			div {
				main {
					> header {
						background: ${DarkTheme.colors.grayFour};
					}

					label {
						color: ${DarkTheme.colors.grayFive};

						button span {
							color: ${DarkTheme.colors.grayFive};
						}
					}

					select {
						background-color: ${DarkTheme.colors.grayFour};
						color: ${DarkTheme.colors.grayFive};
					}
				}
			}
		`}

	${props =>
		props.$isOutsideOfCall &&
		css`
			width: 100%;
			height: calc(100vh - 125px);
			border-left: 1px solid ${props.$isDarkMode ? DarkTheme.colors.grayFour : 'var(--gray-2)'};
			text-align: start;
			overflow: auto;
			> div {
				position: unset;
				width: 100%;
				max-width: 100%;
				height: 100%;
			}
		`}

	${props =>
		props.$isFromMonitoring &&
		css`
			> div:first-of-type {
				position: unset;
				background: none;
				height: 100%;
				> section {
					box-shadow: unset;
					width: 100%;
					max-width: 100%;
					> header {
						display: none;
					}
					> div {
						padding: 0;
						> main {
							> header {
								padding: 0;
							}
							> div {
								> label {
									button {
										padding: 0 !important;
										background: unset !important;
									}
								}
								> select {
									box-shadow: unset;
									border: 1px solid var(--gray-8-o05);
								}
							}
							.stream-settings-wrapper {
								> button {
									padding: 0 !important;
									background: unset !important;
									min-width: 50px;
									border: 1px solid ${props.$isDarkMode ? 'var(--gray-0-o10)' : 'var(--gray-8-o05)'} !important;
									> img {
										border-radius: var(--spacing-s);
									}
								}
							}
							.virtual-background-buttons-container {
								> button {
									background-color: var(--blue-2) !important;
									border-color: var(--blue-2) !important;
								}
							}
						}
					}
				}
			}
		`}
`;

/**
 * @typedef {import('@solaborate/calls/webrtc').LocalTrack} LocalTrack
 * @param {object} props
 * @param {() => void} props.onDismiss
 * @param {(localTracks: LocalTrack[]) => void} [props.setLocalTracks]
 * @param {LocalTrack[]} [props.localTracks]
 * @param {() => void} [props.createLocalTracks]
 * @param {string} [props.position]
 * @param {boolean} [props.isOutsideOfCall]
 * @param {boolean} [props.isFromMonitoring]
 * @param {object} [props.localParticipant]
 */

const StreamSettingsView = ({
	onDismiss,
	setLocalTracks,
	localTracks,
	createLocalTracks,
	position,
	isOutsideOfCall = false,
	isFromMonitoring = false,
	localParticipant,
}) => {
	let { current: modelOptions } = useRef({
		height: 144,
		width: 256,
		virtualBackground: {
			backgroundEffectEnabled: false,
			backgroundType: 'none',
		},
	});

	const [tracks, setTracks] = useState({});
	const localTrackFactory = useRef(null);
	const previewVideoRef = useRef(null);
	const audioRefOutput = useRef(null);
	const intl = useIntl();
	const [streamEffect, setStreamEffect] = useState(null);
	const [error, setError] = useState(null);
	const userSession = useSelector(state => state.user.userSession);
	const dispatch = useDispatch();

	useEffect(() => {
		const audioOutputId = getSavedStreamSettings()[MediaDeviceKinds.AUDIO_OUTPUT];
		if (audioOutputId) {
			attachSinkId(audioRefOutput.current, audioOutputId);
		}
		const fetchUserSettings = async () => {
			const response = await getUserHealthSystemPreferences(userSession.healthSystem.id);
			if (response.error) {
				return;
			}
			let obj = {};
			response.teamMemberSettings.forEach(item => {
				if (Object.values(UserSettings).includes(item.settingTypeId)) {
					obj[item.settingTypeId] = item.value;
				}
			});
			dispatch(configurationActionCreators.setUserSettings(obj));
			setStreamSettings();
		};
		fetchUserSettings();
	}, []);

	useEffect(() => {
		const getTracks = async () => {
			if (previewVideoRef?.current?.srcObject) {
				return;
			}
			const videoTrack = tracks[Cam];
			if (!videoTrack) {
				return;
			}
			if (isMobileOrTablet()) {
				const factoryTracksResponse = await localTrackFactory.current.createTracks([Cam]);
				previewVideoRef.current.srcObject = new MediaStream([factoryTracksResponse[0].track]);
				return;
			}
			if (videoTrack && videoTrack instanceof StreamBackgroundEffect) {
				modelOptions = { ...videoTrack.options };
			}
		};
		getTracks();
	}, [tracks]);

	useEffect(() => {
		const videoTrack = localTracks?.[Cam];
		if (videoTrack) {
			previewVideoRef.current.srcObject = new MediaStream([videoTrack]);
		}
	}, [localTracks]);

	const setStreamSettings = async () => {
		const { [MediaDeviceKinds.AUDIO_INPUT]: audioInputId, [MediaDeviceKinds.VIDEO_INPUT]: videoInputId } =
			getSavedStreamSettings();
		/** @type {import('@solaborate/calls/webrtc').Constraints} */
		const constraints = { [Mic]: true, [Cam]: true, [ScreenShare]: false };
		if (audioInputId) {
			constraints.audio = { deviceId: audioInputId };
		}
		if (videoInputId) {
			constraints.video = { deviceId: videoInputId };
		}

		if (localTracks) {
			createLocalTracks();
			return;
		}

		localTrackFactory.current = new LocalTrackFactory(constraints);
		try {
			const factoryTracksResponse = await localTrackFactory.current.createTracks([Mic, Cam]);
			if (factoryTracksResponse) {
				const tracksToSet = factoryTracksResponse.reduce((acc, track) => ({ ...acc, [track.type]: track }), {});
				setTracks(tracksToSet);
				const tflite = await loadTfLite();
				const sbe = new StreamBackgroundEffect(tracksToSet[Cam], tflite, modelOptions);
				previewVideoRef.current.srcObject = new MediaStream([sbe.track]);
				setStreamEffect(sbe);
				if (setLocalTracks) {
					setLocalTracks(factoryTracksResponse);
				}
			}
		} catch (err) {
			setError(err.error.message);
		}
	};

	const isDarkMode = useSelector(state => state.user.darkMode);

	return (
		<StyledStreamSettings $isDarkMode={isDarkMode} $isOutsideOfCall={isOutsideOfCall} $isFromMonitoring={isFromMonitoring}>
			<Modal onDismiss={onDismiss} title={intl.formatMessage({ id: 'streamSettingsModalTitle' })} position={position}>
				<Modal.Content>
					<header>
						<video ref={previewVideoRef} autoPlay muted playsInline />
					</header>
					<StreamSettings tracks={tracks} />
					<BackgroundImagesSettings
						onDismiss={onDismiss}
						videoRef={previewVideoRef}
						streamEffect={streamEffect}
						tracks={localTracks}
					/>
				</Modal.Content>
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</Modal>
		</StyledStreamSettings>
	);
};

export default StreamSettingsView;
