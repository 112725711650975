import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import { LiveExamQRCode, MeasureDeviceType, MeasurementTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { MeasureStates } from 'constants/examinations.jsx';
import HeartLungsExaminations from 'containers/LiveExaminations/HeartLungsExaminations.jsx';
import { useConferenceConfigurations, useConferenceParticipants, useScreenType } from 'calls/hooks/index.js';
import OtoDermatoscope from 'containers/LiveExaminations/OtoDermatosope.jsx';
import MeasureDevices from 'containers/LiveExaminations/MeasureDevices.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import { Loader } from 'calls/components/index.js';
import Instructions from 'containers/LiveExaminations/Instructions.jsx';
import ToastMessages from 'containers/LiveExaminations/ToastMessages.jsx';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import Button from 'components/Button.jsx';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { getRoleConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';

const liveExaminationLink = `${healthCareCdnUrl}live-examination/`;

const HelloDeviceExamination = props => {
	const intl = useIntl();
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const conferenceParticipants = useConferenceParticipants();
	const helloParticipant = conferenceParticipants.find(p => p instanceof RemoteHelloParticipant);
	const conferenceConfigurations = useConferenceConfigurations();
	const screenType = useScreenType();

	const handleGenerateQRCode = () => {
		if (!props.isVitalKitAuthorized) {
			props.setExaminationType(LiveExamQRCode.QR_CODE, '', '', getUserInfo().userId);
		}
	};

	const showMedicalDevice = item =>
		getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.MedicalDevices) &&
		getRoleConfigurationValue(roleRoundingConfigurations, item.settingTypeId);
	const isMobileClient = [enums.ClientTypes.IOS, enums.ClientTypes.ANDROID].includes(props.remoteParticipant?.clientType);

	const isVitalKitThermometer =
		props.selectedMeasureDevice === MeasureDeviceType.VITAL_KIT &&
		props.selectedExamination?.type === MeasurementTypes.TEMPERATURE;

	const showStopMeasuringButton = () =>
		(![MeasureStates.MEASURE_COMPLETED, MeasureStates.CONNECT, MeasureStates.DISCONNECTED].includes(
			props.selectedExamination?.buttonText
		) &&
			props.selectedMeasureDevice === MeasureDeviceType.WATCH) ||
		(props.selectedExamination?.buttonText === MeasureStates.MEASURING &&
			props.selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS);

	const areDisabledLiveExamTypes = () =>
		helloParticipant && !props.isVitalKitAuthorized && props.selectedMeasureDevice === MeasureDeviceType.VITAL_KIT;

	const isGridView = () =>
		(props.selectedExamination?.otherExaminations &&
			props.selectedExamination?.otherExaminations.length &&
			props.selectedMeasureDevice !== MeasureDeviceType.WATCH) ||
		props.selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS;

	const isRetakeButtonShown = () =>
		[MeasureStates.MEASURE_COMPLETED, MeasureStates.COULD_NOT_GET_PATIENT_DATA].includes(props.selectedExamination?.buttonText) ||
		props.checkScaleStates() ||
		(props.selectedExamination?.value > 0 &&
			props.selectedExamination?.buttonText === MeasureStates.CONNECT &&
			props.isLastMeasureShown);

	const showDeviceStatusWithInstruction = () =>
		[
			MeasureStates.DISCONNECTED,
			MeasureStates.CONNECTING,
			MeasureStates.STAND_ON_SCALE,
			MeasureStates.CONNECTION_FAILED,
			MeasureStates.PUT_NEW_STRIP,
		].includes(props.selectedExamination?.buttonText) &&
		props.isLastMeasureShown &&
		props.selectedMeasureDevice !== MeasureDeviceType.WATCH &&
		![MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) &&
		!isVitalKitThermometer;

	const showGoBack = () =>
		((props.selectedMeasureDevice && props.selectedExamination) || (helloParticipant && props.isQRCode)) && !props.videoTrack;

	const getOtoDermatoscopeBtnText = buttonState => {
		switch (buttonState) {
			case MeasureStates.CONNECTING:
				return MeasureStates.CONNECTING;
			case MeasureStates.TRYING_TO_CONNECT:
				return MeasureStates.TRYING_TO_CONNECT;
			case MeasureStates.SCAN_NOT_FOUND:
				return MeasureStates.SCAN_NOT_FOUND;
			case MeasureStates.SCAN_FAILED:
				return MeasureStates.SCAN_FAILED;
			case MeasureStates.CONNECTION_FAILED:
				return MeasureStates.CONNECTION_FAILED;
			case MeasureStates.DISCONNECTED:
				return MeasureStates.DEVICE_DISCONNECTED;
			default:
				return 'initiateDevice';
		}
	};

	const isWifiDevice = () =>
		[
			MeasureStates.TRYING_TO_CONNECT,
			MeasureStates.SCAN_NOT_FOUND,
			MeasureStates.SCAN_FAILED,
			MeasureStates.CONNECTION_FAILED,
			MeasureStates.DISCONNECTED,
		].includes(props.selectedExamination?.buttonText);

	const isWiFiDeviceTryingToConnect = () =>
		[MeasureStates.CONNECTING, MeasureStates.TRYING_TO_CONNECT].includes(props.selectedExamination?.buttonText);

	const showStethoscopeConnectButton = () =>
		[MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) &&
		[MeasureStates.CONNECT, MeasureStates.CONNECTING, MeasureStates.DISCONNECTED].includes(props.selectedExamination?.buttonText);

	const isInstructionsShown = () =>
		props.selectedExamination?.isMeasureDevice &&
		!props.videoTrack &&
		[MeasureStates.CONNECT, MeasureStates.CONNECTING, MeasureStates.DISCONNECTED, MeasureStates.CONNECTION_FAILED].includes(
			props.selectedExamination?.buttonText
		) &&
		(!props.selectedExamination?.value || !props.isLastMeasureShown) &&
		![MeasurementTypes.EAR, MeasurementTypes.THROAT, MeasurementTypes.SKIN].includes(props.selectedExamination?.type) &&
		![MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(props.selectedMeasureDevice);

	const getDeviceBtnText = () =>
		[MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(props.selectedExamination?.type) &&
		props.selectedExamination?.buttonText === MeasureStates.DISCONNECTED
			? MeasureStates.CONNECT
			: props.selectedExamination?.buttonText;

	const renderExaminationText = () => {
		if (![MeasurementTypes.EAR, MeasurementTypes.THROAT, MeasurementTypes.SKIN].includes(props.selectedExamination?.type)) {
			return translate('initiateDevice', {
				value: intl.formatMessage({
					id: props.selectedExamination?.device ? `the${props.selectedExamination?.device}` : 'pulseOximeter',
				}),
			});
		} else if ([MeasurementTypes.EAR, MeasurementTypes.THROAT].includes(props.selectedExamination?.type)) {
			return translate(getOtoDermatoscopeBtnText(props.selectedExamination?.buttonText), {
				value: `${isWifiDevice() && !props.hasUsbOtoscope ? 'Wi-Fi' : ''} ${intl.formatMessage({
					id: props.selectedExamination?.device,
				})}`,
			});
		} else if (props.selectedExamination?.type === MeasurementTypes.SKIN) {
			return translate(getOtoDermatoscopeBtnText(props.selectedExamination?.buttonText), {
				value: `${isWifiDevice() && !props.hasUsbDermatoscope ? 'Wi-Fi' : ''} ${intl.formatMessage({
					id: props.selectedExamination?.device,
				})}`,
			});
		} else {
			return '';
		}
	};

	const isCallSettingsPanelOpen = () =>
		conferenceConfigurations.isInviteParticipantsModalViewOpen || conferenceConfigurations.isAudioAndVideoSettingsModalOpen;

	return (
		<>
			<aside
				className={classNames(
					'right-side live-examination-kit',
					props.showStethoscopeHistory ? 'stetho-history-visible' : '',
					isMobile || screenType.isSmall ? 'live-examinations-mobile' : '',
					conferenceConfigurations.isDarkMode ? 'dark-aside-frame' : '',
					isCallSettingsPanelOpen() ? 'hidden' : ''
				)}
				ref={props.measurementRecentElement}
				onScroll={props.handleScroll}>
				<div className={classNames('flex flex-1', screenType.isSmall ? 'reverse-column-direction' : 'column-direction')}>
					{!props.isPreviousMeasurementsView && (
						<div
							className={classNames(
								'live-examination-grid',
								props.rpmMeasurementTypes.length && isMobileClient ? 'rpm-examination-types' : ''
							)}>
							{props.examinationTypes.map(
								item =>
									showMedicalDevice(item) && (
										<div
											key={item.id}
											onClick={() => props.setExamination(item)}
											className={classNames(
												'examination-icon-wrapper',
												props.selectedExamination?.id === item.id ? 'active' : '',
												item.content,
												item.isDisabled || areDisabledLiveExamTypes() ? 'live-examination-disabled' : '',
												props.rpmMeasurementTypes.length > 0 && !props.rpmMeasurementTypes.includes(item.type) && isMobileClient
													? 'display-none'
													: ''
											)}>
											<div>{props.selectedExamination?.id === item.id ? item.selectedIcon : item.icon}</div>
											<p>{translate(item.content)}</p>
										</div>
									)
							)}
						</div>
					)}
					<div className={classNames('flex column-direction flex-1', { 'bottom-15': screenType.isSmall })}>
						{!props.isPreviousMeasurementsView && (
							<div
								className={classNames(
									'live-examination-view position-relative',
									props.selectedExamination?.value ||
										([MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) &&
											![MeasureStates.CONNECT, MeasureStates.CONNECTING].includes(props.selectedExamination?.buttonText))
										? 'measurement-mode'
										: '',
									props.selectedExamination?.type === MeasurementTypes.HEART ? 'heart-background' : '',
									props.selectedExamination?.type === MeasurementTypes.LUNGS ? 'lung-background' : '',
									props.selectedExamination?.type === MeasurementTypes.SKIN && props.videoTrack
										? 'overflow-hidden no-padding-top'
										: '',
									props.videoTrack ? 'video-examination' : ''
								)}>
								{!props.selectedExamination && (isMobileClient || (helloParticipant && !props.isQRCode)) && (
									<MeasureDevices
										setSelectedMeasureDevice={props.setSelectedMeasureDevice}
										inviteCompanionParticipant={props.inviteCompanionParticipant}
										setExaminationTypes={props.setExaminationTypes}
										examinationTypes={props.examinationTypes}
										selectedMeasureDevice={props.selectedMeasureDevice}
										setIsQRCode={props.setIsQRCode}
										isHelloClient={!!helloParticipant}
										isMobileClient={isMobileClient}
										removeVitalKitFromCall={props.removeVitalKitFromCall}
										isAekButtonDisabled={props.isAekButtonDisabled}
										onGenerateQRCode={handleGenerateQRCode}
									/>
								)}
								{!props.selectedExamination &&
									helloParticipant &&
									props.selectedMeasureDevice === MeasureDeviceType.VITAL_KIT && (
										<div className='flex flex-justify-center scan-generated-qr-code'>
											<p>{translate(props.isVitalKitAuthorized ? 'selectOneOfExaminations' : 'scanGeneratedQRCode')}</p>
										</div>
									)}
								{showGoBack() && (
									<div
										className={classNames(
											'go-back-btn position-absolute',
											props.isQRCode && !props.isVitalKitAuthorized ? 'left-l' : ''
										)}
										onClick={props.goBack}>
										<i className='material-icons-outlined cursor-pointer'>west</i>
									</div>
								)}
								{props.selectedExamination && (
									<div className={props.videoTrack ? 'full-width full-height' : ''}>
										<>
											{(isInstructionsShown() || showStethoscopeConnectButton()) && (
												<>
													<Instructions selectedExamination={props.selectedExamination} />
													<Button
														className={classNames(
															'flex',
															props.selectedExamination.type === MeasurementTypes.ABDOMEN ? 'live-examination-disabled' : ''
														)}
														onClick={() => props.startMeasuring(true)}
														imgIcon={`${liveExaminationLink}devices/${props.selectedExamination.deviceImg}`}
														alt='device icon'
														text={
															props.selectedExamination?.isMeasureDevice
																? (props.selectedExamination?.buttonText === MeasureStates.CONNECTION_FAILED &&
																		translate('deviceWiFiConnectionFailed', {
																			value: intl.formatMessage({
																				id: props.selectedExamination?.device,
																			}),
																		})) ||
																  (props.selectedExamination?.buttonText !== MeasureStates.CONNECTION_FAILED &&
																		translate('connectDevice', {
																			value: intl.formatMessage({
																				id: getDeviceBtnText(),
																			}),
																			value1: intl.formatMessage({
																				id: props.selectedExamination?.device
																					? `the${props.selectedExamination?.device}`
																					: 'pulseOximeter',
																			}),
																		}))
																: ''
														}
													/>
												</>
											)}
											{!props.selectedExamination?.isMeasureDevice &&
												!props.videoTrack &&
												![MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(props.selectedMeasureDevice) && (
													<>
														<Instructions selectedExamination={props.selectedExamination} />
														<Button
															className={classNames('flex', [
																props.selectedExamination?.type === MeasurementTypes.ABDOMEN ? 'live-examination-disabled' : '',
															])}
															onClick={() => props.startMeasuring(true)}
															text={renderExaminationText()}
															svgIcon={isWiFiDeviceTryingToConnect() && <Loader $color='var(--green-4)' />}
														/>
													</>
												)}
											{((props.selectedExamination?.isMeasureDevice &&
												props.selectedExamination?.buttonText === MeasureStates.START_MEASURING) ||
												([MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) &&
													props.isAnotherPoint)) && (
												<>
													<p className='selected-examination-description'>
														{translate('deviceIsConnectedStartMeasurement', {
															value: intl.formatMessage({ id: props.selectedExamination?.device }),
														})}
													</p>
													{[MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) &&
														!props.selectedExaminationPoint && <p>{translate('selectOneOfThePoints')}</p>}
													<Button
														className={classNames('flex', [
															props.selectedExamination?.type === MeasurementTypes.ABDOMEN ||
															([MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(props.selectedExamination?.type) &&
																!props.selectedExaminationPoint &&
																props.selectedExamination.buttonText === MeasureStates.START_MEASURING) ||
															(props.isAnotherPoint && !props.selectedExaminationPoint)
																? 'live-examination-disabled'
																: '',
														])}
														onClick={() => {
															props.startMeasuring(true);
															props.setIsAnotherPoint(false);
															props.setIsRetakeHidden(false);
														}}
														imgIcon={`${liveExaminationLink}devices/${props.selectedExamination?.deviceImg}`}
														alt='device icon'
														text={translate(
															props.isAnotherPoint ? MeasureStates.START_MEASURING : props.selectedExamination?.buttonText
														)}
													/>
												</>
											)}
											{props.selectedExamination?.isMeasureDevice && (
												<>
													{(props.selectedExamination?.buttonText === MeasureStates.MEASURING ||
														props.selectedExamination?.value ||
														props.selectedExamination?.value === 0) && (
														<div
															className={classNames(
																'flex flex-wrap measurement-info',
																isGridView() ? `others-grid others-grid-${props.gridNumber}` : '',
																[MeasurementTypes.HEART_RATE, MeasurementTypes.OXYGEN].includes(props.selectedExamination?.type)
																	? 'pulse-oximeter-grid'
																	: '',
																[MeasurementTypes.HEART, MeasurementTypes.LUNGS, MeasurementTypes.TEMPERATURE].includes(
																	props.selectedExamination?.type
																) && props.isRetakeHidden
																	? 'hidden'
																	: '',
																props.selectedExamination?.type === MeasurementTypes.WEIGHT ? 'weight-measurements' : ''
															)}>
															<div
																className={
																	[MeasurementTypes.HEART_RATE, MeasurementTypes.OXYGEN].includes(props.selectedExamination?.type)
																		? 'remove-pi pulse-oximeter-responsive'
																		: ''
																}>
																<div className='column-direction'>
																	{props.selectedExamination?.type !== MeasurementTypes.SPIROMETER && (
																		<p>{translate(props.selectedExamination?.content)}</p>
																	)}
																	<div className='flex'>
																		{props.selectedExamination?.type !== MeasurementTypes.SPIROMETER &&
																			props.selectedExamination?.selectedIcon}
																		{props.selectedExamination?.type !== MeasurementTypes.SPIROMETER && (
																			<div className='flex right-align-items flex-justify-center'>
																				<h3>
																					{props.selectedExamination?.type && (
																						<>
																							{props.getExaminationValue() === 0 && props.getExaminationValue()}
																							{props.getExaminationValue() !== 0 &&
																								(props.getExaminationValue() ||
																									(![MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(
																										props.selectedExamination?.type
																									) &&
																										'--'))}
																							{[MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(
																								props.selectedExamination?.type
																							) && props.time}
																						</>
																					)}
																				</h3>
																				{!props.time &&
																					![MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(
																						props.selectedExamination?.type
																					) && (
																						<span>
																							{props.selectedExamination?.type &&
																							props.selectedExamination?.value &&
																							props.selectedExamination?.unitCategoryId
																								? props.getUnitPreference(props.selectedExamination?.unitCategoryId)?.unit
																								: props.selectedExamination?.unit}
																						</span>
																					)}
																			</div>
																		)}
																		{props.selectedExamination?.type === MeasurementTypes.SPIROMETER && (
																			<div className='flex right-align-items flex-justify-center spirometer-measurement'>
																				{props
																					.getSpirometerArr(props.selectedExamination?.value)
																					.map((value, valueIndex) => props.getSpirometerDetails(value, valueIndex))}
																			</div>
																		)}
																	</div>
																</div>

																{![MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(
																	props.selectedMeasureDevice
																) &&
																	props.selectedExamination?.otherExaminations?.map(item => {
																		const found = props.examinationTypes.find(el => el.type === item.type);
																		return (
																			<div key={item.id} className='flex'>
																				<p>{translate(item.content)}</p>
																				<div className='flex right-align-items'>
																					{props.getExaminationResult(props.selectedExamination?.type, found)}
																					<span>
																						{found.type && found.value && found.unitCategoryId
																							? props.getUnitPreference(found.unitCategoryId)?.unit
																							: found.unit}
																					</span>
																				</div>
																			</div>
																		);
																	})}
															</div>
														</div>
													)}
													{props.selectedMeasureDevice !== MeasureDeviceType.WATCH && isRetakeButtonShown() && (
														<div className='flex'>
															{![MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) &&
																!isVitalKitThermometer &&
																(props.selectedExamination?.value || props.isLastMeasureShown) && (
																	<ToastMessages
																		onClick={() => props.startMeasuring(true)}
																		selectedExamination={props.selectedExamination}
																	/>
																)}
															{([MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) ||
																isVitalKitThermometer) &&
																!props.isRetakeHidden && (
																	<>
																		<div onClick={() => props.startMeasuring(true)} className='cursor-pointer'>
																			<p className='retake-measurement flex flex-align-center margin-right-m user-select-none'>
																				<img src={`${liveExaminationLink}retry.svg`} alt='device icon' />
																				{translate('retakeMeasurement')}
																			</p>
																		</div>
																		{props.selectedExamination?.type !== MeasurementTypes.TEMPERATURE && (
																			<div onClick={props.selectAnotherPoint} className='cursor-pointer'>
																				<p className='completed-measurement flex flex-align-center'>
																					<img
																						src={`${liveExaminationLink}devices/${props.selectedExamination?.deviceImg}`}
																						alt='device icon'
																					/>
																					{translate('selectAnotherPoint')}
																				</p>
																			</div>
																		)}
																	</>
																)}
														</div>
													)}
													{props.selectedExamination?.buttonText === MeasureStates.MEASURE_COMPLETED &&
														[MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(props.selectedMeasureDevice) && (
															<div className='flex'>
																<div
																	onClick={() =>
																		props.toggleMeasuringWithDeviceType(
																			props.selectedMeasureDevice === MeasureDeviceType.WATCH
																				? MeasureDeviceType.WATCH
																				: MeasureDeviceType.AI_DETECTIONS,
																			props.selectedExamination,
																			true
																		)
																	}
																	className='cursor-pointer'>
																	<p className='retake-measurement flex flex-align-center margin-right-m'>
																		<img src={`${liveExaminationLink}retry.svg`} alt='device icon' />
																		{translate('retakeMeasurement')}
																	</p>
																</div>
																<p className='completed-measurement flex flex-align-center'>
																	<img
																		src={`${liveExaminationLink}devices/${props.selectedExamination?.deviceImg}`}
																		alt='device icon'
																	/>
																	{translate('measureCompleted')}
																</p>
															</div>
														)}

													{props.selectedExamination?.buttonText === MeasureStates.MEASURING &&
														props.selectedMeasureDevice !== MeasureDeviceType.WATCH && (
															<div className='flex column-direction flex-align-center'>
																{([MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) ||
																	isVitalKitThermometer) && (
																	<>
																		<div
																			onClick={() => {
																				props.startMeasuring(false);
																				props.setIsRetakeHidden(false);
																			}}
																			className='cursor-pointer'>
																			<Button
																				className='stop-measuring-btn flex bottom-30'
																				imgIcon={`${liveExaminationLink}devices/stop-measuring.svg`}
																				alt='device icon'
																				text={translate('stopMeasuring')}
																			/>
																		</div>
																		<p className={`${!props.selectedExamination?.value ? 'value-is-measuring' : ''}`}>
																			<img
																				src={`${liveExaminationLink}devices/${props.selectedExamination?.deviceImg}`}
																				alt='device icon'
																			/>
																			{translate('deviceIsMeasuring', {
																				value: intl.formatMessage({ id: props.selectedExamination?.device || 'pulseOximeter' }),
																			})}
																		</p>
																	</>
																)}
																{![MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) &&
																	!isVitalKitThermometer &&
																	(props.selectedExamination?.value || props.isLastMeasureShown) && (
																		<ToastMessages selectedExamination={props.selectedExamination} />
																	)}
															</div>
														)}
													{showDeviceStatusWithInstruction() && (
														<div className='flex column-direction flex-align-center'>
															<ToastMessages
																onClick={() => props.startMeasuring(true)}
																selectedExamination={props.selectedExamination}
															/>
														</div>
													)}
													{showStopMeasuringButton() && (
														<div className='flex column-direction flex-align-center'>
															<div
																onClick={() =>
																	props.toggleMeasuringWithDeviceType(
																		props.selectedMeasureDevice === MeasureDeviceType.WATCH
																			? MeasureDeviceType.WATCH
																			: MeasureDeviceType.AI_DETECTIONS
																	)
																}
																className='cursor-pointer'>
																<Button
																	className='stop-measuring-btn flex bottom-30'
																	imgIcon={`${liveExaminationLink}devices/stop-measuring.svg`}
																	alt='device icon'
																	text={translate('stopMeasuring')}
																/>
															</div>
															<p className={`${!props.selectedExamination?.value ? 'value-is-measuring' : ''}`}>
																<img
																	src={`${liveExaminationLink}devices/${props.selectedExamination?.deviceImg}`}
																	alt='device icon'
																/>
																{translate('deviceIsMeasuring', {
																	value: `${
																		props.selectedMeasureDevice === MeasureDeviceType.WATCH ? 'Watch' : 'AI with camera'
																	}`,
																})}
															</p>
														</div>
													)}
													{props.selectedMeasureDevice === MeasureDeviceType.WATCH &&
														[MeasureStates.CONNECT, MeasureStates.DISCONNECTED].includes(
															props.selectedExamination?.buttonText
														) && (
															<Button
																className='flex'
																onClick={() =>
																	props.toggleMeasuringWithDeviceType(MeasureDeviceType.WATCH, props.selectedExamination, true)
																}
																imgIcon={`${liveExaminationLink}devices/${props.selectedExamination?.deviceImg}`}
																alt='device icon'
																text={translate('startMeasuring')}
															/>
														)}
													{[MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) && (
														<HeartLungsExaminations
															selectedExamination={props.selectedExamination}
															selectedExaminationPoint={props.selectedExaminationPoint}
															setShowStethoscopeHistory={props.setShowStethoscopeHistory}
															selectExaminationPoint={props.selectExaminationPoint}
															isConnected={props.isConnected}
														/>
													)}
												</>
											)}
											{!props.selectedExamination?.isMeasureDevice && (
												<OtoDermatoscope
													isVitalKitAuthorized={props.isVitalKitAuthorized}
													videoTrack={props.videoTrack}
													toggleCameraFeed={props.toggleCameraFeed}
													startMeasuring={props.startMeasuring}
													isHelloClient={!!helloParticipant}
													isCompanionParticipant={props.isCompanionParticipant}
													selectExaminationPoint={props.selectExaminationPoint}
													measurementType={props.selectedExamination?.type}
													takePictureButtonState={props.takePictureButtonState}
													setTakePictureButtonState={props.setTakePictureButtonState}
													isTakePictureButtonClicked={props.isTakePictureButtonClicked}
													setIsTakePictureButtonClicked={props.setIsTakePictureButtonClicked}
												/>
											)}
										</>
									</div>
								)}
							</div>
						)}
						{props.selectedExamination?.isMeasureDevice && props.previousMeasurements.length > 0 && (
							<div
								className={classNames(
									'live-examination-prev',
									props.isPreviousMeasurementsView ? 'flex-1' : '',
									props.selectedExamination?.type === MeasurementTypes.SPIROMETER ? 'spirometer-measurements' : ''
								)}>
								<div className={`flex ${props.isPreviousMeasurementsView ? '' : 'right-align-content'}`}>
									{props.isPreviousMeasurementsView && (
										<>
											<span
												className='go-back-btn'
												onClick={() => {
													props.setIsPreviousMeasurementsView(false);
													props.setPageIndex(0);
												}}>
												<i className='material-icons-outlined cursor-pointer'>west</i>
											</span>
											<p>{translate('previousMeasurements')}</p>
										</>
									)}
									{!props.isPreviousMeasurementsView && (
										<span
											onClick={() => {
												props.setIsPreviousMeasurementsView(true);
												props.setPageIndex(0);
											}}
											className='show-more-history'>
											{translate('showPreviousMeasurements')}
										</span>
									)}
								</div>
								{props.isPreviousMeasurementsView && (
									<div className='flex flex-wrap'>
										{props.getPreviousMeasurementArr(
											props.isPreviousMeasurementsView ? props.previousMeasurementsHistory : props.previousMeasurements
										)}
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</aside>
		</>
	);
};

export default HelloDeviceExamination;
