import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import Textarea from 'components/Textarea.jsx';
import SpeechToText from 'components/SpeechToText/SpeechToText.jsx';
import ActionButtons from 'containers/Monitoring/Whiteboard/ActionButtons.jsx';
import Button from 'components/Button.jsx';

const Activity = props => {
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);

	const intl = useIntl();

	useEffect(() => {
		setRecognizedTranscription(props.data);
	}, [props.data]);

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('activity')}</p>
				<div className='timeline-box-actions'>
					<ActionButtons
						handleEditClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}
						handleExpandClick={() => setExpanded(prevState => !prevState)}
						expanded={expanded}
					/>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations full-width'>
							<tbody>
								<tr>
									<td>{translate('activity')}</td>
									<td className='break-word'>{props.data}</td>
								</tr>
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex flex-space-between flex-wrap whiteboard-select'>
							<Textarea
								maxLength={1000}
								onChange={event => setRecognizedTranscription(event.target.value)}
								value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
								rows={10}
								name='text'
								placeholder={intl.formatMessage({ id: 'message' })}
								className='full-width'
								icon={
									<SpeechToText
										setRecognizedTranscription={setRecognizedTranscription}
										setLiveTranscription={setLiveTranscription}
										setIsSpeechToText={setIsSpeechToText}
										isSpeechToText={isSpeechToText}
									/>
								}
							/>
							<Button
								className={classNames('button', props.isSubmitButtonLoading ? 'loading' : '')}
								onClick={() => props.setData(recognizedTranscription)}
								text={translate('save')}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default Activity;
