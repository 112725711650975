import React from 'react';
import Grid from 'components/Grid.jsx';
import { Button } from 'components/index.js';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, errorInfo: null };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		const stackLine = error.stack.split('\n')[1];
		this.setState({ errorInfo: error, info, stackLine: stackLine });
		console.error('Error caught in boundary:', error, info);
	}

	render() {
		if (this.state.hasError) {
			return (
				<Grid className='member-error-wrapper' columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<h1 style={{ fontSize: 80 }}>:-(</h1>
						<h3> Something went wrong and we couldn't complete your request. Please refresh the page. </h3>
						<p className='member-error align-center '>
							<b>Error:</b> {this.state?.errorInfo?.message} {this.state?.stackLine}.
						</p>
						<div className='top-20'>
							<Button text='reload' marginRight='var(--spacing-s)' onClick={() => window.location.reload()} />
						</div>
					</div>
				</Grid>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
