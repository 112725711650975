import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MediaDevicesController, MediaDevicesUpdated, Mic } from '@solaborate/calls/webrtc';
import { MediaDeviceKinds } from 'calls/enums/index.js';
import { useConferenceConfigurations } from 'calls/hooks/index.js';
import { attachSinkId, getSavedStreamSettings } from 'calls/helpers/index.js';
import { Select, IconButton, ParticipantAudioLevel } from 'calls/components/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { UserSettings } from 'constants/configurationEnums.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { getUserHealthSystemPreferences } from 'api/users.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import translate from 'i18n-translations/translate.jsx';

/**
 * @type {import('styled-components').StyledComponent<"div", any, never>}
 */
const StyledStreamSettings = styled.div`
	label {
		color: ${DarkTheme.colors.grayFive};
		button {
			padding: 0 !important;
			background: none !important;
			border: none;
			cursor: pointer;
			span {
				color: ${DarkTheme.colors.grayFive};
				font-size: 18px;
			}
		}
	}

	select {
		appearance: none;
		color: ${DarkTheme.colors.grayFive};
		background-color: ${DarkTheme.colors.grayFour};
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23cfd3da' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
	}
`;

/**
 * @typedef {import('@solaborate/calls/webrtc').LocalTrack} LocalTrack
 * @param {object} props
 * @param {object} [props.tracks]
 * @param {() => void} [props.createLocalTracks]
 */

const StreamSettings = ({ tracks, createLocalTracks = () => {} }) => {
	const dispatch = useDispatch();
	const conferenceConfigs = useConferenceConfigurations();
	const userSession = useSelector(state => state.user.userSession);

	const { current: mediaDevicesController } = useRef(new MediaDevicesController());
	const audioRefOutput = useRef(null);

	const [mediaDevices, setMediaDevices] = useState(mediaDevicesController.filteredMediaDevices);

	useEffect(() => {
		const audioOutputId = getSavedStreamSettings()[MediaDeviceKinds.AUDIO_OUTPUT];
		if (audioOutputId) {
			attachSinkId(audioRefOutput.current, audioOutputId);
		}
		const fetchUserSettings = async () => {
			const response = await getUserHealthSystemPreferences(userSession.healthSystem.id);
			if (response.error) {
				return;
			}
			let obj = {};
			response.teamMemberSettings.forEach(item => {
				if (Object.values(UserSettings).includes(item.settingTypeId)) {
					obj[item.settingTypeId] = item.value;
				}
			});
			dispatch(configurationActionCreators.setUserSettings(obj));
		};
		fetchUserSettings();
	}, []);

	useEffect(() => {
		if (mediaDevicesController.mediaDevices.length > 0) {
			setMediaDevices(mediaDevicesController.filteredMediaDevices);
		}
	}, [mediaDevicesController.filteredMediaDevices, mediaDevicesController.mediaDevices]);

	useEffect(() => {
		mediaDevicesController.on(event => {
			if (event instanceof MediaDevicesUpdated) {
				setMediaDevices(mediaDevicesController.filteredMediaDevices);
			}
		});
	}, [mediaDevicesController]);

	const saveStreamSettings = (kind, deviceId) => {
		const savedStreamSettings = getSavedStreamSettings();
		const newSavedStreamSettings = {
			...savedStreamSettings,
			[kind]: deviceId,
		};
		getStorage().setItem('streamSettings', JSON.stringify(newSavedStreamSettings));
	};

	return (
		<StyledStreamSettings>
			<Select>
				<Select.Label>{translate('camera')}</Select.Label>
				<Select.Field
					onChange={evt => {
						saveStreamSettings(MediaDeviceKinds.VIDEO_INPUT, evt.target.value);
						createLocalTracks();
					}}>
					{mediaDevices[MediaDeviceKinds.VIDEO_INPUT]?.map(({ label, deviceId }) => (
						<Select.Option
							key={deviceId}
							selected={getSavedStreamSettings()[MediaDeviceKinds.VIDEO_INPUT] === deviceId}
							value={deviceId}>
							{label}
						</Select.Option>
					))}
				</Select.Field>
			</Select>
			<Select>
				<Select.Label>
					{translate('microphone')}&nbsp;&nbsp;
					{tracks[Mic] && <ParticipantAudioLevel track={tracks[Mic].track} />}
				</Select.Label>
				<Select.Field
					onChange={evt => {
						saveStreamSettings(MediaDeviceKinds.AUDIO_INPUT, evt.target.value);
						createLocalTracks();
					}}>
					{mediaDevices[MediaDeviceKinds.AUDIO_INPUT].map(({ label, deviceId }) => (
						<Select.Option
							key={deviceId}
							selected={getSavedStreamSettings()?.[MediaDeviceKinds.AUDIO_INPUT] === deviceId}
							value={deviceId}>
							{label}
						</Select.Option>
					))}
				</Select.Field>
			</Select>
			<Select>
				<Select.Label>
					{translate('streamSettingsSpeaker')}&nbsp;
					<IconButton
						background='transparent'
						color={LightTheme.colors.grayFive}
						icon='play_circle'
						onClick={() => audioRefOutput.current.play()}
					/>
				</Select.Label>
				<Select.Field
					onChange={async evt => {
						const selectedValue = evt.target.value;
						saveStreamSettings(MediaDeviceKinds.AUDIO_OUTPUT, selectedValue);
						conferenceConfigs.setSinkId(selectedValue);
						await attachSinkId(audioRefOutput.current, selectedValue);
					}}>
					{mediaDevices[MediaDeviceKinds.AUDIO_OUTPUT].map(({ label, deviceId }) => (
						<Select.Option
							key={deviceId}
							selected={getSavedStreamSettings()[MediaDeviceKinds.AUDIO_OUTPUT] === deviceId}
							value={deviceId}>
							{label}
						</Select.Option>
					))}
				</Select.Field>
				<Select.Description>{translate('usingDifferentMic')}</Select.Description>
			</Select>
			<audio ref={audioRefOutput} src='https://static.solaborate.com/global/brand/calls/ring.mp3' />
		</StyledStreamSettings>
	);
};

export default StreamSettings;
